import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader, CSelect,
    CSwitch,
    CTextarea,
} from "@coreui/react";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/pro-light-svg-icons";import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import CommonRecordData from "../CommonRecordData/CommonRecordData";


export const MachineFormModal = ({machine, onSaveClick, onCancelClick, show, loading, onClose, areas}) => {
    
    const {t} = useTranslation(["MachineFormModal", "MachineFormContainer", "Buttons", "Validation"]);

    const shifts = useMemo(() => ([
        {id: 0, name: t("MachineFormContainer:select")},
        { id: 1, name: "1" },
        { id: 2, name: "2" },
        { id: 3, name: "3" },
        { id: 4, name: "4" }
    ]),[t]);
    
    const validationSchema = useMemo( () => Yup.object({
        line_room: Yup.string().required(t("Validation:fieldRequired")),
        areas_id: Yup.number().moreThan(0,t("Validation:pleaseSelectValue")),
        shifts: Yup.number().moreThan(0, t("Validation:pleaseSelectValue"))
    }),[t]);
    
    return (
        <Formik initialValues={machine}
                validateOnChange
                validateOnMount={parseInt(machine.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg">
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faCogs} className="mr-2"/> {t("machineEdit")}</h2></CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                <CModalBody className="p-4">
                    
                        <CFormGroup>
                            <CLabel htmlFor="line_room">{t("MachineFormModal:lineRoom")}<sup>*</sup></CLabel>
                            <Field name="line_room">
                                {({
                                      field, // { name, value, onChange, onBlur },
                                      meta: {touched, error}
                                  }) =>
                                    <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                                }
                            </Field>
                            <FieldErrorMessage name="line_room" />
                        </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="areas_id">{t("MachineFormModal:area")}<sup>*</sup></CLabel>
                        <Field name="areas_id">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {areas.map( r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                </CSelect>

                            }
                        </Field>
                        <FieldErrorMessage name="areas_id" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="areas_id">{t("MachineFormModal:shifts")}<sup>*</sup></CLabel>
                        <Field name="shifts">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {shifts.map( r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                </CSelect>

                            }
                        </Field>
                        <FieldErrorMessage name="shifts" />
                    </CFormGroup>

                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="is_active">
                            {({field, field: { value }}) =>
                                <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                         variant="opposite"
                                         {...field} value={value === 0 ? true : false}/>
                            }
                        </Field>
                        <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("AreaFormModal:active")}</CLabel>
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="description" className="ml-2 user-select-none">{t("MachineFormModal:description")}</CLabel>
                        <Field name="description">
                            {({
                                  field
                              }) => 
                                <CTextarea {...field} />
                            }
                        </Field>
                    </CFormGroup>
                    
                    {machine.id > 0 && <CommonRecordData item={machine} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    </CForm>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

MachineFormModal.propTypes = {
    machine: PropTypes.shape({
        is_active: PropTypes.number,
        line_room: PropTypes.string,
        shifts: PropTypes.number,
        areas_id: PropTypes.number
    }),
    areas: PropTypes.array,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

MachineFormModal.defaultProps = {
    loading: false,
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default MachineFormModal;