import { useMutation } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useSimulateProductionOrders = ({ onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);

    return useMutation(values => {
        const requestObj = {...values, simulation: 1};
        return axios.post(`${apiUrl}production-orders`, requestObj).then(res => res.data)         
    },
        {
            placeholderData: [],
            onSuccess: () => {
                onSuccess();
            }, onError: onError
        }
    );
};

export default useSimulateProductionOrders;