import PropTypes from 'prop-types';
import noop from "../../utils/noop";
import { EditButton, HistoryButton, DeleteButton} from "../Buttons";

export const ActionsCell = ({onEditClick, onHistoryClick, onDeleteClick}) => {
    
    return (
        <td className="text-nowrap text-center">
            <EditButton className="mr-2" onClick={onEditClick} />             
            <HistoryButton className="mr-2" onClick={onHistoryClick} />
            <DeleteButton onClick={onDeleteClick} />
        </td>
    );
};

ActionsCell.propTypes = {
    onEditClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

ActionsCell.defaultProps = {
    onEditClick: noop,
    onHistoryClick: noop,
    onDeleteClick: noop
};

export default ActionsCell;