import {useEffect, useState} from "react";
import {CButton} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import ReactDOM from "react-dom";

// Musi to tak być urzeźbione ręcznie ponieważ CDataTable nie ma slotów pozwalających na dodawanie własnych itemek do głównego filtra tabeli
const FilterIcon = ({onClick}) =>
    <CButton className="ml-2 border" onClick={onClick}>
        <FontAwesomeIcon icon={faFilter}/>
    </CButton>; 

export const useColumnFilter = () => {

    const [tableFilter, setTableFilter] = useState(false);
    
    useEffect(() => {
        
        const filterContainer = document.querySelector(".c-datatable-filter");

        const filterSwitchContainer = document.createElement("div");

        const clearFilterSvg = filterContainer.querySelector(".c-datatable-filter > svg");
        
        if (filterContainer.contains(clearFilterSvg)) {
        
            // Baaaardzo brzydki hack, ale działa :)
            clearFilterSvg.addEventListener("click", (e) => {
                for (let key in clearFilterSvg) {
                    if (key.startsWith("__reactProps")) {
                        clearFilterSvg[key].onClick();
                        break;
                    }
                }
            });
            
            const clearFilterDiv = document.createElement("button");
            clearFilterDiv.classList.add("ml-2");
            clearFilterDiv.classList.add("border");
            clearFilterDiv.classList.add("btn");
            clearFilterDiv.classList.add("imi-clear-filter-btn");
            clearFilterDiv.addEventListener("click", () => {
                clearFilterSvg.dispatchEvent(new Event('click'));
            });
                
            filterContainer.removeChild(clearFilterSvg);
            clearFilterDiv.appendChild(clearFilterSvg);

            filterContainer.appendChild(clearFilterDiv);
            
        }
        
        filterContainer.appendChild(filterSwitchContainer);

        ReactDOM.render(<FilterIcon onClick={() => setTableFilter(!tableFilter)} />, filterSwitchContainer);

        return () => {
            if (filterContainer) {
                filterContainer.removeChild(filterSwitchContainer);
            }
        }
    }, [tableFilter]);
  
    return tableFilter;
    
};