import {
    CDropdownItem, CDropdown, CDropdownToggle, CDropdownMenu
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import React from "react";
import {cilUser, cilSettings, cilHistory, cilAccountLogout } from "@coreui/icons";
import {useTranslation} from "react-i18next";
import {batch, useDispatch, useSelector} from "react-redux";
import {push} from 'connected-react-router'
import {SetAuthState} from "../../redux/actions/auth.actions";
import {userSelector} from "../../redux/selectors";
import {initialState} from "../../redux/reducers/auth.reducer";
import {useAuthProvider} from "../../auth/AuthProvider";

export const UserMenu = () => {
    
    const {t} = useTranslation("UserMenu");
    const dispatch = useDispatch();
    const user = useSelector(userSelector);
    const { stop, logout } = useAuthProvider();
    
    const handleNav = to => dispatch(push(to));
    
    const handleLogout = () => {
        //stop();         
        logout();
        dispatch(push("/login"));
    }
    
    return (
        <CDropdown
            inNav
            className="c-header-nav-items mx-2"
            direction="down"
        >
            <CDropdownToggle className="c-header-nav-link" caret={false}>
                <div className="c-avatar border">
                    <CIcon name="cil-user" content={cilUser} />
                </div>
            </CDropdownToggle>
            
            <CDropdownMenu className="pt-0" placement="bottom-end">
                
                <CDropdownItem tag="span" className="bg-light text-body" style={{fontWeight:"bold"}}>
                    {user.first_name} {user.last_name}
                </CDropdownItem>
                
                <CDropdownItem tag="span" className="cursor-pointer" onClick={() => handleNav("/user/profile")}>
                    <CIcon name="cil-user" content={cilUser} className="mfe-2" />
                    {t("profile")}
                </CDropdownItem>

                <CDropdownItem tag="span" className="cursor-pointer" onClick={() => handleNav("/user/settings")} >
                    <CIcon name="cil-settings" content={cilSettings} className="mfe-2" />
                    {t("settings")}
                </CDropdownItem>

                <CDropdownItem  tag="span" className="cursor-pointer" onClick={() => handleNav("/user/activity-log")}>
                    <CIcon name="cil-history" content={cilHistory} className="mfe-2" />
                    {t("activityLog")}
                </CDropdownItem>
                <CDropdownItem divider />
                <CDropdownItem tag="span" className="cursor-pointer" onClick={handleLogout}>
                    <CIcon name="cil-account-logout" content={cilAccountLogout} className="mfe-2" />
                    {t("logout")}
                </CDropdownItem>
            </CDropdownMenu>
        </CDropdown>
    );
};


export default UserMenu;