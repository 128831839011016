import { useMemo } from "react";
import PropTypes from 'prop-types';
import noop from "../../utils/noop";
import {CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";

export const LangPicker = ({languages, lang, onChange}) => {
    
    const currentLang = useMemo(() => {
        const ln = languages.find( l => l.key === lang);
        return ln ?? "pl";
    }, [languages, lang]);
    
    return (
            <CDropdown> 
                <CDropdownToggle>{currentLang.text}</CDropdownToggle>
                <CDropdownMenu>
                    {languages.map( l => 
                        <CDropdownItem key={l.key} onClick={() => onChange(l.value)}>{l.text}</CDropdownItem>    
                    )}
                </CDropdownMenu>
            </CDropdown>
    );
};

LangPicker.propTypes = {
    lang: PropTypes.string,
    languages: PropTypes.array,
    defaultLang: PropTypes.string,
    onChange: PropTypes.func
};

LangPicker.defaultProps = {
    lang: "en",
    onChange: noop
};

export default LangPicker;