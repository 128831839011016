import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CElementCover
} from "@coreui/react";
import { noop } from "../../utils/noop";
import { CancelButton, YesDeleteButton,  } from "../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const ConfirmDeleteModal = ({onDeleteOkClick, onDeleteCancelClick, show, loading }) => {
    
    const {t} = useTranslation("ConfirmDeleteModal");
    
    return (
        <CModal show={show} onClose={onDeleteCancelClick} closeOnBackdrop={false} className="imi-delete-modal">
            <CModalHeader closeButton><h2><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-danger"/>{t("recordDelete")}</h2></CModalHeader>
            <CModalBody className="text-center py-4 imi-delete-modal__body">
                
                {t("areYouSure")}
                
            </CModalBody>
            <CModalFooter>
                
                    <CancelButton onClick={onDeleteCancelClick}/>
                    <YesDeleteButton onClick={onDeleteOkClick} />
                    
            </CModalFooter>
            {loading && <CElementCover/>}
        </CModal>
    );
};

ConfirmDeleteModal.propTypes = {
    show: PropTypes.bool,
    loading: PropTypes.bool,
    onDeleteOkClick: PropTypes.func,
    onDeleteCancelClick: PropTypes.func
};

ConfirmDeleteModal.defaultProps = {
    show: false,
    loading: false,
    onDeleteOkClick: noop,
    onDeleteCancelClick: noop
};

export default ConfirmDeleteModal;