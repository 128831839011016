import {useMemo} from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {usePlanningContext} from "./PlanningContextProvider";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import {woCellRenderer} from "./WOCell";

const defaultColDef = {
  sortable: true,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  resizable: true,
};

export const WorkOrderJDE = ({loading}) => {
    
    const {t} = useTranslation(["WorkOrderJDE", "Common"]);
    
    const {woJdeData, onCheckWO, onWoJdeGridReady, getRowStyle } = usePlanningContext();
    
    const getRowColors = useMemo(() => getRowStyle("priority"),
        [getRowStyle],
    );
    
    return (
        <div className="position-relative h-50 pb-1">
            <div className="ag-theme-balham" style={{height:"100%"}}>
                <AgGridReact
                    modules={AllCommunityModules}
                    rowData={woJdeData}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    onGridReady={onWoJdeGridReady}
                    onSelectionChanged={onCheckWO}
                    getRowStyle={getRowColors}
                    rowMultiSelectWithClick
                >
                    <AgGridColumn field="_chk" headerName="" checkboxSelection headerCheckboxSelection
                                  filter={false} lockPosition
                                  minWidth={50} maxWidth={50} 
                                  pinned
                    />
                    <AgGridColumn field="id" headerName={t("Common:id")}/>
                    <AgGridColumn field="rccp" headerName={t("WorkOrderJDE:rccp")} />
                    <AgGridColumn field="wo_number" headerName={t("WorkOrderJDE:woNumber")} cellRendererFramework={woCellRenderer} />
                    <AgGridColumn field="type" headerName={t("WorkOrderJDE:type")} />
                    <AgGridColumn field="item_number_2nd" headerName={t("WorkOrderJDE:itemNumber")} />
                    <AgGridColumn field="item_number_3rd" headerName={t("WorkOrderJDE:3rdItemNumber")} />
                    <AgGridColumn field="wo_status" headerName={t("WorkOrderJDE:status")} />
                    <AgGridColumn field="description" headerName={t("WorkOrderJDE:description")} />
                    <AgGridColumn field="qty_ordered" headerName={t("WorkOrderJDE:qtyOrdered")} />
                    <AgGridColumn field="qty_shipped" headerName={t("WorkOrderJDE:qtyShipped")} />
                    <AgGridColumn field="qty_open" headerName={t("WorkOrderJDE:qtyOpen")} />
                    <AgGridColumn field="order_date" headerName={t("WorkOrderJDE:orderDate")} />
                    <AgGridColumn field="requested_date" headerName={t("WorkOrderJDE:requestedDate")} />
                    <AgGridColumn field="planned_effective_date" headerName={t("WorkOrderJDE:plannedEffectiveDate")} />
                    <AgGridColumn field="start_date" headerName={t("WorkOrderJDE:startDate")} />
                    <AgGridColumn field="branch" headerName={t("WorkOrderJDE:branch")} />
                    <AgGridColumn field="priority" headerName={t("WorkOrderJDE:priority")} />
                    <AgGridColumn field="related_so_no" headerName={t("WorkOrderJDE:relatedSoNo")} />
                    <AgGridColumn field="related_type" headerName={t("WorkOrderJDE:relatedType")} />
                    <AgGridColumn field="customer_no" headerName={t("WorkOrderJDE:customerNo")} />
                    <AgGridColumn field="planner" headerName={t("WorkOrderJDE:planner")} />
                </AgGridReact>
            </div>
            
        </div>
    );
};

WorkOrderJDE.propTypes = {
    loading: PropTypes.bool
};

WorkOrderJDE.defaultProps = {
    loading: false
};

export default WorkOrderJDE;