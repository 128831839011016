import PropTypes from 'prop-types';
import {CSelect} from "@coreui/react";
import {usePlanningContext} from "./PlanningContextProvider";
import { CFormGroup, CInputGroup, CInputGroupPrepend, CInputGroupText} from "@coreui/react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";

export const FilterProductGroup = ({className, value, onChange}) => {

    const {t} = useTranslation("Filters");
    const { productGroups } = usePlanningContext();
    
    return (
        <CFormGroup inline={true} className={classNames("mb-0", className)}>
            <CInputGroup>
                <CInputGroupPrepend className="cursor-default">
                    <CInputGroupText>
                        {t("productGroup")}            
                    </CInputGroupText>
                </CInputGroupPrepend>
                <CSelect className={className} value={value} onChange={onChange}>
                    {productGroups.map( r => <option key={r.id} value={r.id}>{r.name}</option>)}
                </CSelect>
            </CInputGroup>
        </CFormGroup>
    );
};

FilterProductGroup.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func
};

FilterProductGroup.defaultProps = {
    className: "",
    onChange: () => {}
};

export default FilterProductGroup;