import CIcon from "@coreui/icons-react";
import { cilSpeedometer, cilChartLine, cilTrash, cilViewQuilt, cil3d, cilBriefcase} from "@coreui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faUsersCog } from "@fortawesome/free-solid-svg-icons";
import {
    faCogs,
    faStopwatch,
    faLayerGroup,
    faBoxes,
    faDumpster,
    faUserShield,
    faListAlt,
    faWarehouseAlt, faObjectUngroup, faClipboard
} from "@fortawesome/pro-light-svg-icons";

const nav = () => {
    
    // W polu name jest klucz z pliku translacji z Sidebar.json
    return [
        {
            _tag: "CSidebarNavItem",
            name: "menu_start",
            key: "start",
            to: "/start",
            icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" content={cilSpeedometer}/>
        },
        {
            _tag: "CSidebarNavItem",
            name: "menu_planning",
            key: "planning",
            to: "/planning",
            icon: <FontAwesomeIcon icon={faClipboard} className="c-sidebar-nav-icon" />
        },
        {
            _tag: "CSidebarNavItem",
            name: "menu_productionReporting",
            key: "production-reporting",
            to: "/production-reporting",
            icon: <CIcon name="cil-briefcase" customClasses="c-sidebar-nav-icon" content={cilBriefcase}/>
        },
        {
            _tag: "CSidebarNavItem",
            name: "menu_scrapReporting",
            key: "scrap-reporting",
            to: "/scrap-reporting",
            icon: <CIcon name="cil-trash" customClasses="c-sidebar-nav-icon" content={cilTrash}/>
        },
        {
            _tag: "CSidebarNavDropdown",
            name: "menu_reports",
            key: "reports",
            icon: <CIcon name="cil-chart-line" customClasses="c-sidebar-nav-icon" content={cilChartLine}/>,
            _children: [
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report1",
                    key: "report-1",
                    to: "/reports/report-1"
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report2",
                    key: "report-2",
                    to: '/reports/report-2'
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report3",
                    key: "report-3",
                    to: "/reports/report-3"
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report4",
                    key: "report-4",
                    to: "/reports/report-4"
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report5",
                    key: "report-5",
                    to: "/reports/report-5"
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report6",
                    key: "report-6",
                    to: "/reports/report-6"
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_report7",
                    key: "report-7",
                    to: "/reports/report-7"
                }
            ]
        },

        {
            _tag: "CSidebarNavDropdown",
            name: "menu_dataCorrection",
            key: "data-correction",
            //to: "/data-correction",
            icon: <CIcon name="cil-view-quilt" customClasses="c-sidebar-nav-icon" content={cilViewQuilt}/>,
            _children: [
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_production-orders",
                    key: "production-orders",
                    to: "/data-correction/production-orders",
                    icon: <FontAwesomeIcon icon={faClipboard} className="c-sidebar-nav-icon"/>
                },
            ]
        },
        
        /*{
            _tag: "CSidebarNavItem",
            name: "menu_dataAdministration",
            key: "data-administration",
            to: "/data-administration",
            icon: <CIcon name="cil-3d" customClasses="c-sidebar-nav-icon" content={cil3d}/>,
        },*/

        {
            _tag: "CSidebarNavDropdown",
            name: "menu_dataAdministration",
            key: "administration",
            icon: <CIcon name="cil-3d" customClasses="c-sidebar-nav-icon" content={cil3d}/>,
            _children: [
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_users",
                    key: "users",
                    to: "/administration/users",
                    icon: <FontAwesomeIcon icon={faUser} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_employees",
                    key: "employees",
                    to: "/administration/employees",
                    icon: <FontAwesomeIcon icon={faUsersCog} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_areas",
                    key: "areas",
                    to: "/administration/areas",
                    icon: <FontAwesomeIcon icon={faWarehouseAlt} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_machinery",
                    key: "machinery",
                    to: "/administration/machinery",
                    icon: <FontAwesomeIcon icon={faCogs} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_stop-groups",
                    key: "stop-groups",
                    to: "/administration/stop-groups",
                    icon: <FontAwesomeIcon icon={faObjectUngroup} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_stops",
                    key: "stops",
                    to: "/administration/stops",
                    icon: <FontAwesomeIcon icon={faStopwatch} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_product-groups",
                    key: "product-groups",
                    to: "/administration/product-groups",
                    icon: <FontAwesomeIcon icon={faLayerGroup} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_products",
                    key: "products",
                    to: "/administration/products",
                    icon: <FontAwesomeIcon icon={faBoxes} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_scraping-codes",
                    key: "scraping-codes",
                    to: "/administration/scraping-codes",
                    icon: <FontAwesomeIcon icon={faDumpster} className="c-sidebar-nav-icon"/>
                },
                /*{
                    _tag: "CSidebarNavItem",
                    name: "menu_bom",
                    key: "bom",
                    to: "/administration/bom",
                    icon: <FontAwesomeIcon icon={faPuzzlePiece} className="c-sidebar-nav-icon"/>
                },*/
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_permissions",
                    key: "permissions",
                    to: "/administration/permissions",
                    icon: <FontAwesomeIcon icon={faUserShield} className="c-sidebar-nav-icon"/>
                },
                {
                    _tag: "CSidebarNavItem",
                    name: "menu_dictionaries",
                    key: "dictionaries",
                    to: "/administration/dictionaries",
                    icon: <FontAwesomeIcon icon={faListAlt} className="c-sidebar-nav-icon"/>
                },
              /*  {
                    _tag: "CSidebarNavItem",
                    name: "menu_settings",
                    key: "settings",
                    to: "/administration/settings",
                }*/
            ]
        },

        /*{
            _tag: 'CSidebarNavTitle',
            _children: ['Theme']
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Colors',
            to: '/theme/colors',
            icon: 'cil-drop',
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Typography',
            to: '/theme/typography',
            icon: 'cil-pencil',
        },
        {
            _tag: 'CSidebarNavTitle',
            _children: ['Components']
        },
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Base',
            route: '/base',
            icon: 'cil-puzzle',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Breadcrumb',
                    to: '/base/breadcrumbs',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Cards',
                    to: '/base/cards',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Carousel',
                    to: '/base/carousels',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Collapse',
                    to: '/base/collapses',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Forms',
                    to: '/base/forms',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Jumbotron',
                    to: '/base/jumbotrons',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'List group',
                    to: '/base/list-groups',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Navs',
                    to: '/base/navs',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Navbars',
                    to: '/base/navbars',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Pagination',
                    to: '/base/paginations',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Popovers',
                    to: '/base/popovers',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Progress',
                    to: '/base/progress-bar',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Switches',
                    to: '/base/switches',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Tables',
                    to: '/base/tables',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Tabs',
                    to: '/base/tabs',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Tooltips',
                    to: '/base/tooltips',
                },
            ],
        },
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Buttons',
            route: '/buttons',
            icon: 'cil-cursor',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Buttons',
                    to: '/buttons/buttons',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Brand buttons',
                    to: '/buttons/brand-buttons',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Buttons groups',
                    to: '/buttons/button-groups',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Dropdowns',
                    to: '/buttons/button-dropdowns',
                }
            ],
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Charts',
            to: '/charts',
            icon: 'cil-chart-pie'
        },
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Icons',
            route: '/icons',
            icon: 'cil-star',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'CoreUI Free',
                    to: '/icons/coreui-icons',
                    badge: {
                        color: 'success',
                        text: 'NEW',
                    },
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'CoreUI Flags',
                    to: '/icons/flags',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'CoreUI Brands',
                    to: '/icons/brands',
                },
            ],
        },
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Notifications',
            route: '/notifications',
            icon: 'cil-bell',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Alerts',
                    to: '/notifications/alerts',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Badges',
                    to: '/notifications/badges',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Modal',
                    to: '/notifications/modals',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Toaster',
                    to: '/notifications/toaster'
                }
            ]
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Widgets',
            to: '/widgets',
            icon: 'cil-calculator',
            badge: {
                color: 'info',
                text: 'NEW',
            },
        },
        {
            _tag: 'CSidebarNavDivider'
        },
        {
            _tag: 'CSidebarNavTitle',
            _children: ['Extras'],
        },
        {
            _tag: 'CSidebarNavDropdown',
            name: 'Pages',
            route: '/pages',
            icon: 'cil-star',
            _children: [
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Login',
                    to: '/login',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Register',
                    to: '/register',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Error 404',
                    to: '/404',
                },
                {
                    _tag: 'CSidebarNavItem',
                    name: 'Error 500',
                    to: '/500',
                },
            ],
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Disabled',
            icon: 'cil-ban',
            badge: {
                color: 'secondary',
                text: 'NEW',
            },
            addLinkClass: 'c-disabled',
            'disabled': true
        },
        {
            _tag: 'CSidebarNavDivider',
            className: 'm-2'
        },
        {
            _tag: 'CSidebarNavTitle',
            _children: ['Labels']
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Label danger',
            to: '',
            icon: {
                name: 'cil-star',
                className: 'text-danger'
            },
            label: true
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Label info',
            to: '',
            icon: {
                name: 'cil-star',
                className: 'text-info'
            },
            label: true
        },
        {
            _tag: 'CSidebarNavItem',
            name: 'Label warning',
            to: '',
            icon: {
                name: 'cil-star',
                className: 'text-warning'
            },
            label: true
        },
        {
            _tag: 'CSidebarNavDivider',
            className: 'm-2'
        }*/
    ];

};

export default nav;