import {useEffect, useState} from "react";
import {ProductList as List} from "../components";
import { useTranslation } from "react-i18next";
import useProducts from "../hooks/useProducts";
import ImportModal from "../components/ImportModal";
import { ProductFormContainer as FormContainer } from "./";
import {useImportModal} from "../hooks/useImportModal";
import {useToaster} from "../context-providers/ToasterProvider";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import {useDeleteModal} from "../hooks/useDeleteModal";
import {apiUrls} from "../common/apiUrls";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";

export const ProductsContainer = () => {

    const {t} = useTranslation(["ProductsContainer", "Buttons"]);

    const apiUrl = useSelector(apiUrlSelector);

    const { toastError } = useToaster();

    const [recordId, setRecordId] = useState(0);

    const [showRecordModal, setShowRecordModal] = useState(false);

    const { isLoading, error, data } =  useProducts();

    useEffect( ( ) => {
        if ( error ) {
            toastError(t("errorFetchingProducts"));
        }
    }, [error] );

    const { modal: importModal, onImportClick } = useImportModal("url fo importu d");

    const { confirmDelete, ...deleteModalProps } = useDeleteModal(apiUrls.products);
    
    const handleCloseRecordModal = () => setShowRecordModal(false);

    const handleAddClick = () => {
        
        setRecordId(0);
        setShowRecordModal(true);

    };

    const handleEditClick = row => {
        setRecordId(row.id);
        setShowRecordModal(true);
    };

    const handleHistoryClick = (item) => {
        console.log("Pokazuję historię", item);
    };
    
    return (
        <>
            {showRecordModal && <FormContainer recordId={recordId}
                                               onSave={handleCloseRecordModal}
                                               onCancelClick={handleCloseRecordModal}
                                               onGetError={handleCloseRecordModal} />}

            <ImportModal {...importModal} />

            <ConfirmDeleteModal {...deleteModalProps} />
            
            <List records={data} loading={isLoading} onAddClick={handleAddClick}
                  onEditClick={handleEditClick} onDeleteClick={confirmDelete} onHistoryClick={handleHistoryClick}
                  onImportClick={onImportClick} exportHref={`${apiUrl}/${apiUrls.products}/export"`} />
        </>
    );
};

export default ProductsContainer;