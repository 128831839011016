import {AuthActionTypes } from "../actions/auth.actions";

export const authMiddleware = () => {

    return store => next => action => {
        
        const { type, payload } = action;
        
        if ( type === AuthActionTypes.SetAuthState ) {
            if ( payload.loggedIn === true ) {
                localStorage.setItem("auth", JSON.stringify(payload));
            } else {
                localStorage.removeItem("auth");
            }
            
        }
        return next(action);
    }

};
