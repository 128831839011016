import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

export const DeleteButton = ({className, ...props}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton type="button" color="danger" size="sm" className={classNames(className)} {...props}><FontAwesomeIcon icon={faTrashAlt} className="mr-2"/>{t("delete")}</CButton>
    );
};


export default DeleteButton;