import PropTypes from 'prop-types';
import noop from "../../utils/noop";
import {AddButton, ExportButton, ImportButton} from "../Buttons";
import classNames from "classnames";

export const ActionToolbar = ({className, addDisabled, addButton, exportDisabled, importDisabled, onAddClick, onExportClick, onImportClick, exportHref}) => {
    return (
        <div className={classNames("align-self-center", className)}>
            {addButton && <AddButton onClick={onAddClick} disabled={addDisabled}/>}
            <ExportButton className="ml-2" onClick={onExportClick} disabled={exportDisabled} href={exportHref} />
            <ImportButton className="ml-2" onClick={onImportClick} disabled={importDisabled}/>
        </div>
    );
};

ActionToolbar.propTypes = {
    className: PropTypes.string,
    exportHref: PropTypes.string,
    addDisabled: PropTypes.bool,
    addButton: PropTypes.bool,
    exportDisabled: PropTypes.bool,
    importDisabled: PropTypes.bool,
    onAddClick: PropTypes.func,
    onExportClick: PropTypes.func,
    onImportClick: PropTypes.func
};

ActionToolbar.defaultProps = {
    className: "",
    exportHref: "",
    addDisabled: false,
    addButton: true,
    exportDisabled: false,
    importDisabled: false,
    onAddClick: noop,
    onExportClick: noop,
    onImportClick: noop
};

export default ActionToolbar;