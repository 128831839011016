import {useContext, createContext, useState, useEffect, useCallback} from "react";
import useWOJDEs from "../../hooks/useWOJDEs";
import useProductGroups from "../../hooks/useProductGroups";
import {useTranslation} from "react-i18next";
import useCreateProductionOrders from "../../hooks/useCreateProductionOrders";
import useCreateProductionOrder from "../../hooks/useCreateProductionOrder";
import {useToaster} from "../../context-providers/ToasterProvider";
import {useSelector} from "react-redux";
import {apiUrlSelector, areaSelector} from "../../redux/selectors";
import {useQueryClient} from "react-query";
import useProductionOrders from "../../hooks/useProductionOrders";
import {format} from "date-fns";
import {DATE_FORMAT} from "../../common/formats";
import useSimulateProductionOrders from "../../hooks/useSimulateProductionOrders";
import usePrevious from "use-previous";

const PlanningContext = createContext({});

export const usePlanningContext = () => useContext(PlanningContext);

export const PlanningProvider = ({children}) => {

    const {t} = useTranslation(["Planning", "Filters"]);
    const apiUrl = useSelector(apiUrlSelector);
    const area = useSelector(areaSelector);
    const previousArea = usePrevious(area);
    const queryClient = useQueryClient();
    const { toastError, toastSuccess } = useToaster();

    const [woJdeGridApi, setWoJdeGridApi] = useState(null);
    const [woPlannedGridApi, setWoPlannedGridApi] = useState(null);
    const [simulationModalVisible, setSimulationModalVisible] = useState(false);
    const [notesModalVisible, setNotesModalVisible] = useState(false);
    const [filterProductGroup, setFilterProductGroup] = useState("0");
    const [filterRccp, setFilterRccp] = useState("");
    const [filterSx, setFilterSx] = useState(255);
    const [filterJde,setFilterJde] = useState({
        areas_name: area.name
    });
    const [filterPlanned, setFilterPlanned] = useState({
        areas_name: area.name    
    });
    const [filterDate, setFilterDate] = useState(new Date());
    const [operationDate, setOperationDate] = useState(new Date());
    const [loadDegree, setLoadDegree] = useState(0);
    const [checkedWO, setCheckedWO] = useState(false);
    const [notesRecordId, setNotesRecordId] = useState(0);
    
    // Jeżeli zmieni się area to trzeba dodać go do filtra
    useEffect(() => {
        if ( typeof previousArea === "undefined" || area.id !== previousArea.id) {
            setFilterPlanned({...filterPlanned, areas_name: area.name});
            setFilterJde({...filterJde, areas_name: area.name});
        }
    },[area, previousArea, setFilterPlanned, setFilterJde, filterJde, filterPlanned]);
    
    const onCheckWO = () => {
        const selectedNodes = woJdeGridApi.getSelectedNodes();
        
        setCheckedWO(selectedNodes.length > 0);
    };
    
    const onFilterClick = () => {
        const newFilterJde = {
            areas_name: area.name
        };

        const newFilterPlanned = {
            areas_name: area.name
        };
        
        const gid = parseInt(filterProductGroup); 
        if (  gid > 0 ) {
            
            // Kluczem jest nazwa nie id, dlatego szukam rekordu w tablicy i podstawiam nazwę do filtra
            const groupName = productGroups.find( g => g.id === gid );
            if ( groupName ) {
                newFilterJde.product_groups_name = groupName.name;
                newFilterPlanned.product_groups_name = groupName.name;
            }
            
        }
        
        if ( parseInt(filterSx) !== 255 ) {
            newFilterJde.related_type = filterSx;
            newFilterPlanned.related_type = filterSx;
        }
        
        if ( filterRccp.length > 0 ) {
            newFilterJde.rccp = filterRccp;
            newFilterPlanned.rccp = filterRccp;
        }
        
        setFilterJde(newFilterJde);
        setFilterPlanned(newFilterPlanned);
    }
    
    const onWoJdeGridReady = useCallback((params) => {
        setWoJdeGridApi(params.api);
        // Resize kolumn
        params.columnApi.autoSizeAllColumns();
        },
        [setWoJdeGridApi]
    );

    const onWoPlannedGridReady = useCallback((params) => {
            setWoPlannedGridApi(params.api);
            // Resize kolumn
            params.columnApi.autoSizeAllColumns();
        },
        [setWoPlannedGridApi]
    );
    
    const addAllToProductGroups = (data) => {
        return [{id: 0, name: `-- ${t("Filters:all")} --`}].concat(data);
    }
    
    // TODO: pobranie danych tylko jeżeli są pobrane grupy
    const { data: productGroups} = useProductGroups(addAllToProductGroups);
    // Funkcja onSuccess dodaje sztuczną kolumnę dla checkboxa
    const { data: woJdeData } = useWOJDEs(filterJde, (data) => data.map( d => {d._chk = ""; return d} ));
    const { data: productionOrdersData } = useProductionOrders(filterPlanned);
    
    const { mutate: createProductionOrders } = useCreateProductionOrders({
        onError: err => toastError(t("saveError")),
        onSuccess: () => {
            woJdeGridApi.deselectAll();
            setCheckedWO(false);
            toastSuccess(t("saveSuccess"));
            Promise.all([
                queryClient.invalidateQueries(["jde-work-orders", filterJde, apiUrl]),
                queryClient.invalidateQueries(["production-orders", filterPlanned, apiUrl]),    
            ]).then(() => {
                woJdeGridApi.hideOverlay();
                woPlannedGridApi.hideOverlay();    
            });
            
        }
    });

    const { mutate: simulateProductionOrders, isLoading: isSimulationLoading, data: simulationData } = useSimulateProductionOrders({
        onError: err => {
            toastError(t("calculationError"));
            setSimulationModalVisible(false);
        },
        onSuccess: () => {
        }
    });
    
    const { mutate: createProductionOrder } = useCreateProductionOrder({
        onError: () => toastError(t("saveError")),
        onSuccess: () => {
            toastSuccess(t("saveSuccess"));
            queryClient.invalidateQueries(["jde-work-orders", filterJde, apiUrl]);
            queryClient.invalidateQueries(["production-orders", filterPlanned, apiUrl]);
        }
    });
    
    const onSaveProductionOrders = () => {

        const selectedNodes = woJdeGridApi.getSelectedNodes();
        const selectedIds = selectedNodes.map( node => node.data.id );
        woJdeGridApi.showLoadingOverlay();
        woPlannedGridApi.showLoadingOverlay();
        createProductionOrders({
            start_date: format(filterDate, DATE_FORMAT),
            jde_work_orders_ids: selectedIds
        });
    };
    
    const onSimulateProductionOrders = () => {
        setSimulationModalVisible(true);
        simulateProductionOrders({
            start_date: format(filterDate, DATE_FORMAT),
            jde_work_orders_id: checkedWO
        });
    }
    
    const onInternalStatusChange = (id, internalStatus, onSuccess, onError) => {
        
        createProductionOrder({
            id,
            internal_status:internalStatus
        },{
            onSuccess,
            onError
        });
        
    }
    
    const onOrderCellValueChange = (id, value, onSuccess, onError) => {
        createProductionOrder({
            id,
            wo_order:value
        },{
            onSuccess, onError
        });    
    }
    
    const onNotesClick = (id) => {
        
        setNotesRecordId(id);
        setNotesModalVisible(true);
        
    }
    
    const getRowStyle = (priorityName) => {
        
        return params => {
            
            if ( params ) {
                const priority = params.data[priorityName];
                
                if (priority === 1) {
                    return {backgroundColor: "#eaf8e6"}; // Green
                } else if (priority === 2) {
                    return {backgroundColor: "#ffebeb"}; // Red
                } else if (priority === 3) {
                    return {backgroundColor: "#fff8ba"}; // Orange (yellow)
                }
            }
        
        }
        
    };
    
    return (
        <PlanningContext.Provider value={{  
            productGroups,
            woJdeData,
            productionOrdersData,
            filterProductGroup,
            setFilterProductGroup,
            filterDate, setFilterDate,
            filterRccp, setFilterRccp,
            filterPlanned,
            operationDate, setOperationDate,
            loadDegree, setLoadDegree,
            checkedWO,
            onCheckWO,
            onSaveProductionOrders,
            onInternalStatusChange,
            onSimulateProductionOrders,
            simulationModalVisible,
            setSimulationModalVisible,
            isSimulationLoading,
            simulationData,
            onWoJdeGridReady,
            onWoPlannedGridReady,
            getRowStyle,
            onFilterClick,
            filterSx, setFilterSx,
            woJdeGridApi,
            onOrderCellValueChange,
            onNotesClick,
            notesModalVisible, setNotesModalVisible,
            notesRecordId
        }}>{children}</PlanningContext.Provider>
    );
};