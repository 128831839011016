import classNames from "classnames";
import {CFormGroup, CInputGroup, CInputGroupPrepend, CInputGroupText, CSelect} from "@coreui/react";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {usePlanningContext} from "./PlanningContextProvider";

export const FilterSx = ({className}) => {

    const {t} = useTranslation(["Filters"]);
    
    const {filterSx, setFilterSx} = usePlanningContext();
    
    const options = useMemo(() => [255,0,1].map( v => ({ value: v, label: t(`sx.${v}`) }) ), [t]);
    
    return (
        <CFormGroup inline={true} className={classNames("mb-0", className)}>
            <CInputGroup>
                <CInputGroupPrepend className="cursor-default">
                    <CInputGroupText>
                        SX
                    </CInputGroupText>
                </CInputGroupPrepend>
                <CSelect value={filterSx} onChange={(val) => setFilterSx(val.target.value)}>
                    {options.map( r => <option key={r.value} value={r.value}>{r.label}</option>)}
                </CSelect>
            </CInputGroup>
        </CFormGroup>
    );
};

export default FilterSx;