import React, {useEffect, useRef, useState} from 'react'
import { useTranslation} from "react-i18next";
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CInput,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    CRow,
    CSpinner
} from '@coreui/react'
import CIcon from "@coreui/icons-react";
import {cilUser, cilLockLocked } from "@coreui/icons";
import imiLogo from "./brand.png";
import PropTypes from "prop-types";
import noop from "../../utils/noop";
import AreaPicker from "../AreaPicker/AreaPicker";

const CLogin = ({ onLoginClick, onChange, inputsDisabled, loading, error }) => {

    const { t } = useTranslation("Login");

    const usernameRef = useRef();
    const [data, setData ] = useState({username:"", password:"", area: { id: 0, name: "", description: ""}});
    
    useEffect(  () => usernameRef.current.focus(),[]);

    const handleChange = (name, value) => {
        const newData = {...data, [name]: value};
        setData(newData);
        onChange({name,value}, newData);
    };

    const handleKeyDown = evt => {
        if (evt.key === "Enter" && evt.shiftKey === false) {
            onLoginClick(data);
        }
    };

    const handleLoginClick = () => {
        
        onLoginClick(data);

    };
    
    const bodyStyle =  inputsDisabled ? { opacity: "0.5" } : {};
    
    return (
        <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md="10">
                        <CCardGroup style={bodyStyle}>
                            <CCard className="text-white bg-brand py-5 d-md-down-none" style={{ width: "400px" }}>
                                <CCardBody className="text-center">
                                    <div>
                                        <img src={imiLogo} style={{width:"200px"}} alt="IMI Logo" />
                                    </div>
                                </CCardBody>
                            </CCard>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <h1>IMI MES</h1>
                                        <p className="text-muted">{t("Login:loginToYourAccount")}</p>
                                        <CInputGroup className="mb-3">
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-user" content={cilUser} />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput innerRef={usernameRef} name="username"  onChange={({target:{name, value}}) => handleChange(name, value)} onKeyDown={handleKeyDown} type="text" placeholder={t("Login:username")} autoComplete="username" />
                                        </CInputGroup>
                                        <CInputGroup >
                                            <CInputGroupPrepend>
                                                <CInputGroupText>
                                                    <CIcon name="cil-lock-locked" content={cilLockLocked} />
                                                </CInputGroupText>
                                            </CInputGroupPrepend>
                                            <CInput type="password" name="password" onChange={({target:{name, value}}) => handleChange(name, value)} onKeyDown={handleKeyDown} disabled={inputsDisabled} placeholder={t("Login:password")} autoComplete="current-password" />
                                        </CInputGroup>

                                        {error.length > 0 ? <span className="d-inline-bslock text-danger mt-2">{error}</span> : <span>&nbsp;</span>}
                                        
                                        <CInputGroup className="mt-4 mb-4">
                                            <AreaPicker area={data.area}
                                                        className="w-100"
                                                        onChange={(id) => handleChange("area", id)}/>
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs="12" className="text-center">
                                                <CButton color="primary" className="px-4 w-100" onClick={handleLoginClick} disabled={data.username.length === 0 || data.password.length === 0 || data.area.id === 0}>{t("Login:login")}</CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                            {loading === true && <>
                                <div className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center bg-light" style={{zIndex: "10", opacity: "0.4"}} />
                                <div className="w-100 h-100 position-absolute d-flex align-items-center justify-content-center" style={{zIndex: "11"}}>
                                    <CSpinner color="info"/>
                                </div>
                            </>}
                            
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
};

CLogin.propTypes = {
    inputsDisabled: PropTypes.bool,
    loading: PropTypes.bool,
    onLoginClick: PropTypes.func,
    onChange: PropTypes.func,
    validator: PropTypes.func
};

CLogin.defaultProps = {
    inputsDisabled: false,
    loading: false,
    onLoginCLick: noop,
    onChange: noop,
    validator: () => true
};

export default CLogin