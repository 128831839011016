import { createReducer } from "@reduxjs/toolkit";
import {SetAuthState, SetExpiresIn, SetRefreshToken, SetAccessToken} from "../actions/auth.actions";

export const initialState = {
  loggedIn: false,
  access_token: "",
  refresh_token: "",
  expires_in: 0
};

export const authReducer = createReducer(initialState, (builder) => {
  builder.addCase(SetAuthState, (state, action) => {
    state.loggedIn = action.payload.loggedIn;
    state.access_token = action.payload.access_token;
    state.refresh_token = action.payload.refresh_token;
    state.expires_in = action.payload.expires_in;
    return state;
  }).addCase(SetRefreshToken, (state, action) => {
    return {...state, refresh_token: action.payload};
  }).addCase(SetExpiresIn, (state, action) => {
    return {...state, expires_in: action.payload};
  }).addCase(SetAccessToken, (state, action) => {
    return {...state, access_token: action.payload};
  }).addDefaultCase((state, action) => state)
});