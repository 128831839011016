import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader, CSelect,
    CSwitch, CTextarea,
} from "@coreui/react";
import "react-datepicker/dist/react-datepicker.css";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/pro-light-svg-icons";
import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import DateTimePickerField from "../DateTimePickerField/DateTimePickerField";
import CommonRecordData from "../CommonRecordData/CommonRecordData";


export const ProductFormModal = ({record, onSaveClick, onCancelClick, show, loading, onClose, machinery}) => {
    
    const {t} = useTranslation(["ProductFormModal", "Buttons", "Validation"]);
    
    const validationSchema = useMemo( () => Yup.object({
        parent_item_number_2nd: Yup.string().required(t("Validation:fieldRequired")),
        item_number: Yup.string().required(t("Validation:fieldRequired")),
        description: Yup.string().required(t("Validation:fieldRequired")),
        operation_sequence: Yup.number().typeError(t("Validation:pleaseEnterAnInteger")).required(t("Validation:fieldRequired")),
        rccp: Yup.string().required(t("Validation:fieldRequired")),
        work_center: Yup.string().required(t("Validation:fieldRequired")),
        crew_size: Yup.number().typeError(t("Validation:pleaseEnterAnInteger")).integer(t("Validation:pleaseEnterAnInteger")).required(t("Validation:fieldRequired")),
        standard_time_m: Yup.number().typeError(t("Validation:pleaseEnterNumber")).required(t("Validation:fieldRequired")),
        standard_time_l: Yup.number().typeError(t("Validation:pleaseEnterNumber")).required(t("Validation:fieldRequired")),
        standard_time_s: Yup.number().typeError(t("Validation:pleaseEnterNumber")).required(t("Validation:fieldRequired")),
        idicator_eff: Yup.number().typeError(t("Validation:pleaseEnterNumber")).required(t("Validation:fieldRequired")),
        machinery_id: Yup.number().moreThan(0, t("Validation:pleaseSelectValue")).required(t("Validation:fieldRequired")),
    }),[t]);
    
    return (
        <Formik initialValues={record}
                validateOnChange
                validateOnMount={parseInt(record.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  submitForm,  
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg" scrollable={true}>
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faBoxes} className="mr-2"/> {t("productEdit")}</h2></CModalHeader>
                    
                <CModalBody className="p-4">
                    <CForm onSubmit={handleSubmit}>
                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="time_active">
                            {({field, field: { value }}) =>
                                <CSwitch id="time_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                         variant="opposite"
                                         {...field} value={value === 0 ? true : false}/>
                            }
                        </Field>
                        <CLabel htmlFor="time_active" className="ml-2 user-select-none">{t("ProductFormModal:timeActive")}</CLabel>
                    </CFormGroup>
                    
                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="last_step">
                            {({field, field: { value }}) =>
                                <CSwitch id="last_step" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                         variant="opposite"
                                         {...field} value={value === 0 ? true : false}/>
                            }
                        </Field>
                        <CLabel htmlFor="last_step" className="ml-2 user-select-none">{t("ProductFormModal:lastStep")}</CLabel>
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="parent_item_number_2nd">{t("ProductFormModal:parentItemNumber2nd")}<sup>*</sup></CLabel>
                        <Field name="parent_item_number_2nd">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="parent_item_number_2nd" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="item_number">{t("ProductFormModal:itemNumber")}<sup>*</sup></CLabel>
                        <Field name="item_number">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="item_number" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="description">{t("ProductFormModal:description")}<sup>*</sup></CLabel>
                        <Field name="description">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="description" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="description2">{t("ProductFormModal:description2")}<sup>*</sup></CLabel>
                        <Field name="description2">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="description2" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="operation_sequence">{t("ProductFormModal:operationSequence")}<sup>*</sup></CLabel>
                        <Field name="operation_sequence">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="operation_sequence" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="operation_description" className="ml-2 user-select-none">{t("ProductFormModal:operationDescription")}</CLabel>
                        <Field name="operation_description">
                            {({
                                  field
                              }) =>
                                <CTextarea {...field} />
                            }
                        </Field>
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="rccp">{t("ProductFormModal:rccp")}<sup>*</sup></CLabel>
                        <Field name="rccp">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="rccp" />
                    </CFormGroup>
                
                    <CFormGroup>
                        <CLabel htmlFor="work_center">{t("ProductFormModal:workCenter")}<sup>*</sup></CLabel>
                        <Field name="work_center">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="work_center" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="crew_size">{t("ProductFormModal:crewSize")}<sup>*</sup></CLabel>
                        <Field name="crew_size">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="crew_size" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="standard_time_m">{t("ProductFormModal:standardTimeM")}<sup>*</sup></CLabel>
                        <Field name="standard_time_m">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="standard_time_m" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="standard_time_l">{t("ProductFormModal:standardTimeL")}<sup>*</sup></CLabel>
                        <Field name="standard_time_l">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="standard_time_l" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="standard_time_s">{t("ProductFormModal:standardTimeS")}<sup>*</sup></CLabel>
                        <Field name="standard_time_s">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="standard_time_s" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="idicator_eff">{t("ProductFormModal:idicatorEff")}<sup>*</sup></CLabel>
                        <Field name="idicator_eff">
                            {({
                                  field,
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="idicator_eff" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="time_entry">{t("ProductFormModal:timeEntry")}<sup>*</sup></CLabel>
                        <div>
                            <DateTimePickerField fieldName="time_entry" />
                        </div>
                        <FieldErrorMessage name="time_entry" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="link_image">{t("ProductFormModal:linkImage")}<sup>*</sup></CLabel>
                        <Field name="link_image">
                            {({
                                  field,
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="link_image" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="machinery_id">{t("ProductFormModal:machinery")}<sup>*</sup></CLabel>
                        <Field name="machinery_id">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {machinery.map( r => <option key={r.id} value={r.id}>{r.description}</option>)}
                                </CSelect>
                            }
                        </Field>
                        <FieldErrorMessage name="machinery_id" />
                    </CFormGroup>
                    
                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="is_active">
                            {({field, field: { value }}) =>
                                    <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                variant="opposite"
                                                {...field} value={value === 0 ? true : false}/>
                            }
                        </Field>
                        <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("BOMFormModal:active")}</CLabel>
                    </CFormGroup>
                    </CForm>

                    {record.id > 0 && <CommonRecordData item={record} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" onClick={submitForm} color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

ProductFormModal.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        is_active: PropTypes.number,
        time_active: PropTypes.number,
        last_step: PropTypes.number,
        parent_item_number_2nd: PropTypes.string,
        item_number :PropTypes.string,
        description: PropTypes.string,
        description2: PropTypes.string,
        operation_sequence: PropTypes.number,
        operation_description: PropTypes.string,
        rccp: PropTypes.string,
        work_center: PropTypes.string,
        crew_size: PropTypes.number,
        standard_time_m: PropTypes.number,
        standard_time_l: PropTypes.number,
        standard_time_s: PropTypes.number,
        idicator_eff: PropTypes.number,
        time_entry: PropTypes.string,
        link_image: PropTypes.string,
        machinery_id: PropTypes.number
    }),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

ProductFormModal.defaultProps = {
    loading: false,
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default ProductFormModal;