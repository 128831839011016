import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/pro-light-svg-icons";
import {useToaster} from "../../context-providers/ToasterProvider";
import {useTranslation} from "react-i18next";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    copyToClipboardIcon: {
        fontSize: "1.3em",
        "&:hover": {
            color:"#dc7300"
        }
    }
});

const WOCell = ({value}) => {
    
    const classes = useStyles();
    const {t} = useTranslation(["Common"]);
    const {toastInfo} = useToaster();
    const copyToClipboard = (evt) => {
        evt.stopPropagation();
        navigator.clipboard.writeText(value);
        toastInfo(t("Common:copiedToClipboard"));
    }
    return (
        <div className="d-flex align-items-center"><span className="mr-2">{value}</span>
            <FontAwesomeIcon icon={faCopy} className={`ml-auto cursor-pointer ${classes.copyToClipboardIcon}`}
                             title={t("Common:copyToClipboard")}
                             data-copy-to-clipboard
                             onClick={copyToClipboard}/></div>
    );
};

export const woCellRenderer = (params) => <WOCell value={params.value} />;

WOCell.propTypes = {
    value: PropTypes.string
};
