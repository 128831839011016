import { ContentContainer } from "./ContentContainer";
import { Header, Footer, Sidebar } from "../components";
import {useLocation} from "react-router-dom";

export const LayoutContainer = () => {
    
    // Planning nie ma nagłówka dla zaoszczędzenia miejsca w pionie
    const location = useLocation();
    
    return (
        <div className="c-app c-default-layout">
            <Sidebar/>
            <div className="c-wrapper">
                {location.pathname !== "/planning" && <Header />}
                <div className="c-body">
                    <ContentContainer />
                </div>
                {location.pathname !== "/planning" && <Footer />}
            </div>
        </div>
    );
};

export default LayoutContainer;