import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector, authSelector} from "../redux/selectors";

export const useUserRoles = (placeholder) => {
    const apiUrl = useSelector(apiUrlSelector);

    const { access_token } = useSelector(authSelector);
    
    return useQuery(["user-roles", apiUrl], () => 
            axios.get(`${apiUrl}user-roles`).then(res => res.data.data),
        {
            refetchOnWindowFocus: false,
            placeholderData: placeholder
        }
    );
};

export default useUserRoles;