import { createReducer } from "@reduxjs/toolkit";
import {SetSidebarState, SetArea} from "../actions/app.actions";

const initialState = {
    sidebarShow: 'responsive',
    area: { id: 0, name: ""}
};

export const appReducer = createReducer(initialState, (builder) => {
    builder.addCase(SetSidebarState, (state, action)=> {
        state.sidebarShow = action.payload;
        return state;

    }).addCase(SetArea, (state, action) => {
        state.area = action.payload
        return state;
    }).addDefaultCase((state, action) => state)
});
