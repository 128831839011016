import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useDeleteRecord = ({ url, onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    const queryClient = useQueryClient();

    return useMutation(id => {
                 
            return axios.delete(`${apiUrl}${url}/${id}`).then(res => res.data);
            
        },
        {
            onSuccess: () => {
                onSuccess();
                queryClient.invalidateQueries([url, apiUrl]);
            }, onError: onError
        }
    );
};

export default useDeleteRecord;