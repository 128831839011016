import PropTypes from "prop-types";
import noop from "../../utils/noop";
import { CContainer, CNavbar }from "@coreui/react";
import { useTranslation} from "react-i18next";
import CLogin from "../../components/Login";
import { LangPicker } from "../../components";
import languages from "../../common/languages";

export const LoginView = ({validator, onLoginClick, inputsDisabled, loading, error}) => {
    
    const { i18n } = useTranslation();
    
    const handleLangChange = lang => {
        i18n.changeLanguage(lang);
    };
    
    return <>
        <CNavbar sticky className="fluid d-flex justify-content-end">
            <LangPicker languages={languages} lang={i18n.language} onChange={handleLangChange} />
        </CNavbar>
    <CContainer className="d-flex h-100 flex-column align-items-center justify-content-center">
        <CLogin validator={validator} onLoginClick={onLoginClick} register={true} inputsDisabled={inputsDisabled} loading={loading} error={error} />        
    </CContainer>
        </>; 
    
};

LoginView.displayName = "LoginView";

LoginView.propTypes = {
    validator: PropTypes.func,
    area: PropTypes.number,
    inputsDisabled: PropTypes.bool,
    loading: PropTypes.bool,
    onLoginClick: PropTypes.func,
    error: PropTypes.string
};

LoginView.defaultProps = {
    inputsDisabled: false,
    loading: false,
    area: 0,
    error: "",
    onLoginClick: noop
};

export default LoginView;