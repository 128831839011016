import PropTypes from 'prop-types';
import {createUseStyles} from "react-jss";
import {usePlanningContext} from "./PlanningContextProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentAltLines} from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

const useStyles = createUseStyles({
    icon: {
        fontSize: "1.4em",
        "&:hover": {
            color:"#dc7300"
        }
    }
});

export const NotesCell = ({id, value}) => {
    
    const classes = useStyles();
    const {onNotesClick} = usePlanningContext();
    
    return (
        <div className="text-center d-flex align-items-center justify-content-center">
            {value} <FontAwesomeIcon className={classNames("ml-2 cursor-pointer", classes.icon)} icon={faCommentAltLines} onClick={ evt => {
                evt.stopPropagation();
                onNotesClick(id);
            }} />
        </div>
    );
};

export const notesCellRenderer = (params) => <NotesCell id={params.data.id} value={params.data.notes} />;

NotesCell.propTypes = {
    id: PropTypes.number,
    value: PropTypes.number
};

export default NotesCell;