import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export const ImportButton = ({className, ...props}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton type="button" color="warning" size="sm" className={classNames("text-white", className)} {...props}><FontAwesomeIcon icon={faUpload} className="mr-2"/>{t("import")}</CButton>
    );
};


export default ImportButton;