import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CSwitch,
    CTextarea,
    CSelect,
} from "@coreui/react";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import LoginField from "./LoginField";
import CommonRecordData from "../CommonRecordData/CommonRecordData";

export const UserFormModal = ({user, onSaveClick, onCancelClick, show, loading, onClose, roles}) => {
    
    const {t} = useTranslation(["UserFormModal", "Buttons", "Validation"]);
    
    const validationSchema = useMemo( () => Yup.object({
        first_name: Yup.string().required(t("Validation:fieldRequired")),
        last_name: Yup.string().required(t("Validation:fieldRequired")),
        email: Yup.string().email(t("Validation:emailNotValid")).required(t("Validation:fieldRequired")),
        user_roles_id: Yup.number().moreThan(0,t("Validation:pleaseSelectValue")),
        password: Yup.string().when("changePassword", (changePassword, schema ) => {
            if ( changePassword ) {
                return schema.required(t("Validation:fieldRequired"));
            }
            return schema;
        }),
        confirmPassword: Yup.string().when("changePassword", (changePassword, schema) => {
            
          if ( changePassword ) {
              return schema.required(t("Validation:fieldRequired")).oneOf([Yup.ref("password")], t("Validation:passwordsMismatch"));
          } 
          
          return schema
            
        }),
    }));
    
    return (
        <Formik initialValues={user}
                validateOnChange
                validateOnMount={parseInt(user.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg">
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faUser} className="mr-2"/> {t("userEdit")}</h2></CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                <CModalBody className="p-4">
                    
                        <CFormGroup>
                            <CLabel htmlFor="first_name">{t("firstName")}<sup>*</sup></CLabel>
                            <Field name="first_name">
                                {({
                                      field, // { name, value, onChange, onBlur },
                                      meta: {touched, error}
                                  }) =>
                                    <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                                }
                            </Field>
                            <FieldErrorMessage name="first_name" />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="last_name">{t("lastName")}<sup>*</sup></CLabel>
                            <Field name="last_name">
                                {({
                                      field,                                     
                                      meta: {touched, error}
                                  }) => <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                                    
                                }
                            </Field>
                            <FieldErrorMessage name="last_name" />
                        </CFormGroup>
                        <CFormGroup>
                            <CLabel htmlFor="email">{t("email")}<sup>*</sup></CLabel>
                            <Field name="email">
                                {({
                                      field,
                                      meta: {touched, error}
                                  }) =>
                                    <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error} />
                                }
                            </Field>
                            <FieldErrorMessage name="email" />
                        </CFormGroup>
                        
                        <CFormGroup>
                            <CLabel htmlFor="username">{t("username")}<sup>*</sup></CLabel>
                                <LoginField/>
                            <FieldErrorMessage name="username" />
                        </CFormGroup>

                        {values.id > 0 &&<CFormGroup className="d-flex flex-row">
                            <Field type="checkbox" name="changePassword">
                                {({field, field: { value }}) =>
                                    <CSwitch id="changePassword" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                             variant="opposite"
                                             {...field} value={value}/>
                                }
                            </Field>
                            <CLabel htmlFor="changePassword" className="ml-2 user-select-none">{t("changePassword")}</CLabel>
                            
                        </CFormGroup>}
                    
                    {((parseInt(values.id) > 0 && values.changePassword) || (parseInt(values.id) === 0))  && <>
                            <CFormGroup>
                                <CLabel htmlFor="password" className="user-select-none">{t("password")}<sup>*</sup></CLabel>
                                <Field name="password">
                                    {({
                                          field, field: {value},
                                          meta: {touched, error}
                                      }) =>
                                        <CInput autoComplete="off" type="password" {...field} value={value??""} invalid={error && touched} valid={touched && !error} />
                                    }
                                </Field>
                                <FieldErrorMessage name="password" className="user-select-none"/>
                            </CFormGroup>
                            <CFormGroup>
                                <CLabel htmlFor="confirmPassword" className="user-select-none">{t("confirmPassword")}<sup>*</sup></CLabel>
                                <Field name="confirmPassword">
                                    {({
                                          field, field: { value },
                                          meta: {touched, error}
                                      }) =>
                                        <CInput autoComplete="off" type="password" {...field} value={value??""} invalid={error && touched} valid={touched && !error}  />
                                    }
                                </Field>
                                <FieldErrorMessage name="confirmPassword" className="user-select-none" />
                            </CFormGroup>
                        </>}
                    
                        <CFormGroup className="d-flex flex-row">
                            <Field type="checkbox" name="is_active">
                                {({field, field: { value }}) =>
                                        <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                    variant="opposite"
                                                    {...field} value={value === 0 ? true : false}/>
                                }
                            </Field>
                            <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("active")}</CLabel>
                        </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="user_roles_id">{t("role")}<sup>*</sup></CLabel>                         
                        <Field name="user_roles_id">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {roles.map( r => <option key={r.id} value={r.id}>{r.name}</option>)}
                                </CSelect>

                            }
                        </Field>
                        <FieldErrorMessage name="user_roles_id" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="description" className="ml-2 user-select-none">{t("description")}</CLabel>
                        <Field name="description">
                            {({
                                  field
                              }) => 
                                <CTextarea {...field} />
                            }
                        </Field>
                    </CFormGroup>
                    
                    {user.id > 0 && <CommonRecordData item={user} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    </CForm>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

UserFormModal.propTypes = {
    user: PropTypes.shape({
        is_active: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        login: PropTypes.string,
        password: PropTypes.string,
        confirmPassword: PropTypes.string,
        description: PropTypes.string
    }),
    roles: PropTypes.array,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

UserFormModal.defaultProps = {
    loading: false,
    roles: [],
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default UserFormModal;