import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useUnitsOfMeasurement = () => {
    const apiUrl = useSelector(apiUrlSelector);
    return useQuery(["units-of-measurement", apiUrl], () =>
        axios.get(`${apiUrl}units-of-measurement`).then(res => res.data.data)
    ,{
        placeholderData:[],
        refetchOnWindowFocus: false
    });
};

export default useUnitsOfMeasurement;