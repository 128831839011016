import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader, CSelect,
    CSwitch,
} from "@coreui/react";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPuzzlePiece } from "@fortawesome/pro-light-svg-icons";

import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import CommonRecordData from "../CommonRecordData/CommonRecordData";

export const BOMFormModal = ({record, onSaveClick, onCancelClick, show, loading, onClose, unitsOfMeasurement}) => {
    
    const {t} = useTranslation(["BOMFormModal", "Buttons", "Validation"]);
    
    const validationSchema = useMemo( () => Yup.object({
        description: Yup.string().required(t("Validation:fieldRequired")),
        parent_item_number: Yup.string().required(t("Validation:fieldRequired")),
        parent_item_number_2nd: Yup.string().required(t("Validation:fieldRequired")),
        item_number: Yup.string().required(t("Validation:fieldRequired")),
        item_number_2nd: Yup.string().required(t("Validation:fieldRequired")),
        item_number_3rd: Yup.string().required(t("Validation:fieldRequired")),
        quantity: Yup.number().typeError(t("Validation:pleaseEnterNumber")).required(t("Validation:fieldRequired")),
        units_of_measurement_id: Yup.number().moreThan(0,t("Validation:pleaseSelectValue")),
        step: Yup.number().typeError(t("Validation:pleaseEnterAnInteger")).required(t("Validation:fieldRequired")) ,
    }),[t]);
    
    return (
        <Formik initialValues={record}
                validateOnChange
                validateOnMount={parseInt(record.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  submitForm,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg" scrollable={true}>
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faPuzzlePiece} className="mr-2"/> {t("bomEdit")}</h2></CModalHeader>
                    
                <CModalBody className="p-4">

                    <CForm onSubmit={handleSubmit}>
                    <CFormGroup>
                        <CLabel htmlFor="description">{t("BOMFormModal:description")}<sup>*</sup></CLabel>
                        <Field name="description">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="description" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="parent_item_number">{t("BOMFormModal:parentItemNumber")}<sup>*</sup></CLabel>
                        <Field name="parent_item_number">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="parent_item_number" />
                    </CFormGroup>
                
                    <CFormGroup>
                        <CLabel htmlFor="parent_item_number_2nd">{t("BOMFormModal:parentItem2ndNumber")}<sup>*</sup></CLabel>
                        <Field name="parent_item_number_2nd">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="parent_item_number_2nd" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="item_number">{t("BOMFormModal:itemNumber")}<sup>*</sup></CLabel>
                        <Field name="item_number">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="item_number" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="item_number_2nd">{t("BOMFormModal:itemNumber2nd")}<sup>*</sup></CLabel>
                        <Field name="item_number_2nd">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="item_number_2nd" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="item_number_3rd">{t("BOMFormModal:itemNumber3rd")}<sup>*</sup></CLabel>
                        <Field name="item_number_3rd">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="item_number_3rd" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="quantity">{t("BOMFormModal:quantity")}<sup>*</sup></CLabel>
                        <Field name="quantity">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="quantity" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="step">{t("BOMFormModal:step")}<sup>*</sup></CLabel>
                        <Field name="step">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="step" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="link_image">{t("BOMFormModal:linkImage")}</CLabel>
                        <Field name="link_image">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="link_image" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="units_of_measurement_id">{t("BOMFormModal:unitOfMeasurement")}<sup>*</sup></CLabel>
                        <Field name="units_of_measurement_id">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {unitsOfMeasurement.map( r => <option key={r.id} value={r.id}>{r.unit} {r.description.length > 0 ? `(${r.description})`: ''}</option>)}
                                </CSelect>

                            }
                        </Field>
                        <FieldErrorMessage name="units_of_measurement_id" />
                    </CFormGroup>
                    
                    
                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="is_active">
                            {({field, field: { value }}) =>
                                    <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                variant="opposite"
                                                {...field} value={value === 0 ? true : false}/>
                            }
                        </Field>
                        <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("BOMFormModal:active")}</CLabel>
                    </CFormGroup>
                    </CForm>

                    {record.id > 0 && <CommonRecordData item={record} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" onClick={submitForm} color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

BOMFormModal.propTypes = {
    record: PropTypes.shape({
        id: PropTypes.number,
        is_active: PropTypes.number,
        parent_item_number: PropTypes.string,
        parent_item_number_2nd: PropTypes.string,
        item_number: PropTypes.string,
        item_number_2nd: PropTypes.string,
        item_number_3rd: PropTypes.string,
        description: PropTypes.string,
        quantity: PropTypes.number,
        step: PropTypes.number,
        link_image: PropTypes.string
        
    }),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

BOMFormModal.defaultProps = {
    loading: false,
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default BOMFormModal;