import {useEffect} from "react";
import PropTypes from 'prop-types';
import noop from "../utils/noop";
import useCreateStopGroup from "../hooks/useCreateStopGroup";
import useStopGroup from "../hooks/useStopGroup";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import StopGroupFormModal from "../components/StopGroupFormModal";

const model = {
    id: 0,
    is_active: 1,
    code: "",
    description: "",
};

export const StopGroupFormContainer = ({onCancelClick, onSave, recordId, onGetError}) => {
    
    const {t} = useTranslation("StopGroupFormContainer");
    const { toastError, toastSuccess } = useToaster();
    
    const { isLoading, isFetching, error, data } =  useStopGroup({
        recordId,
        placeholder: model});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getGroupDataError"));
        }
    }, [error] );
    
    const { mutate: createRecord, isLoading: isSavingArea } = useCreateStopGroup({
        onError: err => toastError(t("saveGroupError")),
        onSuccess: () => {
            toastSuccess(t("saveGroupSuccess"));
            onSave();
        }
    });
    
    const handleSaveClick = (values, actions) => {
        const data = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( data.id === 0 ) {
            delete data.id;
        }
        createRecord(data);
    };
    
    return (
        <>
            <StopGroupFormModal show={true}
               loading={isLoading || isFetching || isSavingArea}
               record={data ?? model}
               onClose={onCancelClick}
               onSaveClick={handleSaveClick} onCancelClick={onCancelClick} />
       </>
    );
};

StopGroupFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

StopGroupFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default StopGroupFormContainer;