import {useEffect, useState, useRef} from "react";
import PropTypes from 'prop-types';
import {usePlanningContext} from "./PlanningContextProvider";
import {CElementCover} from "@coreui/react";
import {createUseStyles} from "react-jss";

const KEY_LEFT = 37;
const KEY_UP = 38;
const KEY_RIGHT = 39;
const KEY_DOWN = 40;
const KEY_PAGE_UP = 33;
const KEY_PAGE_DOWN = 34;
const KEY_PAGE_HOME = 36;
const KEY_PAGE_END = 35;

const useStyles = createUseStyles({
    input: {
        width: "100%",
        border: 0,
        outline:0,
        "&:focus": {
            outline:0
        }
    }
});

const OrderCell = ({value, params}) => {
    
    const classes = useStyles();
    
    const inputRef = useRef();
    const [isUpdating, setIsUpdating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    
    const {onOrderCellValueChange} = usePlanningContext();
    
    
    const handleDoubleClick = evt => {
        evt.stopPropagation();
        
        if ( isEditing === false ) {
            setIsEditing(true);
        }   
        
    }

    useEffect(() => {
        if (isEditing === true) {
            inputRef.current.focus();
        }
    }, [isEditing]);
    
    
    const handleInput = evt => {
        
        const newVal = evt.target.value.replace(/[^\d]+/,"");
        setCurrentValue(newVal);
    }
    
    const handleKeyDown = evt => {
        
        const keyCode = evt.keyCode;
        
        // W edytorze trzeba zatrzymać akcję dla klawiszy nawigacji,
        // żeby np naciśnięcie strzałki nie spowodowało przesunięcia po komórkach
        const isNavigationKey = keyCode === KEY_LEFT ||
            keyCode === KEY_RIGHT ||
            keyCode === KEY_UP ||
            keyCode === KEY_DOWN ||
            keyCode === KEY_PAGE_DOWN ||
            keyCode === KEY_PAGE_UP ||
            keyCode === KEY_PAGE_HOME ||
            keyCode === KEY_PAGE_END;

        if (isNavigationKey) {
            // this stops the grid from receiving the event and executing keyboard navigation
            evt.stopPropagation();
        }
        
        if ( evt.key === "Enter" ) {
            
            if ( currentValue.length > 0 && currentValue.toString() !== value.toString()) {
                setIsUpdating(true);
                onOrderCellValueChange(params.data.id, currentValue, () => {
                    setIsUpdating(false);
                    setIsEditing(false);
                }, () => {
                    setIsUpdating(false);
                    setIsEditing(false);
                    setCurrentValue(value);
                });
            } else {
                setCurrentValue(value);
                setIsEditing(false);
            }
            
        } else if ( evt.key === "Escape" ) {
            setCurrentValue(value);
            setIsEditing(false);
        }
        
    }
    
    const handleBlur = () => {
        setCurrentValue(value);
        setIsEditing(false);
    }
    
    return (
        <div onDoubleClick={handleDoubleClick} className="imi-internal-status-cell">
            {isEditing ? <input ref={inputRef} value={currentValue} disabled={isUpdating} onClick={e => e.stopPropagation()} onInput={handleInput} onKeyDown={handleKeyDown}
                                onBlur={handleBlur}
                                maxLength="18"
                            className={classes.input}/> : currentValue}
            {isUpdating && <CElementCover color="primary" opacity={0} className="imi-internal-status-cell__cover" />}
        </div>
    );
};

export const orderCellRenderer = (params) => <OrderCell value={params.value} params={params} />;

OrderCell.propTypes = {
    value: PropTypes.string
};
