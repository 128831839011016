import { createAction } from '@reduxjs/toolkit'

const AUTH_ACTION = "Auth/";

export const AuthActionTypes = {
    SetAuthState: `${AUTH_ACTION}SetAuthState`,
    SetRefreshToken: `${AUTH_ACTION}SetRefreshToken`,
    SetAccessToken:  `${AUTH_ACTION}SetAccessToken`,
    SetExpiresIn: `${AUTH_ACTION}SetExpiresIn`
};

export const SetAuthState = createAction(AuthActionTypes.SetAuthState );
export const SetRefreshToken = createAction(AuthActionTypes.SetRefreshToken);
export const SetExpiresIn = createAction(AuthActionTypes.SetExpiresIn);
export const SetAccessToken = createAction(AuthActionTypes.SetAccessToken);
