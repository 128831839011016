import {useEffect, useState} from "react";
import {EmployeeList, MachineryList} from "../components";
import { useTranslation } from "react-i18next";
import ImportModal from "../components/ImportModal";
import MachineFormContainer from "./MachineFormContainer";
import {useImportModal} from "../hooks/useImportModal";
import {useToaster} from "../context-providers/ToasterProvider";
import useMachinery from "../hooks/useMachinery";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import {useDeleteModal} from "../hooks/useDeleteModal";
import {apiUrls} from "../common/apiUrls";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";

export const MachineryContainer = () => {
    
    const {t} = useTranslation(["MachineryContainer", "Buttons"]);

    const apiUrl = useSelector(apiUrlSelector);
    
    const { toastError } = useToaster();
    
    const [recordId, setRecordId] = useState(0);
    
    const [showRecordModal, setShowRecordModal] = useState(false);
    
    const { isLoading, error, data } =  useMachinery();
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("errorFetchingMachinery"));
        }
    }, [error] );
    
    const { modal: importModal, onImportClick } = useImportModal("import-machinery");
    
    const { confirmDelete, ...deleteModalProps } = useDeleteModal(apiUrls.machinery);
    
    const handleCloseRecordModal = () => setShowRecordModal(false);
    
    const handleAddRecordClick = () => {
      
        // Pokaż modala z formularzem usera
        setRecordId(0);
        setShowRecordModal(true);
        
    };
    
    const handleEditClick = row => {
        
        setRecordId(row.id);
        setShowRecordModal(true);
        
    };

    const handleHistoryClick = (item) => {
        console.log("Pokazuję historię", item);
    };
    
    return (         
        <>
            {showRecordModal && <MachineFormContainer machineId={recordId}
                                                 onSave={handleCloseRecordModal}
                                                 onCancelClick={handleCloseRecordModal}
                                                 onGetError={handleCloseRecordModal} />}
                                             
            <ImportModal {...importModal} />

            <ConfirmDeleteModal {...deleteModalProps} />
            
            <MachineryList machinery={data} loading={isLoading} onAddClick={handleAddRecordClick}
                      onEditClick={handleEditClick} onDeleteClick={confirmDelete} onHistoryClick={handleHistoryClick}
                      onImportClick={onImportClick}
                      exportHref={`${apiUrl}/machinery/export"`} />
        </>
    );
};

export default MachineryContainer;