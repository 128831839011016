import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CElementCover,
    CProgress
} from "@coreui/react";
import { CloseButton  } from "../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalculator} from "@fortawesome/pro-light-svg-icons";
import {usePlanningContext} from "./PlanningContextProvider";
import {format} from "date-fns";
import {DATE_FORMAT} from "../../common/formats";
export const SimulationModal = () => {
    
    const {operationDate, isSimulationLoading, setSimulationModalVisible, simulationModalVisible, simulationData} = usePlanningContext();
    
    const {t} = useTranslation("SimulationModal");
    
    const dateFormatted = useMemo(() => format(operationDate, DATE_FORMAT),[operationDate]);
    
    const handleClose = useCallback(() => setSimulationModalVisible(false),[setSimulationModalVisible]); 
    
    return (
        <CModal size="xl" show={simulationModalVisible} onClose={handleClose} closeOnBackdrop={false} scrollable>
            <CModalHeader closeButton><h2><FontAwesomeIcon icon={faCalculator} className="mr-2"/>
                {t("simulationForDay")} {dateFormatted}</h2></CModalHeader>
            <CModalBody className="text-center py-4">
                
                {simulationData && simulationData.map( wo => {
                    return Object.keys(wo).map( k => <div key={k}><h3 className="mt-4 mb-3 text-left">{t("productionOrderNo")}: <span className="text-dark">{k}</span></h3>
                        <table className="table table-sm table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>{t("product")}</th>
                                    <th>{t("operationSequence")}</th>
                                    <th>{t("operationDescription")}</th>
                                    <th>{t("machine")}</th>
                                    <th>{t("machineLoadLevelBefore")}</th>
                                    <th>{t("machineLoadLevelAfter")}</th>
                                    <th>{t("loadLevel")}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {Object.keys(wo[k]).map( p => {
                                const item = wo[k][p];
                                const product = item.product;
                                const loadLevelBefore = (item.loadLevelBefore*100).toFixed(2);
                                const loadLevelAfter = ((item.loadLevelBefore+item.loadLevelProduct)*100).toFixed(2);
                                const loadLevel = (item.loadLevelProduct*100).toFixed(2);
                                return <tr key={p}>
                                    <td>{product.parent_item_number_2nd}</td>
                                    <td>{product.operation_sequence}</td>
                                    <td className="text-left">{product.operation_description}</td>
                                    <td>{product.m_code_m}</td>
                                    <td>
                                        <div>{loadLevelBefore} %</div>
                                        <CProgress
                                            size="sm"
                                            color={loadLevelBefore <= 100 ? "success" : "danger"}
                                            value={loadLevelBefore}
                                            showValue={false}
                                            className="bg-gray-300"
                                        />
                                    </td>
                                    <td>
                                        <div>{loadLevelAfter} %</div>
                                        <CProgress
                                            size="sm"
                                            color={loadLevelAfter <= 100 ? "success" : "danger"}
                                            value={loadLevelAfter}
                                            showValue={false}
                                            className="bg-gray-300"
                                        />
                                    </td>
                                    <td>
                                        <div>{loadLevel} %</div>
                                        <CProgress
                                        size="sm"
                                        color={loadLevel <= 100 ? "success" : "danger"}
                                        value={loadLevel}
                                        showValue={false}
                                        className="bg-gray-300"
                                    /></td>
                                </tr>;
                            })}
                            </tbody>
                        </table>
                        
                    </div>);
                })}
                
            </CModalBody>
            <CModalFooter className="justify-content-center">
                    <CloseButton onClick={handleClose} className="bg-info text-white"/>                 
            </CModalFooter>
            {isSimulationLoading && <CElementCover/>}
        </CModal>
    );
};

export default SimulationModal;