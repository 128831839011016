import {useRef, useState, useMemo } from "react";
import {Field, useFormikContext} from "formik";
import {CInput, CInputGroup, CInputGroupAppend, CInputGroupText, CSpinner} from "@coreui/react";
import classNames from "classnames";
import {useDebouncedCallback} from "use-debounce";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {usePrevious} from "../../hooks/usePrevious";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../../redux/selectors";

export const LoginField = () => {

    const { values: { username: loginValue }, setFieldTouched, setFieldValue, initialValues: { username: initialLogin } } = useFormikContext();
    const {t} = useTranslation(["UserFormModal", "Buttons", "Validation"]);
    
    const apiUrl = useSelector(apiUrlSelector);
    
    const [isChecking, setIsChecking] = useState(false);
    const prevLogin = usePrevious(loginValue);
    
    const loginRef = useRef();
    
    const debouncedLoginValidation = useDebouncedCallback( (value, context, resolve) => {
        
        // Initial login, to login, jak buł przekazany do formularza
        // Jeżeli jest to edycja, to jeżeli aktualny login jest taki sam jak initial, to nie sprawdzamy
        // Bo jest to cały czas ten sam user z tym samym loginem
        if ( value.length > 0 && value !== prevLogin && value !== initialLogin ) {
            setIsChecking(true);
            
            axios.get(`${apiUrl}checklogin?username=${loginValue}`).then ( res => {
                setIsChecking(false);
                resolve(!res.data.isLogin);
            });
            
        } else {
            resolve(true);
        }
        
    }, 250);
    
    const loginSchema = useMemo( () => Yup.string().required(t("Validation:fieldRequired")).test("uniqueLogin", t("Validation:loginTaken"), (value, context) => new Promise ( resolve => debouncedLoginValidation.callback(value, context, resolve) )));
    
    return <Field name="username" validate={login => loginSchema.validate(login).then(v => {
        
        if (document.activeElement === loginRef.current) {
            setFieldTouched("username", true, false);
        }
        return undefined;
    }).catch(err => {
        if (document.activeElement === loginRef.current) {
            setFieldTouched("username", true, false); // Set touched and skip validation
        }
        return err.errors[0]
    })}
    >
        {({
              field, field: { name}, // { name, value, onChange, onBlur },
              meta: {touched, error, ...rest}
          }) => {

            return <CInputGroup className="imi-login-input">
                <CInput innerRef={loginRef} autoComplete="off" {...field} onChange={ (evt) => {
                    setFieldValue(name, evt.target.value.replace(/[^\w]/,"").toLowerCase());
                }}
                        invalid={error && touched} valid={touched && !error}
                        className="border-right-0 imi-login-input__field" />
                <CInputGroupAppend size="sm">
                    <CInputGroupText size="sm"
                                     className={classNames("imi-login-input__spinner-container",
                                         {
                                             "imi-login-input__spinner-container--invalid": touched && error,
                                             "imi-login-input__spinner-container--valid": touched && !error,
                                             "imi-login-input__spinner-container--checking": isChecking
                                         })}>
                        {isChecking &&
                        <div style={{transform: "translateX(7px) translateY(-1px)"}}><CSpinner size="sm"
                                                                                               color="success"/></div>}
                    </CInputGroupText>
                </CInputGroupAppend>
            </CInputGroup>
        }
        }
    </Field>
};

export default LoginField;