import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import useProduct from "../hooks/useProduct";
import noop from "../utils/noop";
import {ProductFormModal} from "../components";
import useCreateProduct from "../hooks/useCreateProduct";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import useMachinery from "../hooks/useMachinery";
import { format } from "date-fns";

const model = {
    id: 0,
    is_active: 1,
    time_active: 0,
    last_step: 0,
    parent_item_number_2nd: "",
    item_number: "",
    description: "",
    description2: "",
    operation_sequence: 0,
    operation_description: "",
    rccp: "",
    work_center: "",
    crew_size: 0,
    standard_time_m: 0,
    standard_time_l: 0,
    standard_time_s: 0,
    idicator_eff: 0,
    time_entry: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
    link_image: "",
    machinery_id: 0
};

export const ProductFormContainer = ({onCancelClick, onSave, recordId, onGetError}) => {

    const [machinery, setMachinery] = useState([]);
    const {t} = useTranslation("ProductFormContainer");
    const { toastError, toastSuccess } = useToaster();

    const { isLoading: machineryLoading, data: machineryData, error: machineryError } = useMachinery([]);

    useEffect(() => {
        if ( machineryData ) {
            setMachinery([{id: 0, description: t("select")}].concat(machineryData));
        }
    }, [machineryData]);
    
    const { isLoading, isFetching, error, data } =  useProduct({
        recordId,
        placeholder: model});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getProductDataError"));
        }
    }, [error] );
    
    const { mutate: createRecord, isLoading: isSavingRecord } = useCreateProduct({
        onError: err => toastError(t("saveProductError")),
        onSuccess: () => {
            toastSuccess(t("saveProductSuccess"));
            onSave();
        }
    });
    
    const handleSaveClick = (values, actions) => {
        const data = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( data.id === 0 ) {
            delete data.id;
        }
        
        createRecord(data);
        
    };
    
    return (
        <>
            <ProductFormModal show={true}
               loading={isLoading || isFetching || isSavingRecord}
               record={data ?? model}
               machinery={machinery}
               onClose={onCancelClick}
               onSaveClick={handleSaveClick} onCancelClick={onCancelClick} />
       </>
    );
};

ProductFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

ProductFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default ProductFormContainer;