import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload} from "@fortawesome/free-solid-svg-icons";


export const ExportButton = ({className, href, ...props}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton as="a" type="button" color="success" size="sm" className={classNames(className)} {...props}><FontAwesomeIcon icon={faDownload} className="mr-2"/>
            <a href={href} className="text-white text-decoration-none">{t("export")}</a>
        </CButton>
    );
};


export default ExportButton;