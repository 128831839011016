import { useState} from "react";
import useDeleteRecord from "./useDeleteRecord";
import {apiUrls} from "../common/apiUrls";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";

export const useDeleteModal =  (url) => {

    const {t} = useTranslation("ConfirmDeleteModal");
    const {toastError, toastSuccess} = useToaster();
    const [id, setId] = useState(0);
    const [loading, setLoading] = useState(false);
    
    const { mutate: deleteRecord, isLoading: isDeletingRecord } = useDeleteRecord({
        url,
        onError: err => toastError(t("deleteError")),
        onSuccess: () => {
            toastSuccess(t("deleteSuccess"));
            setLoading(false);
            onDeleteCancelClick();
        }
    });
    
    const [show,setShow] = useState(false);

    const confirmDelete = ({id}) => {
        setId(id);
        setShow(true);
    };

    const onDeleteCancelClick = () => {
        setId(0);
        setShow(false);
    };
    
    const onDeleteOkClick = () => {
        setLoading(true);
        deleteRecord(id);
    };
    
    
    return {
        show,
        loading,
        onDeleteOkClick,
        onDeleteCancelClick,
        confirmDelete
    }
    
};