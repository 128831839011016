import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useUpdateP = ({ onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    //const queryClient = useQueryClient();

    return useMutation(values => {
                 
        return axios.patch(`${apiUrl}stops/${values.id}`, values).then(res => res.data);
            
        },
        {
            onSuccess: () => {
                onSuccess();
                //queryClient.invalidateQueries(["stops", apiUrl]);
            }, onError: onError
        }
    );
};

export default useUpdateP;