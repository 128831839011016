import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useCreateUser = ({ onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    const queryClient = useQueryClient();

    return useMutation(values => {
        
            if ( parseInt(values.id) > 0 ) {
                return axios.patch(`${apiUrl}users/${values.id}`, values).then(res => res.data);
            } else {
                return axios.post(`${apiUrl}users`, values).then(res => res.data)
            }
        },
        {
            onSuccess: () => {
                onSuccess();
                queryClient.invalidateQueries(["users", apiUrl]);
            }, onError: onError
        }
    );
};

export default useCreateUser;