import {useEffect, useState} from "react";
import {EmployeeList, StopList as List} from "../components";
import { useTranslation } from "react-i18next";
import useStops from "../hooks/useStops";
import ImportModal from "../components/ImportModal";
import { StopFormContainer as FormContainer } from "./";
import {useImportModal} from "../hooks/useImportModal";
import {useToaster} from "../context-providers/ToasterProvider";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import useCreateStop from "../hooks/useCreateStop";
import {useDeleteModal} from "../hooks/useDeleteModal";
import {apiUrls} from "../common/apiUrls";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";

export const StopsContainer = () => {

    const {t} = useTranslation(["StopsContainer", "Buttons"]);

    const {toastSuccess} = useToaster();
    
    const apiUrl = useSelector(apiUrlSelector);

    const { toastError } = useToaster();

    const [recordId, setRecordId] = useState(0);

    const [showRecordModal, setShowRecordModal] = useState(false);

    const { isLoading, error, data } =  useStops();

    useEffect( ( ) => {
        if ( error ) {
            toastError(t("errorFetchingStops"));
        }
    }, [error] );

    const { modal: importModal, onImportClick } = useImportModal("url fo importu");

    const { confirmDelete, ...deleteModalProps } = useDeleteModal(apiUrls.stops);
    
    const handleCloseRecordModal = () => setShowRecordModal(false);
    
    const handleAddClick = () => {
        
        setRecordId(0);
        setShowRecordModal(true);

    };

    const handleEditClick = (row, id, col) => {
        
        // Jeżeli kliknąłem w kolumnę ze switchem to nie otwieram okienka edycji
        //const isSwitch = col.match(/p\d{1,2}/); 
        
        //if ( !isSwitch ) {
            setRecordId(row.id);
            setShowRecordModal(true);
        //}

    };

    const handleHistoryClick = (item) => {
        console.log("Pokazuję historię", item);
    };
    
    return (
        <>
            {showRecordModal && <FormContainer recordId={recordId}
                                               onSave={handleCloseRecordModal}
                                               onCancelClick={handleCloseRecordModal}
                                               onGetError={handleCloseRecordModal} />}

            <ImportModal {...importModal} />

            <ConfirmDeleteModal {...deleteModalProps} />
            
            <List records={data} loading={isLoading} onAddClick={handleAddClick}
                  onEditClick={handleEditClick} onDeleteClick={confirmDelete} onHistoryClick={handleHistoryClick}
                  onEditClick={handleEditClick}
                  onImportClick={onImportClick} exportHref={`${apiUrl}/scraping-codes/export"`} />
        </>
    );
};

export default StopsContainer;