import immutableObject from "../helpers/immutableObject";

function ConfigService() {
  
  const ret = immutableObject(null,{
    
    getConfig: {
      /**
       * Gets config from server
       * @returns {Promise<Object>}}
       */
      value: () => {

        return new Promise((resolve, reject) => {
          
          const baseHref = document.querySelector("base")?.href;
          
          const head = document.head;
          
          const script = document.createElement("script");
          script.src = `${baseHref}config.js`;
          
          const evtName = "config.received";
          const handleConfigReceived = (evt) => {
            
            head.removeChild(script);
            
            window.removeEventListener(evtName, handleConfigReceived );
            resolve(evt.detail);
            
          };
          
          window.addEventListener(evtName, handleConfigReceived );
          head.appendChild(script);
                     
        });
        
      }
    }
    
  });
  
  return ret;
}

export default ConfigService;