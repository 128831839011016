import {CButton} from "@coreui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

export const FilterButton = ({className, ...rest}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton color="info" className={classNames("px-2", className)} {...rest}><FontAwesomeIcon icon={faFilter} className="mr-2" />{t("filter")}</CButton>
    );
};

export default FilterButton;