import {CToast, CToastHeader, CToastBody, CToaster } from "@coreui/react";
import {useTranslation} from "react-i18next";
import {useToaster} from "../../context-providers/ToasterProvider";
import classNames from "classnames";
export const Toaster = () => {
    
    const {toasters} = useToaster();
    
    const {t} = useTranslation("Toaster");
    
    const toastProps  = (toast) => {
        if ( toast.type === "success" ) {
            return ["card-accent-success", "text-body", t("success")];
        } else if (toast.type === "info" ) {
            return ["card-accent-info", "text-body", t("info")];
        } else {
            return ["card-accent-danger", "text-body", t("error")];
        }
    };    
    
    return (
        <CToaster position="top-right">
            {toasters.map ( toast => {
                const [cardClass, cName, header] = toastProps(toast);
                return <CToast
                    {...toast}
                    fade={true}
                    autohide={toast.type === "success" ||  toast.type === "info" ? toast.time : false} className={cardClass}>
                    <CToastHeader closeButton className={`font-weight-bold ${cName}`}>{header}</CToastHeader>
                    <CToastBody className={classNames(cName)}>{toast.message}</CToastBody>
                </CToast> 
            })}
        </CToaster>
    );
};

export default Toaster;