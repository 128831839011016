import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import useUser from "../hooks/useUser";
import noop from "../utils/noop";
import {UserFormModal} from "../components/UserFormModal";
import useCreateUser from "../hooks/useCreateUser";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import useUserRoles from "../hooks/useUserRoles";

const userModel = {
    id: 0,
    first_name: "",
    last_name: "",
    username: "",
    email:"",
    is_active: 1,
    password:"",
    confirmPassword: "",
    changePassword: true,
    description: "",
    user_roles_id: 0
};

export const UserFormContainer = ({onCancelClick, onSave, userId, onGetError}) => {
    
    const [roles, setRoles] = useState([]);
    const {t} = useTranslation("UserFormContainer");
    const { toastError, toastSuccess } = useToaster();
    
    const { isLoading: rolesLoading, data: rolesData, error: rolesError } = useUserRoles([]);
    
    useEffect(() => {
        if ( rolesData ) {
            setRoles([{id: 0, name: t("select")}].concat(rolesData));
        }
    }, [rolesData]);
    
    const { isLoading, isFetching, error, data } =  useUser({
        userId,
        placeholder: userModel,
        /*filter: (userData) => {
            const retData = {...userData};
            delete retData.password_salt;
            delete retData.last_modification_date;
            return retData;
        }*/
    });
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getUserDataError"));
        }
    }, [error] );
    
    const { mutate: createUser, isLoading: isSavingUser } = useCreateUser({
        onError: err => toastError(t("saveUserError")),
        onSuccess: () => {
            toastSuccess(t("saveUserSuccess"));
            onSave();
        }
    });
    
    const handleSaveUserClick = (values, actions) => {
        const userData = {
            ...values,
            is_active: values.is_active ? 1 : 0,
            password: values.password ?? ""
        };
        
        delete userData.changePassword;
        delete userData.confirmPassword;
        
        if ( userData.id === 0 ) {
            delete userData.id;
        }
        
        createUser(userData);
    };
    
    return (
        <>
            <UserFormModal show={true}
               loading={isLoading || isFetching || isSavingUser}
               user={data ?? userModel}
               roles={roles}
               onClose={onCancelClick}
               onSaveClick={handleSaveUserClick} onCancelClick={onCancelClick} />
       </>
    );
};

UserFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

UserFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default UserFormContainer;