import { useMutation } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import {apiUrls} from "../common/apiUrls";

export const useCreateNote = ({ onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    
    return useMutation(values => {
        
            if ( parseInt(values.id) > 0 ) {
                return axios.patch(`${apiUrl}${apiUrls.notes}/${values.id}`, values).then(res => res.data);
            } else {
                return axios.post(`${apiUrl}${apiUrls.notes}`, values).then(res => res.data)
            }
        },
        {
            onSuccess,
            onError
        }
    );
};

export default useCreateNote;