import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useImport = ({ url, onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    const queryClient = useQueryClient();

    // TODDO: Url to będzie końcówak urla wskazująca co importować.
    return useMutation(values => {
        
            return new Promise((resolve, reject) => {
               
                setTimeout( () => resolve({ totalRecords: 2259, importedRecords: 2248}), 3000);
                
            });
            
        },
        {
            onSuccess,
            onError
        }
    );
};

export default useImport;