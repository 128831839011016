import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader, CSelect,
    CSwitch,
    CRow,
    CCol
} from "@coreui/react";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch} from "@fortawesome/pro-light-svg-icons";
import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import { range } from "lodash";
import CommonRecordData from "../CommonRecordData/CommonRecordData";

export const StopFormModal = ({record, onSaveClick, onCancelClick, show, loading, onClose, groups, machinery }) => {
    
    const {t} = useTranslation(["StopFormModal", "Buttons", "Validation"]);
    
    const pRows = useMemo( () => range(0,4).map( r =>
        range(1,6).map( c => `p${c + 5 * r}`)
    ),[]);
    
    const resetSwitchValues = (values, setFieldValue, omit) => {
        pRows.forEach( r => r.forEach( c => {
            if ( c !== omit) {
                // Walidacja jest wyłączona, bo nic nie wnosi, a zauważalnie spowalnia działanie interfejsu
                setFieldValue(c, 0, false);
            }
        } ));
    };
    
    const validationSchema = useMemo( () => Yup.object({
        description : Yup.string().required(t("Validation:fieldRequired")),
        stop_groups_id: Yup.string().required(t("Validation:fieldRequired")),
        machinery_id: Yup.number().required(t("Validation:fieldRequired")),
    }),[t]);
    
    return (
        <Formik initialValues={record}
                validateOnChange
                validateOnMount={parseInt(record.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField,
                  setFieldValue
                  /* and other goodies */
              }) => {
                
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg">
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faStopwatch} size="lg" className="mr-2"/> {t("stopEdit")}</h2></CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                <CModalBody className="p-4">
                    
                    <CFormGroup>
                        <CLabel htmlFor="description">{t("StopFormModal:description")}<sup>*</sup></CLabel>
                        <Field name="description">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="description" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="stop_groups_id">{t("StopFormModal:group")}<sup>*</sup></CLabel>
                        <Field name="stop_groups_id">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {groups.map( r => <option key={r.id} value={r.id}>{r._name ? r._name: `${r.code} (${r.description})`}</option>)}
                                </CSelect>

                            }
                        </Field>
                        <FieldErrorMessage name="stop_groups_id" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="machinery_id">{t("StopFormModal:machine")}<sup>*</sup></CLabel>
                        <Field name="machinery_id">
                            {({
                                  field, field: { value },
                                  meta: {touched, error}
                              }) =>

                                <CSelect {...field} invalid={error && touched} valid={touched && !error}>
                                    {machinery.map( r => <option key={r.id} value={r.id}>{r._name ? r._name : `${r.code_m} (${r.description})`}</option>)}
                                </CSelect>

                            }
                        </Field>
                        <FieldErrorMessage name="machinery_id" />
                    </CFormGroup>

                    <CFormGroup>
                        
                        {pRows.map( r => {
                            return <CRow key={`r${r}`}>
                                {r.map( c => {
                                    return <CCol key={c} className="d-flex justify-content-between">
                                        <CLabel htmlFor="is_active" className="ml-2 user-select-none">{`${c.toUpperCase()}`}</CLabel>
                                        <Field type="checkbox" name={c}>
                                            {({field, field: { value, onChange }}) => 
                                            {
                                                
                                                return <CSwitch id={c} shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                                variant="opposite" {...field} 
                                                                onChange={(evt) => {
                                                                    onChange(evt);
                                                                    resetSwitchValues(values, setFieldValue, c);
                                                                }}
                                                                 value={value === 0} />}
                                            }
                                        </Field>
                                </CCol>})}
                            </CRow>
                        })}
                        
                    </CFormGroup>    
                    
                    
                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="is_active">
                            {({field, field: { value }}) =>
                                <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                variant="opposite"
                                                {...field} value={value === 0 ? true : false}/>
                            }
                        </Field>
                        <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("StopFormModal:active")}</CLabel>
                    </CFormGroup>

                    {record.id > 0 && <CommonRecordData item={record} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    </CForm>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

StopFormModal.propTypes = {
    record: PropTypes.shape({
        is_active: PropTypes.number,
        stop_groups_id: PropTypes.number,
        machinery_id: PropTypes.number,
        category_level_1: PropTypes.string,
        category_level_2: PropTypes.string,
        description: PropTypes.string,
        p1: PropTypes.number,
        p2: PropTypes.number,
        p3: PropTypes.number,
        p4: PropTypes.number,
        p5: PropTypes.number,
        p6: PropTypes.number,
        p7: PropTypes.number,
        p8: PropTypes.number,
        p9: PropTypes.number,
        p10: PropTypes.number,
        p11: PropTypes.number,
        p12: PropTypes.number,
        p13: PropTypes.number,
        p14: PropTypes.number,
        p15: PropTypes.number,
        p16: PropTypes.number,
        p17: PropTypes.number,
        p18: PropTypes.number,
        p19: PropTypes.number,
        p20: PropTypes.number
    }),
    groups: PropTypes.array,
    machinery: PropTypes.array,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

StopFormModal.defaultProps = {
    loading: false,
    groups: [],
    machinery: [],
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default StopFormModal;