import {useCallback, useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CElementCover,
    CTextarea,
    CListGroup, CListGroupItem
} from "@coreui/react";
import {AddNoteButton, CloseButton} from "../Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCommentAltLines, faClock, faUser} from "@fortawesome/pro-light-svg-icons";
import {usePlanningContext} from "./PlanningContextProvider";
import useNotes from "../../hooks/useNotes";
import useCreateNote from "../../hooks/useCreateNote";
import {noteTypes} from "../../common/noteTypes";
import {apiUrls} from "../../common/apiUrls";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../../redux/selectors";
import {useQueryClient} from "react-query";
import classNames from "classnames";
import {useToaster} from "../../context-providers/ToasterProvider";
import usePrevious from "use-previous";

export const NotesModal = () => {

    const queryClient = useQueryClient();
    const {setNotesModalVisible, notesModalVisible, notesRecordId, filterPlanned} = usePlanningContext();
    const previousNotesRecordId = usePrevious(notesRecordId);
    const apiUrl = useSelector(apiUrlSelector);
    const {t} = useTranslation("NotesModal");
    const { toastError } = useToaster();
    
    const [noteValue, setNoteValue] = useState("");
    const [filterNotes, setFilterNotes] = useState({
        type: noteTypes.productionOrders,
        order: "add_date DESC"
    });
    
    useEffect(() => {
        // Bo nie chcemy zmieniać jeżeli zmieni się filterNotes, żeby się nie zapętlić
        if ( typeof previousNotesRecordId !== "undefined" && previousNotesRecordId !== notesRecordId) {
            setFilterNotes({...filterNotes, type_id: notesRecordId});
        }
    }, [notesRecordId, previousNotesRecordId, filterNotes]);
    
    // Enabled jest włączane tylko jeżeli jest podane id rekordu, dla którego mają zostać pobrane notatki
    const { data, isLoading } = useNotes(filterNotes, { enabled: notesRecordId > 0 });
    
    const { mutate: saveNote, isLoading: isSaving} = useCreateNote({});
    
    const handleInput = evt => setNoteValue(evt.target.value);
    
    const handleSave = () => {
        saveNote({
            type:noteTypes.productionOrders,
            type_id: notesRecordId,
            note:noteValue
        }, {
            onSuccess: () => {
                queryClient.invalidateQueries([apiUrls.notes, filterNotes, apiUrl]);
                queryClient.invalidateQueries([apiUrls.productionOrders, filterPlanned, apiUrl]);
                setNoteValue("");
            },
            onError: () => {
                toastError(t("saveNoteError"));
            }
        })
        
    }
    
    const handleClose = useCallback(() => setNotesModalVisible(false),[setNotesModalVisible]); 
    
    return (
        <CModal size="lg" show={notesModalVisible} onClose={handleClose} closeOnBackdrop={false} scrollable>
            <CModalHeader closeButton><h2><FontAwesomeIcon icon={faCommentAltLines} className="mr-2"/>
                {t("notes")}</h2></CModalHeader>
            <CModalBody className="py-4">
                <div>
                    <CTextarea value={noteValue} onInput={handleInput}  rows={5} placeholder={t("typeNote")}></CTextarea>
                    <div className="text-right">
                        <AddNoteButton onClick={handleSave} className="mt-2" disabled={noteValue.length === 0}/>
                    </div>
                </div>
                
                <CListGroup className="mt-4" accent>
                    {data && data.map( (note,i) => <CListGroupItem accent={ i%2===0 ? "info" : "warning"} key={note.id} className={classNames( "mb-1", { "list-group-item-light" : i%2===0} )}>
                        
                        <div className="d-flex w-100 justify-content-between">
                            <div><FontAwesomeIcon icon={faUser} /><strong className="ml-2 mb-1">{note.add_user_name}</strong></div>
                            <div className="d-flex align-items-center" style={{ fontSize: "0.95em"}}>
                                <FontAwesomeIcon icon={faClock} /> <span className="ml-1">{note.add_date}</span>
                            </div>
                        </div>
                        <p className="mb-1 mt-2 " style={{fontSize:"1.1em", whiteSpace: "pre-wrap"}}>
                            {note.note}
                        </p>
                    </CListGroupItem>)}
                </CListGroup>
            </CModalBody>
            <CModalFooter className="justify-content-center">
                    <CloseButton onClick={handleClose} className="bg-info text-white"/>                 
            </CModalFooter>
            {(isLoading || isSaving) && <CElementCover/>}
        </CModal>
    );
};

export default NotesModal;