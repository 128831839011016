import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CSwitch,
    CTextarea,
} from "@coreui/react";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouseAlt } from "@fortawesome/pro-light-svg-icons";
import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import CommonRecordData from "../CommonRecordData/CommonRecordData";

export const AreaFormModal = ({area, onSaveClick, onCancelClick, show, loading, onClose}) => {
    
    const {t} = useTranslation(["AreaFormModal", "Buttons", "Validation"]);
    
    const validationSchema = useMemo( () => Yup.object({
        name: Yup.string().required(t("Validation:fieldRequired")),
    }),[t]);
    
    return (
        <Formik initialValues={area}
                validateOnChange
                validateOnMount={parseInt(area.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg">
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faWarehouseAlt} className="mr-2"/>{t("areaEdit")}</h2></CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                <CModalBody className="p-4">
                    
                        <CFormGroup>
                            <CLabel htmlFor="name">{t("AreaFormModal:name")}<sup>*</sup></CLabel>
                            <Field name="name">
                                {({
                                      field, // { name, value, onChange, onBlur },
                                      meta: {touched, error}
                                  }) =>
                                    <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                                }
                            </Field>
                            <FieldErrorMessage name="name" />
                        </CFormGroup>
                    
                        <CFormGroup>
                            <CLabel htmlFor="code_aq">{t("AreaFormModal:codeAq")}<sup>*</sup></CLabel>
                            <Field name="code_aq">
                                {({
                                      field,                                     
                                      meta: {touched, error}
                                  }) => <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                                    
                                }
                            </Field>
                            <FieldErrorMessage name="code_aq" />
                        </CFormGroup>
                    
                        <CFormGroup>
                            <CLabel htmlFor="crew_no">{t("AreaFormModal:crewNo")}<sup>*</sup></CLabel>
                            <Field name="crew_no">
                                {({
                                      field,
                                      meta: {touched, error}
                                  }) =>
                                    <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error} />
                                }
                            </Field>
                            <FieldErrorMessage name="crew_no" />
                        </CFormGroup>
                    
                        <CFormGroup className="d-flex flex-row">
                            <Field type="checkbox" name="is_active">
                                {({field, field: { value }}) =>
                                        <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                    variant="opposite"
                                                    {...field} value={value === 0 ? true : false}/>
                                }
                            </Field>
                            <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("AreaFormModal:active")}</CLabel>
                        </CFormGroup>
                    
                        <CFormGroup>
                            <CLabel htmlFor="description" className="ml-2 user-select-none">{t("AreaFormModal:description")}</CLabel>
                            <Field name="description">
                                {({
                                      field
                                  }) => 
                                    <CTextarea {...field} />
                                }
                            </Field>
                        </CFormGroup>
                    
                    {area.id > 0 && <CommonRecordData item={area} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    </CForm>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

AreaFormModal.propTypes = {
    area: PropTypes.shape({
        is_active: PropTypes.number,
        name: PropTypes.string,
        code_aq: PropTypes.string,
        crew_no: PropTypes.string,
        description: PropTypes.string
    }),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

AreaFormModal.defaultProps = {
    loading: false,
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default AreaFormModal;