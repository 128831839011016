import { Planning } from "../components";
import {PlanningProvider} from "../components/Planning/PlanningContextProvider";

export const PlanningContainer = () => {
    
    return (
        <PlanningProvider>
            <Planning />
        </PlanningProvider>
    );
};

export default PlanningContainer;