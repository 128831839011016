import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CSwitch,
    CTextarea,
} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRuler } from "@fortawesome/pro-light-svg-icons";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import CommonRecordData from "../CommonRecordData/CommonRecordData";

export const UnitOfMeasurementFormModal = ({unit, onSaveClick, onCancelClick, show, loading, onClose }) => {
    
    const {t} = useTranslation(["UnitOfMeasurementFormModal", "Buttons", "Validation"]);
    
    const validationSchema = useMemo( () => Yup.object({
        unit: Yup.string().required(t("Validation:fieldRequired")),
    }));
    
    return (
        <Formik initialValues={unit}
                validateOnChange
                validateOnMount={parseInt(unit.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg">
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faRuler} className="mr-2"/> {t("UnitOfMeasurementFormModal:unitEdit")}</h2></CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                <CModalBody className="p-4">
                    
                        <CFormGroup>
                            <CLabel htmlFor="unit">{t("UnitOfMeasurementFormModal:unit")}<sup>*</sup></CLabel>
                            <Field name="unit">
                                {({
                                      field, // { name, value, onChange, onBlur },
                                      meta: {touched, error}
                                  }) =>
                                    <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                                }
                            </Field>
                            <FieldErrorMessage name="unit" />
                        </CFormGroup>
                    
                        <CFormGroup className="d-flex flex-row">
                            <Field type="checkbox" name="is_active">
                                {({field, field: { value }}) =>
                                        <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                                    variant="opposite"
                                                    {...field} value={value === 0 ? true : false}/>
                                }
                            </Field>
                            <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("UnitOfMeasurementFormModal:active")}</CLabel>
                        </CFormGroup>
                    
                        <CFormGroup>
                            <CLabel htmlFor="description" className="ml-2 user-select-none">{t("UnitOfMeasurementFormModal:description")}</CLabel>
                            <Field name="description">
                                {({
                                      field
                                  }) => 
                                    <CTextarea {...field} />
                                }
                            </Field>
                        </CFormGroup>

                    {unit.id > 0 && <CommonRecordData item={unit} />}
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    </CForm>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

UnitOfMeasurementFormModal.propTypes = {
    unit: PropTypes.shape({
        id: PropTypes.number,
        is_active: PropTypes.number,
        unit: PropTypes.string,
        description: PropTypes.string
    }),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

UnitOfMeasurementFormModal.defaultProps = {
    loading: false,
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default UnitOfMeasurementFormModal;