import {CFormGroup,CInputGroup, CInputGroupPrepend, CInputGroupText, CInput, CPopover} from "@coreui/react";
import classNames from "classnames";
import {usePlanningContext} from "./PlanningContextProvider";
import {useTranslation} from "react-i18next";

export const FilterRccp = ({className, style}) => {
    
    const { filterRccp, setFilterRccp } = usePlanningContext(); 
    const {t} = useTranslation(["Filters"]);
    
    const handleChange = (e) => {
        const value = e.target.value;
        setFilterRccp(value.replace(/[^\d,]/,"").trim().replace(/^,/,"").replace(/,{2,}/,","));
    }
    
    return (
        <CFormGroup inline={true} className={classNames("mb-0", className)} style={style}>
            <CInputGroup className="flex-nowrap">
                <CPopover content={t("numbersAndComma")} tigger="hover">
                    <CInputGroupPrepend className="cursor-default">
                        <CInputGroupText>RCCP</CInputGroupText>                     
                    </CInputGroupPrepend>
                </CPopover>
                <CInput value={filterRccp} onInput={handleChange}/>
            </CInputGroup>
        </CFormGroup>
    );
};

FilterRccp.propTypes = {};

FilterRccp.defaultProps = {};

export default FilterRccp;