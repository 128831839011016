import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router"
import { createRootReducer } from "./redux/reducers";
import { configureStore } from '@reduxjs/toolkit'
import { authMiddleware } from "./redux/middleware/AuthMiddleware";

export const history = createBrowserHistory();

export const store = configureStore({
    reducer: createRootReducer(history),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)).concat(authMiddleware()),
    preloadedState: {},
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [] });




