import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import useBOM from "../hooks/useBOM";
import noop from "../utils/noop";
import {BOMFormModal} from "../components";
import useCreateBOM from "../hooks/useCreateBOM";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import useUnitsOfMeasurement from "../hooks/useUnitsOfMeasurement";

const model = {
    id: 0,
    is_active: 1,
    parent_item_number: "",
    parent_item_number_2nd: "",
    item_number: "",
    item_number_2nd: "",
    item_number_3rd: "",
    description: "",
    quantity: 0,
    step: 0,
    link_image: ""
};

export const BOMFormContainer = ({onCancelClick, onSave, recordId, onGetError}) => {

    const [units, setUnits] = useState([]);
    const {t} = useTranslation("BOMFormContainer");
    const { toastError, toastSuccess } = useToaster();

    const { isLoading: unitsLoading, data: unitsData, error: unitsError } = useUnitsOfMeasurement([]);

    useEffect(() => {
        if ( unitsData ) {
            setUnits([{id: 0, name: t("select")}].concat(unitsData));
        }
    }, [unitsData]);
    
    const { isLoading, isFetching, error, data } =  useBOM({
        recordId,
        placeholder: model});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getBOMDataError"));
        }
    }, [error] );
    
    const { mutate: createArea, isLoading: isSavingArea } = useCreateBOM({
        onError: err => toastError(t("saveBOMError")),
        onSuccess: () => {
            toastSuccess(t("saveBOMSuccess"));
            onSave();
        }
    });
    
    const handleSaveClick = (values, actions) => {
        const data = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( data.id === 0 ) {
            delete data.id;
        }
        createArea(data);
    };
    
    return (
        <>
            <BOMFormModal show={true}
               loading={isLoading || isFetching || isSavingArea}
               record={data ?? model}
               unitsOfMeasurement={unitsData}
               onClose={onCancelClick}
               onSaveClick={handleSaveClick} onCancelClick={onCancelClick} />
       </>
    );
};

BOMFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

BOMFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default BOMFormContainer;