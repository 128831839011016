import {usePlanningContext} from "./PlanningContextProvider";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import {woCellRenderer} from "./WOCell";
import {orderCellRenderer} from "./OrderCell";
import {internalStatusCellRenderer} from "./InternalStatusCell";
import {notesCellRenderer} from "./NotesCell";

const defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
};

export const WorkOrderPlanned = () => {

    const {t} = useTranslation(["ProductionOrderList", "Common"]);
    const {productionOrdersData,onWoPlannedGridReady, getRowStyle} = usePlanningContext();

    const getRowColors = useMemo( () => getRowStyle("jwo_priority"),
        [getRowStyle]
    );
    
    return (
        <div className="position-relative h-50 pt-1">
            <div className="ag-theme-balham" style={{height: "100%"}}>
                <AgGridReact
                    modules={AllCommunityModules}
                    rowData={productionOrdersData}
                    defaultColDef={defaultColDef}
                    rowSelection="multiple"
                    onGridReady={onWoPlannedGridReady}
                    getRowStyle={getRowColors}
                    rowMultiSelectWithClick
                >
                    <AgGridColumn field="id" headerName={t("Common:id")} />
                    <AgGridColumn field="notes" headerName={t("ProductionOrderList:notes")} 
                                  cellRendererFramework={notesCellRenderer}
                                  filter={false}
                                  width={80}
                                  minWidth={70}
                                  
                    />
                    <AgGridColumn field="internal_status" headerName={t("ProductionOrderList:internalStatus")} cellRendererFramework={internalStatusCellRenderer} />
                    <AgGridColumn field="jwo_wo_number" headerName={t("ProductionOrderList:woNumber")} cellRendererFramework={woCellRenderer}/>
                    <AgGridColumn field="jwo_requested_date" headerName={t("ProductionOrderList:requestedDate")} />
                    <AgGridColumn field="p_item_number" headerName={t("ProductionOrderList:product")} />
                    <AgGridColumn field="jwo_qty_ordered" headerName={t("ProductionOrderList:quantity")} />
                    <AgGridColumn field="jwo_priority" headerName={t("ProductionOrderList:priority")} />
                    <AgGridColumn field="wo_order" headerName={t("ProductionOrderList:order")} 
                                  cellRendererFramework={orderCellRenderer} />
                    <AgGridColumn field="pg_name" headerName={t("ProductionOrderList:productGroup")} />
                    <AgGridColumn field="jwo_rccp" headerName={t("ProductionOrderList:rccp")} />
                    <AgGridColumn field="jwo_wo_status" headerName={t("ProductionOrderList:status")} />
                    <AgGridColumn field="add_date" headerName={t("ProductionOrderList:timeEntry")} />
                    <AgGridColumn field="add_user_name" headerName={t("ProductionOrderList:userName")} />
                </AgGridReact>
            </div>
        </div>
    );
};

export default WorkOrderPlanned;