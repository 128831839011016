import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useCreateProductionOrders = ({ onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    const queryClient = useQueryClient();

    return useMutation(values => {         
        return axios.post(`${apiUrl}production-orders`, values).then(res => res.data)         
    },
        {
            onSuccess: () => {
                onSuccess();
                queryClient.invalidateQueries(["production-orders", apiUrl]);
            }, onError: onError
        }
    );
};

export default useCreateProductionOrders;