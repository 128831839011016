import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useStopGroups = () => {
    const apiUrl = useSelector(apiUrlSelector);
    return useQuery(["stop-groups", apiUrl], () =>
        axios.get(`${apiUrl}stop-groups`).then(res => res.data.data),
        {
            refetchOnWindowFocus: false,
            placeholderData: []
        }
    );
};

export default useStopGroups;