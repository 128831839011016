import {useMemo} from "react";
import PropTypes from 'prop-types';
import {CDataTable, CCard, CCardBody, CCardHeader} from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import noop from "../../utils/noop";
import {ActionToolbar } from "../Toolbars/ActionToolbar";
import StatusCell from "../StatusCell";
import ActionsCell from "../ActionsCell";
import {tableFilterObject} from "../../common/tableFilterObject";
import {useColumnFilter} from "../../hooks/useColumnFilter";

export const UserList = ({users, onAddClick, onEditClick, onDeleteClick, onHistoryClick, onImportClick, onExportClick, loading, exportHref }) => {
    
    const {t} = useTranslation(["UserList", "Common"]);
    
    const columnFilter = useColumnFilter();
    
    const fields = useMemo(() => ([
            { key: "id", label:t("Common:id")},
            { key: "is_active", label: t("Common:activeColName")},
            { key: "last_name", label:t("UserList:lastName"), _style: { width: "40%"} },
            { key: "first_name", label:t("UserList:firstName"), _style: { width: "40%"} },
            { key: "username", label: t("UserList:login"), _style: { width: "20%"} },         
            { key: "_actions", label: t("Common:actions"), sorter: false, filter: false },         
        ]),[t]);
    
    return (         
        <CCard>
            <CCardHeader>
                <div className="d-flex flex-row">
                    <h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faUser} className="mr-2"/>{t("UserList:users")}</h2>
                    
                    <div className="ml-auto d-flex">
                        <ActionToolbar className="ml-auto" onAddClick={onAddClick} onImportClick={onImportClick} onExportClick={onExportClick} exportHref={exportHref}/>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    size="md"
                    tableFilter={tableFilterObject(t)}
                    cleaner
                    itemsPerPageSelect
                    itemsPerPage={10}
                    columnFilter={columnFilter}
                    footer
                    sorter
                    striped
                    hover
                    responsive
                    pagination
                    noItemsViewSlot={<div>{t("Common:noRecordsFound")}</div>}
                    items={users} fields={fields}
                    loading={loading}
                    scopedSlots={{
                        is_active: item => <StatusCell active={item.is_active} />,
                        _actions: item => <ActionsCell onEditClick={() => onEditClick(item)} onDeleteClick={() => onDeleteClick(item)} onHistoryClick={() => onHistoryClick(item)}/>
                    }}
                />
            </CCardBody>
        </CCard>
        
    );
};

UserList.propTypes = {
    users: PropTypes.array,
    loading: PropTypes.bool,
    exportHref: PropTypes.string,
    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onImportClick: PropTypes.func,
    onExportClick: PropTypes.func
};

UserList.defaultProps = {
    users: [],
    loading: false,
    exportHref: "",
    onAddClick: noop,
    onEditClick: noop,
    onDeleteClick: noop,
    onHistoryClick: noop,
    onImportClick: noop,
    onExportClick: noop,
};

export default UserList;