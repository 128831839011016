import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import noop from "../utils/noop";
import useProductionOrder from "../hooks/useProductionOrder";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import ProductionOrderFormModal from "../components/ProductionOrderFormModal";
import { format } from "date-fns";
import {DATE_FORMAT} from "../common/formats";
import useCreateProductionOrder from "../hooks/useCreateProductionOrder";

const model = {
    id: 0,
    is_active: 1,
    jde_work_orders_id: 0,
    start_date: format(new Date, DATE_FORMAT),
    products_id: 0,
    quantity: 0,
    load_level: "",
    wo_order: 0,
    product_groups_id: 0,
    wo_status: "",
    time_entry: ""
};

export const ProductionOrderFormContainer = ({onCancelClick, onSave, recordId, onGetError }) => {
    
    const {t} = useTranslation("ProductionOrderFormContainer");
    const { toastError, toastSuccess } = useToaster();
    
    const { isLoading, isFetching, error, data } =  useProductionOrder({
        recordId,
        placeholder: model});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getOrderDataError"));
        }
    }, [error] );
    
    const { mutate: createRecord, isLoading: isSavingArea } = useCreateProductionOrder({
        onError: err => toastError(t("saveOrderError")),
        onSuccess: () => {
            toastSuccess(t("saveOrderSuccess"));
            onSave();
        }
    });
    
    const handleSaveClick = (values, actions) => {
        const data = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( data.id === 0 ) {
            delete data.id;
        }

        const dataToSend = {
            id: data.id,
            is_active: data.is_active,
            status: data.status,
            jde_work_orders_id: data.jde_work_orders_id,
            start_date: data.start_date,
            wo_order: data.wo_order,
            wo_status: data.wo_status,
            time_entry: data.time_entry
        };
        
        createRecord(dataToSend);
    };
    
    return (
            <ProductionOrderFormModal show={true}
               loading={isLoading || isFetching || isSavingArea}
               record={data ?? model}
               onClose={onCancelClick}
               onSaveClick={handleSaveClick} onCancelClick={onCancelClick} />
    );
};

ProductionOrderFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

ProductionOrderFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default ProductionOrderFormContainer;