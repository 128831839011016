import { LoginRoute } from "../routes";
import { LoadingFallback } from "../components";
import { LayoutContainer } from "./LayoutContainer";
import {useAuthProvider} from "../auth/AuthProvider";

const RoutesContainer = () => {
  
  const { authState: { loggedIn } } = useAuthProvider();
  
  return (<LoadingFallback>
        {loggedIn === false && <LoginRoute exact path="/login" />}
        {loggedIn === true && <LayoutContainer />}
  </LoadingFallback>);
    
};

export default RoutesContainer;