import { createReducer } from "@reduxjs/toolkit";
import {SetUserData} from "../actions/user.actions";

const initialState = {
    id: 0,
    email: "",
    first_name: "",
    last_name: "",
    username: "",
    user_roles_id: ""
};

export const userReducer = createReducer(initialState, (builder) => {
    builder.addCase(SetUserData, (state, action)=> {
        
        return {...action.payload};

    }).addDefaultCase((state, action) => state)
});
