import React from "react";
import CIcon from "@coreui/icons-react";
import {cifPl, cifGb} from "@coreui/icons";


/**
 * Languages available in app
 */

const languages = [
  { key: 'pl', value: 'pl', text: <span><CIcon name="cif-pl" content={cifPl} className="mr-2"/>Polski</span> },
  { key: 'en', value: 'en', text: <span><CIcon name="cif-gb" content={cifGb} className="mr-2"/> English</span> }
];


export default languages;
