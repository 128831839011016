import { useQuery } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useProductGroup = ({recordId, placeholder, filter}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    
    return useQuery(["product-group", apiUrl, recordId], () => {
        
            if ( recordId > 0 ) {
                return axios.get(`${apiUrl}product-groups/${recordId}`).then(res => {
                        if ( typeof filter === "function" ) {
                            return filter(res.data.data);
                        } else {
                            return res.data.data;
                        }
                    }
                );
            } else {
                return Promise.resolve(placeholder);
            }
        },
        {
            placeholderData: placeholder,
            refetchOnWindowFocus: false
        }
    );
};

export default useProductGroup;