import {CButton} from "@coreui/react";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

export const SaveButton = ({className, ...rest}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton color="primary" className={classNames("px-4", className)} {...rest}><FontAwesomeIcon icon={faSave} className="mr-2" />{t("save")}</CButton>
    );
};

export default SaveButton;