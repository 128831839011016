import {useState, useRef, useEffect} from "react";
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CButton,
    CElementCover,
    CRow, CCol
} from "@coreui/react";
import { noop } from "../../utils/noop";
import { CancelButton, UploadButton, CloseButton } from "../Buttons";
import CIcon from "@coreui/icons-react";
import {cilFile, cilDataTransferUp} from "@coreui/icons";

const FileItem = ({file}) => {
    
    const {t} = useTranslation("ImportModal");
    
    return (<div className="mb-3 imi-file-item">
        {!file && <span>{t("fileNotSelected")}</span>}
        {file && <span><CIcon size="lg" name="cil-file" content={cilFile} className="mr-2" />{file.name}</span>}
    </div>);
};

export const ImportModal = ({onUploadClick, onCancelClick, show, loading, ready, importedModel}) => {

    const [file,setFile] = useState();
    const fileInputRef = useRef();
    const {t} = useTranslation("ImportModal");
    
    const handleFileChange = evt => {
        setFile(evt.target.files[0]);
    };
    
    const handleUploadClick = () => {
        onUploadClick(file);
    };
    
    useEffect(() => {
        if (ready === false) {
            setFile();
        }
    },[ready]);
    
    useEffect(() => {
        if ( typeof file === "undefined" ) {
            fileInputRef.current.value = "";
        }
    },[file]);
    
    const handleLabelClick = () => fileInputRef.current.click();
    
    const handleCloseClick = () => {
        setFile();
        onCancelClick();
    };
    
    return (
        <CModal show={show} onClose={handleCloseClick} closeOnBackdrop={false} className="imi-import-modal">
            <CModalHeader closeButton><h2>{t("importFromFile")}</h2></CModalHeader>
            <CModalBody className="text-center imi-import-modal__body">
                {ready === false && <>
                    <FileItem file={file}/>
                    <CButton color="info" onClick={handleLabelClick}><CIcon name="cil-data-transfer-up" content={cilDataTransferUp} className="mr-2"/>{t("selectFile")}</CButton>
                    <input ref={fileInputRef} type="file" id="importFile" hidden onChange={handleFileChange} />
                </>}
                
                {ready === true && <div className="d-flex flex-column justify-content-center h-100 px-4">
                    <CRow className="text-left">
                        <CCol>{t('totalRecords')}:</CCol>
                        <CCol className="font-weight-bold">{importedModel?.totalRecords}</CCol>
                    </CRow>
                    <CRow className="text-left">
                        <CCol>{t('importedRecords')}:</CCol>
                        <CCol className="font-weight-bold">{importedModel?.importedRecords}</CCol>
                    </CRow>
                </div>}
                
            </CModalBody>
            <CModalFooter>
                {ready === false && <>
                    <CancelButton onClick={handleCloseClick}/>
                    <UploadButton onClick={handleUploadClick} disabled={!file || loading}/>
                </>}
                {ready === true && <CloseButton onClick={onCancelClick} />}
            </CModalFooter>
            {loading && <CElementCover/>}
        </CModal>
    );
};

ImportModal.propTypes = {
    show: PropTypes.bool,
    loading: PropTypes.bool,
    ready: PropTypes.bool,
    importedModel: PropTypes.shape({
        importedRecords: PropTypes.number,
        totalRecords: PropTypes.number
    }),
    onUploadClick: PropTypes.func,
    onCancelClick: PropTypes.func
};

ImportModal.defaultProps = {
    show: false,
    loading: false,
    ready: false,
    onUploadClick: noop,
    onCancelClick: noop
};

export default ImportModal;