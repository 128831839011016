import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { authReducer } from "./auth.reducer";
import { appReducer } from "./app.reducer";
import { configReducer } from "./config.reducer";
import { userReducer } from "./user.reducer";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    app: appReducer,
    config: configReducer, 
    user: userReducer
});

export default createRootReducer;