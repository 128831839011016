import {useState, useMemo } from "react";
import PropTypes from 'prop-types';
import {usePlanningContext} from "./PlanningContextProvider";
import {CElementCover} from "@coreui/react";
import {useTranslation} from "react-i18next";

const USER = 2;
const ADMIN = 4;
const OPERATOR = 8;
const ALL = USER | ADMIN | OPERATOR;

// Obiekt definiuje z jakiego statusu (klucz) można przejść na jaki status i kto może na niego wejść
const allowedStatuses = {
    "10": [
        { status: "10", flag: ALL }, // Na samego siebie można wejść zawsze
        { status: "20", flag: OPERATOR },
        { status: "30", flag: USER | ADMIN },
        { status: "40", flag: USER | ADMIN}
    ],
    "20": [
        { status: "20", flag: ALL }, // Na samego siebie można wejść zawsze
        { status: "40", flag: OPERATOR }
    ],
    "30": [
        { status: "30", flag: ALL }, // Na samego siebie można wejść zawsze
        { status: "20", flag: OPERATOR },
        { status: "40", flag: USER | ADMIN }
    ],
    "40": [
        { status: "40", flag: ALL }, // Na samego siebie można wejść zawsze
        { status: "30", flag: ADMIN }
    ]
};

const isStatusDisabled = ( { id, status, currentState, flag } ) => {
    return !(typeof allowedStatuses[currentState].find( s => (s.status === status) && ((s.flag & flag) > 0)) !== "undefined");
};

export const InternalStatusCell = ({id, value, params}) => {

    const {t} = useTranslation("ProductionOrderList");
    const [currentValue, setCurrentValue] = useState(value);
    const statuses = useMemo(() => (
        [
            { label: `10 ${t('statuses.10')}`, value: "10" },
            { label: `20 ${t('statuses.20')}`, value: "20" },
            { label: `30 ${t('statuses.30')}`, value: "30" },
            { label: `40 ${t('statuses.40')}`, value: "40" }
        ] 
    ),[t]); 
    
    const [isUpdating, setIsUpdating] = useState(false);
    
    const {onInternalStatusChange} = usePlanningContext();
    
    const handleChange = (e) => {
        setIsUpdating(true);
        
        const val = e.target.value;
        const lastValue = currentValue;
        setCurrentValue(val);
        onInternalStatusChange(id, parseInt(val), () => { // onSuccess
           setIsUpdating(false);
        },
        () => { // onError
            setCurrentValue(lastValue);
            setIsUpdating(false);
        });
    }
    
    // TODO: Uwzględnić uprawnienia jak będą
    const flag = USER;
    
    return (
        <div className="text-center imi-internal-status-cell">
            <select value={currentValue} className="w-100" onChange={handleChange}>
                {statuses.map( s => <option key={s.value} value={s.value}
                                            disabled={isStatusDisabled({
                                                id,
                                                status: s.value,
                                                currentState: value.toString(),
                                                flag,
                                            })}>{s.label}</option>)}
            </select>
            {isUpdating && <CElementCover color="primary" opacity={0} className="imi-internal-status-cell__cover" />}
        </div>
    );
};

export const internalStatusCellRenderer = (params) => <InternalStatusCell id={params.data.id} value={params.value.toString()} params={params}/>;

InternalStatusCell.propTypes = {
    value: PropTypes.string
};

export default InternalStatusCell;