import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import { CCol, CRow} from "@coreui/react";

export const CommonRecordData = ({item}) => {
    
    const {t} = useTranslation("CommonRecordData");
    
    return (
        <div>
            <CRow>
                <CCol sm="3" lg="2">
                    <strong className="text-black-50">{t("added")}</strong>: 
                </CCol>
                <CCol>
                    <span className="text-black-50">{item.add_date}, {item.add_user_name}</span>
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="3" lg="2">
                    <strong className="text-black-50">{t("modified")}</strong>: 
                </CCol>
                <CCol>
                    <span className="text-black-50">{item.last_modification_date}, {item.last_modification_user_name}</span>
                </CCol>
            </CRow>
        </div>
    );
};

CommonRecordData.propTypes = {
    item: PropTypes.object
};

CommonRecordData.defaultProps = {};

export default CommonRecordData;