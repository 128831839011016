import PropTypes from 'prop-types';
import {ErrorMessage} from "formik";
import CIcon from "@coreui/icons-react";
import {cilWarning} from "@coreui/icons";
import classNames from "classnames";
export const FieldErrorMessage = ({name, className}) => {
    return (
        <ErrorMessage name={name}>
            {msg => <span className={classNames("text-danger d-flex flex-row align-items-center mt-1", className)}><CIcon name="cil-warning" content={cilWarning} className="mr-2" />{msg}</span>}
        </ErrorMessage>
    );
};

FieldErrorMessage.propTypes = {
    name: PropTypes.string    
};


export default FieldErrorMessage;