import React from 'react'
import { CFooter } from '@coreui/react'

const Footer = () => {
    return (
        <CFooter fixed={false}>
            <div className="text-center w-100">
                <span className="ml-1">&copy; 2020</span>
            </div>
        </CFooter>
    )
};

export default React.memo(Footer)