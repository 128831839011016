import { useMemo } from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import {useTranslation} from "react-i18next";
import {useField} from "formik";
import {DATE_FORMAT, DATETIME_DISPLAY_FORMAT, DATETIME_FORMAT} from "../../common/formats";


export const DateTimePickerField = ({fieldName, showTimeSelect}) => {

    const {i18n: { language } } = useTranslation();
    
    const [{value}, { setValue}] = useField(fieldName);
    
    const selected = useMemo(() => {
        if ( value.length > 0 ) {
            return parse(value, showTimeSelect ? DATETIME_FORMAT : DATE_FORMAT, new Date());
        } else {
            return new Date();
        }
    },[value, showTimeSelect]);
    
    return (<DatePicker
        className={showTimeSelect === false ? "date-only" : ""}
        selected={selected}
        onChange={(date) => {
            setValue(format(date, showTimeSelect ? DATETIME_FORMAT : DATE_FORMAT));
        }}
        showYearDropdown={true}
        timeIntervals={15}
        showTimeSelect={showTimeSelect}
        dateFormat={showTimeSelect ? DATETIME_DISPLAY_FORMAT : DATE_FORMAT}
        locale={language}
    />);
};

DateTimePickerField.propTypes = {
    fieldName: PropTypes.string,
    showTimeSelect: PropTypes.bool
};

DateTimePickerField.defaultProps = {
    showTimeSelect: true
};

export default DateTimePickerField;