import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import ImportModal from "../components/ImportModal";
import {useImportModal} from "../hooks/useImportModal";
import {useToaster} from "../context-providers/ToasterProvider";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import UnitOfMeasurementFormContainer from "./UnitOfMeasurementFormContainer";
import UnitsOfMeasurementList from "../components/UnitsOfMeasurementList";
import useUnitsOfMeasurement from "../hooks/useUnitsOfMeasurement";
import {useDeleteModal} from "../hooks/useDeleteModal";
import {apiUrls} from "../common/apiUrls";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {EmployeeList} from "../components";

export const UnitsOfMeasurementContainer = () => {

    const {t} = useTranslation(["UnitsOfMeasurementContainer", "Buttons"]);

    const apiUrl = useSelector(apiUrlSelector);

    const { toastError } = useToaster();

    const [unitId, setUnitId] = useState(0);

    const [showUnitModal, setShowUnitModal] = useState(false);

    const { isLoading, error, data } =  useUnitsOfMeasurement();

    useEffect( ( ) => {
        if ( error ) {
            toastError(t("errorFetchingUnits"));
        }
    }, [error] );

    const { modal: importModal, onImportClick } = useImportModal("url fo importu");

    const { confirmDelete, ...deleteModalProps } = useDeleteModal(apiUrls.unitsOfMeasurement);
    
    const handleCloseUnitModal = () => setShowUnitModal(false);

    const handleAddUnitClick = () => {
        setUnitId(0);
        setShowUnitModal(true);
    };

    const handleEditClick = row => {
        setUnitId(row.id);
        setShowUnitModal(true);
    };

    const handleHistoryClick = (item) => {
        console.log("Pokazuję historię", item);
    };
    
    return (
        <>
            {showUnitModal && <UnitOfMeasurementFormContainer unitId={unitId}
                                                 onSave={handleCloseUnitModal}
                                                 onCancelClick={handleCloseUnitModal}
                                                 onGetError={handleCloseUnitModal} />}

            <ImportModal {...importModal} />

            <ConfirmDeleteModal {...deleteModalProps} />
            
            <UnitsOfMeasurementList units={data} loading={isLoading} onAddClick={handleAddUnitClick}
                      onEditClick={handleEditClick} onDeleteClick={confirmDelete} onHistoryClick={handleHistoryClick}
                      onImportClick={onImportClick} exportHref={`${apiUrl}/units-of-measurement/export"`} />
        </>
    );
};

export default UnitsOfMeasurementContainer;