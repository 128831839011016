import PropTypes from "prop-types";
import { Suspense } from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => <div className="w-100 h-100 d-flex align-items-center justify-content-center">
    <Spinner animation="grow" variant="info" />
</div>;
    
export const LoadingFallback = ({children}) => {
    
    return <Suspense fallback={<Loading/>}>{children}</Suspense>
    
};

LoadingFallback.propTypes = {
  children: PropTypes.node
};


export default LoadingFallback;
