import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector, useStore } from 'react-redux'
import { RoutesContainer } from "./containers";
import './i18n';
import './themes/default/main.scss';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useServices } from "./context-providers/ServicesProvider";
import {SetConfig} from "./redux/actions/config.actions";
import { Toaster } from "./components/Toasters";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import en from "date-fns/locale/en-US";
import pl from "date-fns/locale/pl";
import {useTranslation} from "react-i18next";
import {useAxiosAuth} from "./auth/useAxiosAuth";
import {useToaster} from "./context-providers/ToasterProvider";
import {useAuthProvider} from "./auth/AuthProvider";
registerLocale("pl", pl);
registerLocale("en", en);

const queryClient = new QueryClient();

function App() {
    
  const { i18n: {language}, t } = useTranslation("Common");
  const { toastInfo } = useToaster();
  const { error } = useAuthProvider();
    
  useEffect(() => {
      setDefaultLocale(language);
  },[language]);

    useEffect(() => {
        if ( error.length > 0 ) {
            toastInfo(t("sessionExpired"));
        }
    }, [error]);
    
    
    useAxiosAuth();
    
  return (
      <>
          <QueryClientProvider client={queryClient}>
              <RoutesContainer/>}
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        <Toaster />
      </>
  );
}

export default App;
