import {useEffect, useState} from "react";
import {AreaList} from "../components";
import { useTranslation } from "react-i18next";
import useAreas from "../hooks/useAreas";
import ImportModal from "../components/ImportModal";
import AreaFormContainer from "./AreaFormContainer";
import {useImportModal} from "../hooks/useImportModal";
import {useToaster} from "../context-providers/ToasterProvider";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import {useDeleteModal} from "../hooks/useDeleteModal";
import {apiUrls} from "../common/apiUrls";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";

export const AreasContainer = () => {

    const {t} = useTranslation(["AreasContainer", "Buttons"]);

    const apiUrl = useSelector(apiUrlSelector);

    const { toastError } = useToaster();

    const [userId, setUserId] = useState(0);

    const [showAreaModal, setShowAreaModal] = useState(false);

    const { isLoading, error, data } =  useAreas();

    useEffect( ( ) => {
        if ( error ) {
            toastError(t("errorFetchingAreas"));
        }
    }, [error] );

    const { modal: importModal, onImportClick } = useImportModal("url fo importu");

    const { confirmDelete, ...deleteModalProps } = useDeleteModal(apiUrls.areas);
    
    const handleCloseAreaModal = () => setShowAreaModal(false);

    const handleAddAreaClick = () => {

        // Pokaż modala z formularzem usera
        setUserId(0);
        setShowAreaModal(true);

    };

    const handleEditClick = row => {

        setUserId(row.id);
        setShowAreaModal(true);

    };

    const handleHistoryClick = (item) => {
        console.log("Pokazuję historię", item);
    };

    return (
        <>
            {showAreaModal && <AreaFormContainer areaId={userId}
                                                 onSave={handleCloseAreaModal}
                                                 onCancelClick={handleCloseAreaModal}
                                                 onGetError={handleCloseAreaModal} />}

            <ImportModal {...importModal} />

            <ConfirmDeleteModal {...deleteModalProps} />
            
            <AreaList areas={data} loading={isLoading} onAddClick={handleAddAreaClick}
                      onEditClick={handleEditClick} onDeleteClick={confirmDelete} onHistoryClick={handleHistoryClick}
                      onImportClick={onImportClick} exportHref={`${apiUrl}/areas/export"`} />
        </>
    );
};

export default AreasContainer;