import React, {useMemo} from "react";
import { useSelector, useDispatch } from 'react-redux'
import {
    CHeader,
    CToggler,
//    CHeaderBrand,
    CHeaderNav,
//    CHeaderNavItem,
//    CHeaderNavLink,
//    CSubheader,
    CBreadcrumbRouter,
//    CDropdownItem, CDropdown, CDropdownToggle, CDropdownMenu, CBadge
} from '@coreui/react'
import {useTranslation} from "react-i18next";
import { routes } from "../../routes";
import {SetSidebarState} from "../../redux/actions/app.actions";
import UserMenu from "../UserMenu";

const Header = () => {

    const { t } = useTranslation(["Sidebar", "UserMenu", "Common"]);
    
    // Translate route names for CBreadcrumbRouter
    const translatedRoutes = useMemo(()=> 
        routes.map( r => (
            { ...r, name: t(r.name)} 
        ))
    ,[t]);
    
    const dispatch = useDispatch();
    
    const [sidebarShow] = useSelector(state => [state.app.sidebarShow]);

    const toggleSidebar = () => {
        
        const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive';
        dispatch(SetSidebarState(val));
    };

    const toggleSidebarMobile = () => {
        const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive';
        dispatch(SetSidebarState(val));
    };
    
    return (
        <CHeader>
            <CToggler
                inHeader
                className="ml-md-3 d-lg-none"
                onClick={toggleSidebarMobile}
            />
            <CToggler
                inHeader
                className="ml-3 d-md-down-none"
                onClick={toggleSidebar}
            />
            
            <CHeaderNav className="d-md-down-none mr-auto">
                <CBreadcrumbRouter
                    className="border-0 c-subheader-nav m-0 px-0 px-md-3"
                    routes={translatedRoutes}
                />
                {/*
                <CHeaderNavItem className="px-3" >
                    <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
                </CHeaderNavItem>
                <CHeaderNavItem  className="px-3">
                    <CHeaderNavLink to="/users">Users</CHeaderNavLink>
                </CHeaderNavItem>
                <CHeaderNavItem className="px-3">
                    <CHeaderNavLink>Settings</CHeaderNavLink>
                </CHeaderNavItem>
                */}
            </CHeaderNav>
            

            <CHeaderNav className="px-3">
                {/* {t("Common:area")}: <AreaPicker area={area} className="ml-2" style={{width:"200px"}} onChange={handleAreaChange} /> */}
                <UserMenu />
                
                
                {/*
                <TheHeaderDropdownNotif/>
                <TheHeaderDropdownTasks/>
                <TheHeaderDropdownMssg/>
                <TheHeaderDropdown/>
                */}
            </CHeaderNav>

            {/*
            <CSubheader className="px-3 justify-content-between">
                <CBreadcrumbRouter
                    className="border-0 c-subheader-nav m-0 px-0 px-md-3"
                    routes={translatedRoutes}
                />
               */}
                {/*
                <div className="d-md-down-none mfe-2 c-subheader-nav">
                    <CLink className="c-subheader-nav-link"href="#">
                        <CIcon name="cil-speech" alt="Settings" />
                    </CLink>
                    <CLink
                        className="c-subheader-nav-link"
                        aria-current="page"
                        to="/dashboard"
                    >
                        <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
                    </CLink>
                    <CLink className="c-subheader-nav-link" href="#">
                        <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
                    </CLink>
                </div>
                */}
            {/*
            </CSubheader>
            */}
        </CHeader>
    )
}

export default Header;