import {createContext, useContext, useEffect, useState, useCallback} from "react";

const ConfigClientContext = createContext({});

ConfigClientContext.displayName = "ConfigClientContext";

export const useConfigClient = () => {
    
    const context = useContext(ConfigClientContext);
    
    if (!context) {
        throw new Error("useConfig must be within ConfigProvider");
    }
    
    return context;
    
}

export const useConfig = (config) => {
    
    const configClient = useConfigClient();

    const enabled = config?.enabled ?? true;

    useEffect(() => {
        if ( enabled ) {
            configClient.getConfig();
        }
    }, [enabled]);
    
    return configClient;
    
}

/**
 * 
 * @param children
 * @param configPath Relative to base.href
 * @param enabled
 * @param renderChildrenWithoutConfig
 * @returns {JSX.Element}
 * @constructor
 */
export const ConfigProvider = ({children, configPath, enabled = true}) => {
    
    const [config,setConfig] = useState({});
    const [loading,setLoading] = useState(enabled);
    const [hasConfig, setHasConfig] = useState(false);

    const getConfig = useCallback( () => {

        setLoading(true);

        // TODO: Rejecxt po timaeut???
        const baseHref = document.querySelector("base")?.href;

        const head = document.head;

        const script = document.createElement("script");
        script.src = `${baseHref}${configPath}`;

        const evtName = "config.received";
        const handleConfigReceived = (evt) => {

            head.removeChild(script);

            window.removeEventListener(evtName, handleConfigReceived);
            setConfig(evt.detail);
            setHasConfig(true);
            setLoading(false);

        };

        window.addEventListener(evtName, handleConfigReceived);
        head.appendChild(script);

    },[setLoading, setConfig, setHasConfig]);

    useEffect(() => {
        if ( enabled === true ) {
            getConfig();
        }
    }, [enabled, getConfig]);
    
    return (
        <ConfigClientContext.Provider value={{
            config, getConfig, loading, hasConfig
        }}>
            {typeof children === "function" ? children({
                config, getConfig, loading, hasConfig
            }) : children}
        </ConfigClientContext.Provider>
    );
    
};
