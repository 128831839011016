import {useEffect} from "react";
import PropTypes from 'prop-types';
import useUnitOfMeasurement from "../hooks/useUnitOfMeasurement";
import noop from "../utils/noop";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import useCreateUnitOfMeasurement from "../hooks/useCreateUnitOfMeasurement";
import UnitOfMeasurementFormModal from "../components/UnitOfMeasurementFormModal";

const unitModel = {
    id: 0,
    is_active: 1,
    unit: "",
    description: "",
};

export const UnitOfMeasurementFormContainer = ({onCancelClick, onSave, unitId, onGetError}) => {
    
    const {t} = useTranslation("UnitOfMeasurementFormContainer");
    const { toastError, toastSuccess } = useToaster();
    
    const { isLoading, isFetching, error, data } =  useUnitOfMeasurement({
        unitId,
        placeholder: unitModel});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getUnitDataError"));
        }
    }, [error] );
    
    const { mutate: createUnit, isLoading: isSavingUnit } = useCreateUnitOfMeasurement({
        onError: err => toastError(t("saveUnitError")),
        onSuccess: () => {
            toastSuccess(t("saveUnitSuccess"));
            onSave();
        }
    });
    
    const handleSaveAreaClick = (values, actions) => {
        const unitData = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( unitData.id === 0 ) {
            delete unitData.id;
        }
        createUnit(unitData);
    };
    
    return (
        <>
            <UnitOfMeasurementFormModal show={true}
               loading={isLoading || isFetching || isSavingUnit}
               unit={data ?? unitModel}
               onClose={onCancelClick}
               onSaveClick={handleSaveAreaClick} onCancelClick={onCancelClick} />
       </>
    );
};

UnitOfMeasurementFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

UnitOfMeasurementFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default UnitOfMeasurementFormContainer;