import {useContext, createContext, useReducer} from "react";
import PropTypes from "prop-types";
import {nanoid} from "nanoid";

const ToasterContext = createContext();
ToasterContext.displayName = "ToasterContext";

const useToaster = () => useContext(ToasterContext);

const reducer = (state, action) => {

    const { name, payload } = action;
    if ( name === "add" ) {
        
        return state.concat(payload);
        
    } else if ( name === "remove" ) {
        return state.filter( toast => toast.key !== payload );
        
    } else {
        return [...state];
    }
    
};

const ToasterProvider = ({children}) => {
    
    const [ toasters, dispatch ] = useReducer(reducer, []);

    const dispatchToast = (type, message, options) => {
        const key = nanoid();
        dispatch({
            name: "add",
            payload: {
                key,
                message,
                type,
                show: true,
                time: options?.time ?? 3000,
                onStateChange: (val) => {
                    if ( val === false ) {
                        dispatch({
                            name: "remove",
                            payload: key
                        })
                    }
                }
            }
        });
    };
    
    const value = {
        toastError: (message, options) => dispatchToast("error", message, options),
        toastSuccess: (message, options) => dispatchToast("success", message, options),
        toastInfo: (message, options) => dispatchToast("info", message, options),
        toasters
    };
    
    return <ToasterContext.Provider value={value}>{children}</ToasterContext.Provider>;
    
};

ToasterProvider.propTypes = {
    children: PropTypes.node,
};


export { ToasterProvider, useToaster };