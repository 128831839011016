import {useEffect, useState} from "react";
import { UserList } from "../components";
import { useTranslation } from "react-i18next";
import useUsers from "../hooks/useUsers";
import ImportModal from "../components/ImportModal";
import UserFormContainer from "./UserFormContainer";
import {useImportModal} from "../hooks/useImportModal";
import {useToaster} from "../context-providers/ToasterProvider";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import { apiUrls } from "../common/apiUrls";
import ConfirmDeleteModal from "../components/ConfirmDeleteModal";
import {useDeleteModal} from "../hooks/useDeleteModal";

export const UsersContainer = () => {
    
    const {t} = useTranslation(["UsersContainer", "Buttons"]);
    
    const apiUrl = useSelector(apiUrlSelector);
    
    const { toastError, toastSuccess } = useToaster();
    
    const [userId, setUserId] = useState(0);
    
    const [showUserModal, setShowUserModal] = useState(false);
    
    const { isLoading, error, data } =  useUsers();
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("errorFetchingUsers"));
        }
    }, [error] );
    
    const { modal: importModal, onImportClick } = useImportModal("url fo importu");

    const { confirmDelete, ...deleteModalProps } = useDeleteModal(apiUrls.users);
    
    const handleCloseUserModal = () => setShowUserModal(false);
    
    const handleAddUserClick = () => {
      
        // Pokaż modala z formularzem usera
        setUserId(0);
        setShowUserModal(true);
        
    };
    
    const handleEditClick = row => {
        
        setUserId(row.id);
        setShowUserModal(true);
        
    };
    
    const handleHistoryClick = (item) => {
        console.log("Pokazuję historię", item);
    };
    
    return (         
        <> 
            {showUserModal && <UserFormContainer userId={userId}
                                                 onSave={handleCloseUserModal}
                                                 onCancelClick={handleCloseUserModal}
                                                 onGetError={handleCloseUserModal} />}
                                             
            <ImportModal {...importModal} />
            
            <ConfirmDeleteModal {...deleteModalProps} />
            
            <UserList users={data?.data} loading={isLoading} onAddClick={handleAddUserClick}
                      onEditClick={handleEditClick} onDeleteClick={confirmDelete} onHistoryClick={handleHistoryClick}
                      onImportClick={onImportClick} exportHref={`${apiUrl}/users/export"`} />
        </>
    );
};

export default UsersContainer;