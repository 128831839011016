import { useMemo } from "react";
import PropTypes from 'prop-types';
import {CDataTable, CCard, CCardBody, CCardHeader} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faObjectUngroup} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import noop from "../../utils/noop";
import {ActionToolbar } from "../Toolbars/ActionToolbar";
import {tableFilterObject} from "../../common/tableFilterObject";
import {useColumnFilter} from "../../hooks/useColumnFilter";
import ActionsCell from "../ActionsCell";
import StatusCell from "../StatusCell";

export const StopGroupList = ({records, onAddClick, onEditClick, onDeleteClick, onHistoryClick, onImportClick, loading, exportHref }) => {
    
    const {t} = useTranslation(["StopGroupList", "Common"]);

    const columnFilter = useColumnFilter();
    
    const fields = useMemo(() => ([
            { key: "id", label:t("Common:id") },
            { key: "is_active", label: t("Common:activeColName")},
            { key: "code", label:t("StopGroupList:code"),  _classes: "text-nowrap" },
            { key: "description", label: t("StopGroupList:description"), _style: { width: "100%"} },
            { key: "_actions", label: t("Common:actions"), sorter: false, filter: false }
        ]),[t]);
    
    return (         
        <CCard>
            <CCardHeader>
                <div className="d-flex flex-row">
                    <h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faObjectUngroup} className="mr-2"/>{t("StopGroupList:groups")}</h2>
                    <div className="ml-auto d-flex">
                        <ActionToolbar className="ml-auto" onAddClick={onAddClick} onImportClick={onImportClick} exportHref={exportHref}/>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    size="md"
                    tableFilter={tableFilterObject(t)}
                    cleaner
                    itemsPerPageSelect
                    itemsPerPage={10}
                    columnFilter={columnFilter}
                    footer
                    sorter
                    striped
                    hover
                    responsive
                    pagination
                    noItemsViewSlot={<div>{t("Common:noRecordsFound")}</div>}
                    items={records} fields={fields}
                    loading={loading}
                    scopedSlots={{
                        is_active: item => <StatusCell active={item.is_active} />,
                        _actions: item => <ActionsCell onEditClick={() => onEditClick(item)} onDeleteClick={() => onDeleteClick(item)} onHistoryClick={() => onHistoryClick(item)}/>
                    }}
                />
            </CCardBody>
        </CCard>
        
    );
};

StopGroupList.propTypes = {
    areas: PropTypes.array,
    loading: PropTypes.bool,
    exportHref: PropTypes.string,
    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onImportClick: PropTypes.func,
    onExportClick: PropTypes.func
};

StopGroupList.defaultProps = {
    areas: [],
    loading: false,
    exportHref: "",
    onAddClick: noop,
    onEditClick: noop,
    onDeleteClick: noop,
    onHistoryClick: noop,
    onImportClick: noop,
    onExportClick: noop,
};

export default StopGroupList;