import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import useProductGroup from "../hooks/useProductGroup";
import noop from "../utils/noop";
import {ProductGroupFormModal} from "../components";
import useCreateProductGroup from "../hooks/useCreateProductGroup";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import useAreas from "../hooks/useAreas";

const model = {
    id: 0,
    is_active: 1,
    name: "",
    areas_id: 0,
    rccp: "",
    work_center: "",
    gl_cat: ""
};

export const ProductGroupFormContainer = ({onCancelClick, onSave, recordId, onGetError}) => {

    const [areas, setAreas] = useState([]);
    const {t} = useTranslation("ProductGroupFormContainer");
    const { toastError, toastSuccess } = useToaster();

    const { isLoading: areasLoading, data: areasData, error: areasError } = useAreas([]);

    useEffect(() => {
        if ( areasData ) {
            setAreas([{id: 0, name: t("select")}].concat(areasData));
        }
    }, [areasData]);
    
    const { isLoading, isFetching, error, data } =  useProductGroup({
        recordId,
        placeholder: model});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getProductGroupDataError"));
        }
    }, [error] );
    
    const { mutate: createRecord, isLoading: isSavingRecord } = useCreateProductGroup({
        onError: err => toastError(t("saveProductGroupError")),
        onSuccess: () => {
            toastSuccess(t("saveProductGroupSuccess"));
            onSave();
        }
    });
    
    const handleSaveClick = (values, actions) => {
        const data = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( data.id === 0 ) {
            delete data.id;
        }
        createRecord(data);
    };
    
    return (
        <>
            <ProductGroupFormModal show={true}
               loading={isLoading || isFetching || isSavingRecord}
               record={data ?? model}
               areas={areasData}
               onClose={onCancelClick}
               onSaveClick={handleSaveClick} onCancelClick={onCancelClick} />
       </>
    );
};

ProductGroupFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

ProductGroupFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default ProductGroupFormContainer;