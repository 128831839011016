import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import noop from "../utils/noop";
import useCreateStop from "../hooks/useCreateStop";
import useStop from "../hooks/useStop";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import { StopFormModal as FormModal } from "../components";
import useStopGroups from "../hooks/useStopGroups";
import useMachinery from "../hooks/useMachinery";
import { range } from "lodash";

const model = {
    id: 0,
    is_active: 1,
    stop_groups_id: 0,
    machinery_id:0,
    category_level_1: "",
    category_level_2: "",
    description: "",
    p1: 0, p2: 0, p3: 0, p4: 0, p5: 0,
    p6: 0, p7: 0, p8: 0, p9: 0, p10: 0,
    p11: 0, p12: 0, p13: 0, p14: 0, p15: 0,
    p16: 0, p17: 0, p18: 0, p19: 0, p20: 0
};

export const StopFormContainer = ({onCancelClick, onSave, recordId, onGetError}) => {

    const [groups, setGroups] = useState([]);
    const [machinery, setMachinery] = useState([]);
    
    const {t} = useTranslation("StopFormContainer");
    const { toastError, toastSuccess } = useToaster();

    const { isLoading: groupsLoading, data: groupsData, error: groupsError } = useStopGroups();

    useEffect(() => {
        if ( groupsData ) {
            setGroups([{id: 0, _name: t("select")}].concat(groupsData));
        }
    }, [groupsData]);

    const { isLoading: machineryLoading, data: machineryData, error: machineryError } = useMachinery();

    useEffect(() => {
        if ( machineryData ) {
            setMachinery([{id: 0, _name: t("select")}].concat(machineryData));
        }
    }, [machineryData]);
    
    const { isLoading, isFetching, error, data } =  useStop({
        recordId,
        placeholder: model});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getStopDataError"));
        }
    }, [error] );
    
    const { mutate: createRecord, isLoading: isSavingArea } = useCreateStop({
        onError: err => toastError(t("saveStopError")),
        onSuccess: () => {
            toastSuccess(t("saveStopSuccess"));
            onSave();
        }
    });
    
    const handleSaveClick = (values, actions) => {
        const data = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };
        
        const retData = {...data};
        if ( retData.id === 0 ) {
            delete retData.id;
        }
            
        delete retData.sg_code;
        delete retData.sg_description;
        
        // CSwitch chyba ma jakiegoś buga z wartoscią, więc przepisujemy bool na int
        range(1,20).forEach( r => {
            const key = `p${r}`;
            const v = retData[key];
            retData[key] = v ? 1 : 0;
        });
        
        createRecord(retData);
    };
    
    return (
        <>
            <FormModal show={true}
               loading={isLoading || isFetching || isSavingArea}
               record={data ?? model}
               groups={groups}
               machinery={machinery}
               onClose={onCancelClick}
               onSaveClick={handleSaveClick} onCancelClick={onCancelClick} />
       </>
    );
};

StopFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

StopFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default StopFormContainer;