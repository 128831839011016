import { createReducer } from "@reduxjs/toolkit";
import {SetConfig} from "../actions/config.actions";

const initialState = {
    config: {}
};

export const configReducer = createReducer(initialState, (builder) => {
    builder.addCase(SetConfig, (state, action)=> {
        state = {...action.payload};
        return state;

    }).addDefaultCase((state, action) => state)
});
