import PropTypes from 'prop-types';
import {CToast, CToastHeader, CToastBody, CToaster } from "@coreui/react";
import {useTranslation} from "react-i18next";
import { noop } from "../../utils/noop";

export const ErrorToaster = ({ show, message, onStateChange}) => {
    
    const {t} = useTranslation("ErrorToaster");
    
    return (
        <CToaster position="top-right">
            <CToast
                show={show}
                onStateChange={onStateChange}
                fade={true}
                autohide={false}>
                <CToastHeader closeButton className="text-danger font-weight-bold">{t("error")}</CToastHeader>
                <CToastBody className="text-danger">{message}</CToastBody>
            </CToast>
        </CToaster>
    );
};

ErrorToaster.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.node,
    onStateChange: PropTypes.func
};

ErrorToaster.defaultProps = {
    onStateChange: noop
};

export default ErrorToaster;