import {useEffect} from "react";
import PropTypes from 'prop-types';
import useArea from "../hooks/useArea";
import noop from "../utils/noop";
import {AreaFormModal} from "../components";
import useCreateArea from "../hooks/useCreateArea";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";

const areaModel = {
    id: 0,
    is_active: 1,
    name: "",
    code_aq: "",
    crew_no: "",
    description: "",
};

export const AreaFormContainer = ({onCancelClick, onSave, areaId, onGetError}) => {
    
    const {t} = useTranslation("AreaFormContainer");
    const { toastError, toastSuccess } = useToaster();
    
    const { isLoading, isFetching, error, data } =  useArea({
        areaId,
        placeholder: areaModel});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getAreaDataError"));
        }
    }, [error] );
    
    const { mutate: createArea, isLoading: isSavingArea } = useCreateArea({
        onError: err => toastError(t("saveAreaError")),
        onSuccess: () => {
            toastSuccess(t("saveAreaSuccess"));
            onSave();
        }
    });
    
    const handleSaveAreaClick = (values, actions) => {
        const areaData = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( areaData.id === 0 ) {
            delete areaData.id;
        }
        createArea(areaData);
    };
    
    return (
        <>
            <AreaFormModal show={true}
               loading={isLoading || isFetching || isSavingArea}
               area={data ?? areaModel}
               onClose={onCancelClick}
               onSaveClick={handleSaveAreaClick} onCancelClick={onCancelClick} />
       </>
    );
};

AreaFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

AreaFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default AreaFormContainer;