import {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {CElementCover, CSwitch} from "@coreui/react";
import {useTranslation} from "react-i18next";
import useUpdateP from "../../hooks/useUpdateP";
import {useToaster} from "../../context-providers/ToasterProvider";
import {usePrevious} from "../../hooks/usePrevious";
import {noop} from "../../utils/noop";
import { range } from "lodash";

export const PSwitchCell = ({id, initialChecked, name, tag: Tag, onChange}) => {
    
    
    const {t} = useTranslation("Common");
    const { toastError } = useToaster();

    const [checked, setChecked] = useState(initialChecked === 1);
    
    const previousChecked = usePrevious(initialChecked);
    
    useEffect( () => {
        
        if ( typeof previousChecked !== "undefined" &&  previousChecked !== initialChecked) {
            setChecked(initialChecked);
        }
    },[initialChecked, previousChecked] );
    
    const { mutate: updateP, isLoading: isUpdatingP } = useUpdateP({
        onError: err => toastError(t("saveError")),
        onSuccess: () => {
            setChecked(!checked);
            onChange(!checked ? 1 : 0);
        }
    });
    
    const Cell = (props) => <Tag {...props}/>;
    
    
    const handleChange = (evt) => {

        const {checked, name} = evt.target;

        const pVal = checked ? 1 : 0;
        
        // Wszysktie p ustawiamy na zero oprócz tego klikniętego
        const p = range(1,20).map( c => `p${c}`).reduce( (prev, current) => {
                if ( current !== name ) {
                    return {...prev, [current]: 0};
                } else {
                    return {...prev, [current]: pVal};
                }
        }, {});
        
        
        updateP({
            id,
            ...p
        });
        
    };

    
    return (
        <Cell className="text-nowrap text-center position-relative bip-p-switch-cell">
            <div className="position-absolute d-flex align-items-center justify-content-center h-100" style={{top:0, left:0, bottom:0, right:0}}>
                <div className="position-relative">
                    <CSwitch shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                         variant="opposite"
                         onChange={handleChange}
                         checked={checked}
                         name={name}/>
                        {isUpdatingP && <CElementCover color="primary" opacity={0} className="imi-p-switch-cell__cover" />}
                    </div>
            </div>
        </Cell>
    );
};

PSwitchCell.propTypes = {
    id: PropTypes.number,
    tag: PropTypes.string,
    initialValue: PropTypes.oneOf([0,1]),
    onChange: PropTypes.func
};

PSwitchCell.defaultProps = {
    initialValue: false,
    tag: "td",
    onChange: noop
};

export default PSwitchCell;