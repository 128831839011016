import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import {filterString } from "../utils/filterString";

export const useUsers = (params) => {
    
    const apiUrl = useSelector(apiUrlSelector);

    const queryKey = (() => {
        if ( typeof params?.filter !== "undefined") {
            return ["users", params.filter, apiUrl];
        } else {
            return ["users", apiUrl];
        }
    })();
    
    return useQuery(queryKey, () =>
        axios.get(`${apiUrl}users${filterString(params?.filter)}`).then(res => res.data)
    ,{
        enabled: params?.enabled ?? true,
        placeholderData:[],
        refetchOnWindowFocus: false
    });
};

export default useUsers;