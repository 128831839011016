import {createSelector} from "reselect";

const getLoggedIn = state => state.auth.loggedIn;

export const loggedInSelector = createSelector([getLoggedIn], loggedIn => {
    return loggedIn;
});

const config = state => state.config;
const auth = state => state.auth;
const user = state => state.user;

export const configSelector = createSelector([config], config => config);

export const apiUrlSelector = createSelector([config], config => config.apiUrl);
export const authUrlSelector = createSelector([config], config => config.authUrl);
export const authSelector = createSelector( [auth], auth => auth);

const app = state => state.app;
export const areaSelector = createSelector([app], app => app.area);

export const userSelector = createSelector([user],user => user);