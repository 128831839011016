import { lazy } from "react";
import { Route } from "react-router-dom";
const LoginPage =  lazy( () => import("../pages/LoginPage"));


export const LoginRoute = ({...rest}) => {
  
    return <Route {...rest}>
          <LoginPage/>
    </Route>
    
};

export default LoginRoute;