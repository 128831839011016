import {useState, useEffect} from "react";
import {batch, useDispatch, useSelector} from 'react-redux'
import {LoginView} from "../views";
import {SetArea} from "../redux/actions/app.actions";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import {push} from "connected-react-router";
import { getUser } from "../auth/getUser";
import {apiUrlSelector, userSelector} from "../redux/selectors";
import {SetUserData} from "../redux/actions/user.actions";
import {useAuthProvider} from "../auth/AuthProvider";

export const LoginContainer = () => {
  
  const {t} = useTranslation("LoginContainer");
  const apiUrl = useSelector(apiUrlSelector);
  const { toastError } = useToaster();
  const [inputsDisabled, setInputsDisabled] = useState(false);
  const userData = useSelector(userSelector);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  
  const { login, authState: { loggedIn, access_token } } = useAuthProvider();
  
  const validator = () => true;

  // Efekt sprawdza stan zmiennej logowania, jeżeli się zmieniła
  // Jeżeli weszła na true, to robimy przekierowanie na start
/*  useEffect(async () => {
    
    console.log("Logged in jest", loggedIn);
    if ( loggedIn === true ) {

      console.log(`Pobieram usera, access_token ${access_token}`);
      const user = await getUser(apiUrl, username, access_token);
          
        batch(() => {
          //dispatch(SetArea(area));
          /!*          dispatch(SetAuthState({
                      loggedIn: true,
                      access_token: res.access_token,
                      refresh_token: res.refresh_token,
                      expires_in: res.expires_in
                    }));*!/

          dispatch(SetUserData({
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            username: user.username,
            user_roles_id: user.user_roles_id
          }));

          dispatch(push("/start"));

        });
        
      
    } else if (previousLoggedIn === true || typeof previousLoggedIn !== "undefined" ) {
      console.log("Wylogowany");
      setInputsDisabled(false);
      
      if ( access_token === "" ) {
        setInputsDisabled(false);
        setError(t("loginError"));
      } else if ( typeof access_token === "undefined" ) {
        toastError(t("connectionError"));
        setInputsDisabled(false);
      }
      
    }
    
  }, [loggedIn, username, access_token]);*/


/*  const handleLogin = ({username, password, area}) => {

    setUsername(username);
    setInputsDisabled(true);
    dispatch(SetArea(area));
    const res = await login(username, password);

    if ( res.access_token === "" ) {
      setInputsDisabled(false);
      setError(t("loginError"));
    } else if ( typeof res.access_token === "undefined" ) {
      toastError(t("connectionError"));
      setInputsDisabled(false);
    } else {
      const user = getUser()
    }
    
  }*/
  
  const handleLoginClick = async ({username, password, area}) => {
    
    setInputsDisabled(true);
    setError("");
    
    const res = await login(username, password);
    
    if ( res.access_token === "" ) {
      setInputsDisabled(false);
      setError(t("loginError"));
    } else if ( typeof res.access_token === "undefined" ) {
      toastError(t("connectionError"));
      setInputsDisabled(false);
    } else {
      
      // Uwaga! Jak się da F5 przy wysłaniu requestu odświeżenia tokena, ale bez otrzymania odpowiedzi
      // To token odświeży się na serwerze, ale już na kliencie tego nie zobaczy - wtedy nastąpi wylogowanie
      // 5. Start w ścieżce okruszkowej nie działa
      // 7. interceptor na 401 - unauthorized - odświeżenie tokena (uwzględnić unauthorized, dla refresh_tokena - logout)
      // 8. Unieważnienie tokenu przy wylogowaniu
      // 9. Coś się pierdoli czasem po restarcie jak jestem zalogowany i wejdę na główną stronę... hm może jak jest wpis w localStorage, ale wygaśnięty token?
      const user = await getUser(apiUrl, username, res.access_token);

        //start();
        
        batch(() => {
          dispatch(SetArea(area));
          
          dispatch(SetUserData({
            id: user.id,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            username: user.username,
            user_roles_id: user.user_roles_id
          }));
          
          dispatch(push("/start"));
          
        });
        
    }
  };
  
  
  return <>
    <LoginView validator={validator} onLoginClick={handleLoginClick} inputsDisabled={inputsDisabled}
               loading={inputsDisabled}
               error={error} />
  </>;

};


export default LoginContainer;