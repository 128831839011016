import { Suspense} from "react";
import {Route, Switch} from "react-router-dom";
import {CFade, CContainer} from "@coreui/react";
import { routes } from "../routes";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import classNames from "classnames";
const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
);

export const ContentContainer = () => {
    
    const { t } = useTranslation("Sidebar");

    // Planning nie ma nagłówka dla zaoszczędzenia miejsca w pionie
    const location = useLocation();
    const isPlanning = location.pathname === "/planning";
    
    return (
        <main className={classNames("c-main imi-content-container", {"imi-content-container__planning" : isPlanning})}>
            <CContainer fluid className={classNames({"imi-content-container__planning-container": isPlanning })}>
                <Suspense fallback={loading}>
                    <Switch>
                        {routes.map( (route, idx) => <Route
                            key={idx}
                            path={route.path}
                            exact={route.exact}
                            name={t(route.name)}
                            render={props => {
                                return <CFade className={classNames({"imi-content-container__planning-fade": isPlanning})}>
                                    <route.component {...props} />
                                </CFade>;
                            }} />)}
                    </Switch>
                </Suspense>
            </CContainer>
        </main>
    );
};

export default ContentContainer;