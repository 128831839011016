import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";
import {apiUrls} from "../common/apiUrls";

export const useNotes = (filter, options) => {
    
    const filterString = filter ? Object.keys(filter).map( f => `${f}=${filter[f]}`).join("&") : "";
    const apiUrl = useSelector(apiUrlSelector);

    const queryKey = (() => {
        if ( typeof filter !== "undefined") {
            return [apiUrls.notes, filter, apiUrl];
        } else {
            return [apiUrls.notes, apiUrl]
        }
    })();
    
    return useQuery(queryKey, () =>
        axios.get(`${apiUrl}${apiUrls.notes}${filterString.length > 0 ? `?${filterString}` : ""}`).then(res => res.data.data),
        {
            refetchOnWindowFocus: false,
            placeholderData: [],
            ...options,
        }
    );
};

export default useNotes;