import {useMemo} from "react";
import PropTypes from 'prop-types';
import {
    CElementCover,
    CForm,
    CFormGroup,
    CInput,
    CLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CSwitch,
    CFormText
} from "@coreui/react";
import noop from "../../utils/noop";
import { useTranslation } from "react-i18next";
import {Formik, Field } from "formik";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faClipboard} from "@fortawesome/pro-light-svg-icons";
import { SaveButton, CancelButton} from "../Buttons";
import * as Yup from "yup";
import DateTimePickerField from "../DateTimePickerField/DateTimePickerField";

export const ProductionOrderFormModal = ({record, onSaveClick, onCancelClick, show, loading, onClose }) => {
    
    const {t} = useTranslation(["ProductionOrderFormModal", "Buttons", "Validation"]);
    
    const validationSchema = useMemo( () => Yup.object({
        wo_order: Yup.number().moreThan(0, t("Validation:pleaseEnterAnIntegerGreaterThan0")).required(t("Validation:pleaseEnterAnIntegerGreaterThan0")),
        wo_status: Yup.string().required(t("Validation:fieldRequired"))
    }),[t]);
    
    return (
        <Formik initialValues={record}
                validateOnChange
                validateOnMount={parseInt(record.id) > 0}
                enableReinitialize
                onSubmit={onSaveClick}
                validationSchema={validationSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  resetForm,
                  isValid,
                  setFieldTouched,
                  validateField
                  /* and other goodies */
              }) => {
                
                
                return <CModal show={show} closeOnBackdrop={false} onClose={onClose} size="lg">
                    <CModalHeader closeButton><h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faClipboard} className="mr-2"/> {t("productionOrderEdit")}</h2></CModalHeader>
                    <CForm onSubmit={handleSubmit}>
                <CModalBody className="p-4">

                    <CFormGroup>
                        <CLabel htmlFor="start_date">{t("ProductionOrderFormModal:startDate")}<sup>*</sup></CLabel>
                        <div>
                            <DateTimePickerField fieldName="start_date" showTimeSelect={false}/>
                        </div>
                        <FieldErrorMessage name="start_date" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="quantity">{t("ProductionOrderFormModal:quantity")}</CLabel>
                        <CFormText style={{fontSize: "1.1em", fontWeight:"bold"}}>{record.quantity}</CFormText>
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="load_level">{t("ProductionOrderFormModal:loadLevel")}<sup>*</sup></CLabel>
                        <Field name="load_level">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off"  invalid={error && touched} valid={touched && !error} readOnly/>
                            }
                        </Field>
                        <FieldErrorMessage name="load_level" />
                    </CFormGroup>
                    
                    <CFormGroup>
                        <CLabel htmlFor="wo_order">{t("ProductionOrderFormModal:woOrder")}<sup>*</sup></CLabel>
                        <Field name="wo_order">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput type="number" autoComplete="off" {...field} invalid={error && touched} valid={touched && !error}/>
                            }
                        </Field>
                        <FieldErrorMessage name="wo_order" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="product_groups_id">{t("ProductionOrderFormModal:productGroup")}</CLabel>
                        <CFormText style={{fontSize: "1.1em", fontWeight:"bold"}}>{record.pg_name}</CFormText>
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="wo_status">{t("ProductionOrderFormModal:woStatus")}<sup>*</sup></CLabel>
                        <Field name="wo_status">
                            {({
                                  field, // { name, value, onChange, onBlur },
                                  meta: {touched, error}
                              }) =>
                                <CInput autoComplete="off" {...field} invalid={error && touched} valid={touched && !error} readOnly/>
                            }
                        </Field>
                        <FieldErrorMessage name="wo_status" />
                    </CFormGroup>

                    <CFormGroup>
                        <CLabel htmlFor="time_entry">{t("ProductionOrderFormModal:timeEntry")}<sup>*</sup></CLabel>
                        <div>
                            <DateTimePickerField fieldName="time_entry" />
                        </div>
                        <FieldErrorMessage name="time_entry" />
                    </CFormGroup>

                    <CFormGroup className="d-flex flex-row">
                        <Field type="checkbox" name="is_active">
                            {({field, field: { value }}) =>
                                <CSwitch id="is_active" shape="pill" color="info" labelOn="✓" labelOff="✕" size="sm"
                                         variant="opposite"
                                         {...field} value={value === 0}/>
                            }
                        </Field>
                        <CLabel htmlFor="is_active" className="ml-2 user-select-none">{t("StopGroupFormModal:active")}</CLabel>
                    </CFormGroup>
                    
                </CModalBody>
                <CModalFooter>
                    <CancelButton type="button" onClick={onCancelClick}>{t("Buttons:cancel")}</CancelButton>
                    <SaveButton type="submit" color="primary" disabled={isValid===false}>{t("Buttons:save")}</SaveButton>
                </CModalFooter>
                    </CForm>
                    {loading && <CElementCover/>}
            </CModal>}}
        </Formik>
    );
};

ProductionOrderFormModal.propTypes = {
    record: PropTypes.shape({
        is_active: PropTypes.number,
        jde_work_orders_id: PropTypes.number,
        start_date: PropTypes.string,
        products_id: PropTypes.number,
        quantity: PropTypes.number,
        load_level: PropTypes.string,
        wo_order: PropTypes.number,
        product_groups_id: PropTypes.number,
        wo_status: PropTypes.string,
        time_entry: PropTypes.string
    }),
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSaveClick: PropTypes.func,
    onCancelClick: PropTypes.func,
};

ProductionOrderFormModal.defaultProps = {
    loading: false,
    onChange: noop,
    onClose: noop,
    onSaveClick: noop,
    onCancelClick: noop,
};

export default ProductionOrderFormModal;