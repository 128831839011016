import {useEffect, useMemo} from "react";
import PropTypes from 'prop-types';
import noop from "../../utils/noop";
import {CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from "@coreui/react";
import { useTranslation} from "react-i18next";
import classNames from "classnames";
import useAreas from "../../hooks/useAreas";

const AreaName = ({area}) => {
    return <span className="w-100 d-inline-block">{area.description} {area.name.length > 0 && `(${area.name})`}</span>};

export const AreaPicker = ({area, onChange, className, disabled, ...rest}) => {

    const { t } = useTranslation("AreaPicker");
    const { data: areas } =  useAreas({ is_active: 1});
    
    const areaList = useMemo( () => {
        
        if ( area.id !== 0 ) {
            return [{id: 0, name: "", description: `-- ${t("select")} --`}].concat(areas);
        } else {
            return areas;
        }
    },[area,areas]);
    
    const currentArea = useMemo(() => {
        const ar = areas.find( a => a.id === area.id );
        return ar ??  { id: 0, name: "", description: `-- ${t("select")} --` }
    }, [area, areas, t]);
    
    return (
            <CDropdown className={classNames(className)} {...rest}> 
                <CDropdownToggle color="light" className="w-100 text-left pr-4" disabled={disabled}><AreaName area={currentArea} /></CDropdownToggle>
                <CDropdownMenu className="w-100">
                    {areaList.map( a => 
                        <CDropdownItem key={a.id} onClick={() => onChange(a)}>
                            <AreaName area={a} />
                        </CDropdownItem>    
                    )}
                </CDropdownMenu>
            </CDropdown>
    );
};

AreaPicker.propTypes = {
    area: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string}),
    className: PropTypes.string,
    onChange: PropTypes.func
};

AreaPicker.defaultProps = {
    area: {id: 0, name:""},
    className: "",
    onChange: noop,
};

export default AreaPicker;