import { useMemo } from "react";
import PropTypes from 'prop-types';
import {CDataTable, CCard, CCardBody, CCardHeader, CButton} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faStopwatch} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import noop from "../../utils/noop";
import {ActionToolbar } from "../Toolbars/ActionToolbar";
import {StatusCell} from "../";
import PSwitchCell from "../PSwitchCell";
import {useColumnFilter} from "../../hooks/useColumnFilter";
import {tableFilterObject} from "../../common/tableFilterObject";
import ActionsCell from "../ActionsCell";
import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../../redux/selectors";

export const StopList = ({records, onAddClick, onEditClick, onDeleteClick, onHistoryClick, onImportClick, loading, exportHref }) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    const queryClient = useQueryClient();
    
    const {t} = useTranslation(["StopList", "Common"]);

    /**
     * Id foR the record which we want to change
     * @param id
     */
    const  handleSwitchChange = (id, value, omit) => {
        
        const newData = records.map( r => {
           if( r.id === id ) {
               const keys = Object.keys(r);
               keys.forEach( k => {
                   if ( k[0] === "p") {
                       if ( k !== omit ) {
                           r[k] = 0;
                       } else {
                           r[k] = value;
                       }
                   }
               });
               return r;
           } else {
               return r;
           }
        });
        
        queryClient.setQueryData(["stops", apiUrl], newData);
        
    };
    
    const columnFilter = useColumnFilter();
    
    const fields = useMemo(() => ([
            { key: "id", label:t("Common:id") },
            { key: "is_active", label: t("Common:activeColName") },
            { key: "sg_code", label:t("StopList:group"),  _classes: "text-nowrap" },
            { key: "m_description", label: t("StopList:machine"), _classes: "text-nowrap" },
            { key: "description", label: t("StopList:description"), _style: { width: "100%"} },
            { key: "p1", label:"P1" }, { key: "p2", label:"P2" }, { key: "p3", label:"P3" }, { key: "p4", label:"P4" }, { key: "p5", label:"P5" },
            { key: "p6", label:"P6" }, { key: "p7", label:"P7" }, { key: "p8", label:"P8" }, { key: "p9", label:"P9" }, { key: "p10", label:"P10" },
            { key: "p11", label:"P11" }, { key: "p12", label:"P12" }, { key: "p13", label:"P13" }, { key: "p14", label:"P14" }, { key: "p15", label:"P15" },
            { key: "p16", label:"P16" }, { key: "p17", label:"P17" }, { key: "p18", label:"P18" }, { key: "p19", label:"P19" }, { key: "p20", label:"P20" },
            { key: "_actions", label: t("Common:actions"), sorter: false, filter: false },
        ]),[t]);
    
    return (         
        <CCard>
            <CCardHeader>
                <div className="d-flex flex-row">
                    <h2 className="mr-2 d-flex flex-row align-items-center"><FontAwesomeIcon icon={faStopwatch} className="mr-2"/>{t("StopList:stops")}</h2>
                    <div className="ml-auto d-flex">
                        <ActionToolbar className="ml-auto" onAddClick={onAddClick} onImportClick={onImportClick} exportHref={exportHref}/>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody>
                <CDataTable
                    size="md"
                    tableFilter={tableFilterObject(t)}
                    cleaner
                    itemsPerPageSelect
                    itemsPerPage={10}
                    columnFilter={columnFilter}
                    footer
                    sorter
                    striped
                    hover
                    responsive
                    pagination
                    noItemsViewSlot={<div>{t("Common:noRecordsFound")}</div>}
                    items={records} fields={fields}
                    loading={loading}
                    scopedSlots={{
                        is_active: item => <StatusCell active={item.is_active}/>,
                        m_description: item => <td><strong>{item.m_line_room}</strong> {item.m_description && <span style={{fontSize:"0.9em"}}>({item.m_description})</span>}</td>,
                        p1: item => <PSwitchCell name="p1" id={item.id} initialChecked={item.p1} onChange={(value) =>  handleSwitchChange(item.id, value, "p1")}/>,
                        p2: item => <PSwitchCell name="p2" id={item.id} initialChecked={item.p2} onChange={(value) =>  handleSwitchChange(item.id, value, "p2")}/>,
                        p3: item => <PSwitchCell name="p3" id={item.id} initialChecked={item.p3} onChange={(value) =>  handleSwitchChange(item.id, value, "p3")}/>,
                        p4: item => <PSwitchCell name="p4" id={item.id} initialChecked={item.p4} onChange={(value) =>  handleSwitchChange(item.id, value, "p4")}/>,
                        p5: item => <PSwitchCell name="p5" id={item.id} initialChecked={item.p5} onChange={(value) =>  handleSwitchChange(item.id, value, "p5")}/>,
                        p6: item => <PSwitchCell name="p6" id={item.id} initialChecked={item.p6} onChange={(value) =>  handleSwitchChange(item.id, value, "p6")}/>,
                        p7: item => <PSwitchCell name="p7" id={item.id} initialChecked={item.p7} onChange={(value) =>  handleSwitchChange(item.id, value, "p7")}/>,
                        p8: item => <PSwitchCell name="p8" id={item.id} initialChecked={item.p8} onChange={(value) =>  handleSwitchChange(item.id, value, "p8")}/>,
                        p9: item => <PSwitchCell name="p9" id={item.id} initialChecked={item.p9} onChange={(value) =>  handleSwitchChange(item.id, value, "p9")}/>,
                        p10: item => <PSwitchCell name="p10" id={item.id} initialChecked={item.p10} onChange={(value) =>  handleSwitchChange(item.id, value, "p10")}/>,
                        p11: item => <PSwitchCell name="p11" id={item.id} initialChecked={item.p11} onChange={(value) =>  handleSwitchChange(item.id, value, "p11")}/>,
                        p12: item => <PSwitchCell name="p12" id={item.id} initialChecked={item.p12} onChange={(value) =>  handleSwitchChange(item.id, value,  "p12")}/>,
                        p13: item => <PSwitchCell name="p13" id={item.id} initialChecked={item.p13} onChange={(value) =>  handleSwitchChange(item.id, value, "p13")}/>,
                        p14: item => <PSwitchCell name="p14" id={item.id} initialChecked={item.p14} onChange={(value) =>  handleSwitchChange(item.id, value, "p14")}/>,
                        p15: item => <PSwitchCell name="p15" id={item.id} initialChecked={item.p15} onChange={(value) =>  handleSwitchChange(item.id, value, "p15")}/>,
                        p16: item => <PSwitchCell name="p16" id={item.id} initialChecked={item.p16} onChange={(value) =>  handleSwitchChange(item.id, value, "p16")}/>,
                        p17: item => <PSwitchCell name="p17" id={item.id} initialChecked={item.p17} onChange={(value) =>  handleSwitchChange(item.id, value, "p17")}/>,
                        p18: item => <PSwitchCell name="p18" id={item.id} initialChecked={item.p18} onChange={(value) =>  handleSwitchChange(item.id, value, "p18")}/>,
                        p19: item => <PSwitchCell name="p19" id={item.id} initialChecked={item.p19} onChange={(value) =>  handleSwitchChange(item.id, value, "p19")}/>,
                        p20: item => <PSwitchCell name="p20" id={item.id} initialChecked={item.p20} onChange={(value) =>  handleSwitchChange(item.id, value, "p20")}/>,
                        _actions: item => <ActionsCell onEditClick={() => onEditClick(item)} onDeleteClick={() => onDeleteClick(item)} onHistoryClick={() => onHistoryClick(item)}/>
                    }}
                />
            </CCardBody>
        </CCard>
        
    );
};

StopList.propTypes = {
    records: PropTypes.array,
    loading: PropTypes.bool,
    exportHref: PropTypes.string,
    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onHistoryClick: PropTypes.func,
    onImportClick: PropTypes.func,
    onExportClick: PropTypes.func,
};

StopList.defaultProps = {
    records: [],
    loading: false,
    exportHref: "",
    onAddClick: noop,
    onEditClick: noop,
    onDeleteClick: noop,
    onHistoryClick: noop,
    onImportClick: noop,
    onExportClick: noop
};

export default StopList;