import {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import useMachine from "../hooks/useMachine";
import noop from "../utils/noop";
import {MachineFormModal} from "../components";
import useCreateMachine from "../hooks/useCreateMachine";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import useAreas from "../hooks/useAreas";

const machineModel = {
    id: 0,
    is_active: 1,
    areas_id: 0,
    line_room: "",
    shifts: 0,
    description: "",
};

export const MachineFormContainer = ({onCancelClick, onSave, machineId, onGetError}) => {
    
    const [areas, setAreas] = useState([]);
    const {t} = useTranslation("MachineFormContainer");
    const { toastError, toastSuccess } = useToaster();
    
    const { isLoading: areasLoading, data: areasData, error: areasError } = useAreas();
    
    useEffect(() => {
        if ( areasData ) {
            setAreas([{id: 0, name: t("MachineFormContainer:select")}].concat(areasData));
        }
    }, [areasData]);
    
    const { isLoading, isFetching, error, data } =  useMachine({
        machineId,
        placeholder: machineModel});
    
    useEffect( ( ) => {
        if ( error ) {
            toastError(t("getMachineDataError"));
        }
    }, [error] );
    
    const { mutate: createMachine, isLoading: isSavingMachine } = useCreateMachine({
        onError: err => toastError(t("saveMachineError")),
        onSuccess: () => {
            toastSuccess(t("saveMachineSuccess"));
            onSave();
        }
    });
    
    const handleSaveMachineClick = (values, actions) => {
        const machineData = {
            ...values,
            is_active: values.is_active ? 1 : 0
        };

        if ( machineData.id === 0 ) {
            delete machineData.id;
        }
        createMachine(machineData);
    };
    
    return (
        <>
            <MachineFormModal show={true}
               loading={isLoading || isFetching || isSavingMachine}
               machine={data ?? machineModel}
               areas={areas}
               onClose={onCancelClick}
               onSaveClick={handleSaveMachineClick} onCancelClick={onCancelClick} />
       </>
    );
};

MachineFormContainer.propTypes = {
    onClose: PropTypes.func,
    onCancelClick: PropTypes.func,
    onSave: PropTypes.func,
    onGetError: PropTypes.func
};

MachineFormContainer.defaultProps = {
    onClose: noop,
    onCancelClick: noop,
    onSave: noop,
    onGetError: noop
};

export default MachineFormContainer;