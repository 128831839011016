import {CCard, CCardBody, CCardHeader} from "@coreui/react";
import WorkOrderJDE from "./WorkOrderJDE";
import WorkOrderPlanned from "./WorkOrderPlanned";
import SimulationModal from "./SimulationModal";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.min.css";
import FilterProductGroup from "./FilterProductGroup";
import {SaveButton} from "../Buttons";
import {usePlanningContext} from "./PlanningContextProvider";
import FilterRccp from "./FilterRccp";
import FilterButton from "../Buttons/FilterButton";
import FilterSx from "./FilterSx";
import NotesModal from "./NotesModal";

export const Planning = () => {
    
    const { filterProductGroup, setFilterProductGroup, checkedWO, onSaveProductionOrders, onFilterClick} =usePlanningContext();
    return (
        <CCard className="imi-planning">
            <CCardHeader>
                <div className="d-flex">
                    <div className="d-flex align-items-center flex-grow-1">
                        <FilterProductGroup value={filterProductGroup} onChange={e => {setFilterProductGroup(e.currentTarget.value)}} />
                        <FilterRccp className="ml-3" />
                        <FilterSx className="ml-3" />
                        <FilterButton className="ml-2" onClick={onFilterClick}/>
                    </div>
                    <div className="ml-auto">
                        <SaveButton className="mr-2" disabled={checkedWO === false}  onClick={onSaveProductionOrders}/>
                    </div>
                    {/*Data: <DateTimePicker value={filterDate} onChange={setFilterDate} showTimeSelect={false}/>*/}
                </div>
            </CCardHeader>
            <CCardBody className="d-flex flex-column">
                <SimulationModal />
                <NotesModal />
                <WorkOrderJDE />
                <WorkOrderPlanned/>
            </CCardBody>
        </CCard>
    );
};


export default Planning;