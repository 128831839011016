import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import {useSelector} from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useCreateProductionOrder = ({ onError, onSuccess}) => {
    
    const apiUrl = useSelector(apiUrlSelector);
    const queryClient = useQueryClient();

    return useMutation(values => {
        
            if ( parseInt(values.id) > 0 ) {
                return axios.patch(`${apiUrl}production-orders/${values.id}`, values).then(res => res.data);
            } else {
                return axios.post(`${apiUrl}production-orders`, values).then(res => res.data)
            }
        },
        {
            onSuccess: (val) => {
                onSuccess(val);
                queryClient.invalidateQueries(["production-orders", apiUrl]);
            }, onError: onError
        }
    );
};

export default useCreateProductionOrder;