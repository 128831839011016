import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next";
import CIcon from "@coreui/icons-react";
import {cilCloudUpload} from "@coreui/icons";
import classNames from "classnames";

export const UploadButton = ({className, ...props}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton type="button" color="primary" className={classNames("px-4", className)} {...props}><CIcon name="cil-cloud-upload" content={cilCloudUpload} className="mr-2"/>{t("upload")}</CButton>
    );
};


export default UploadButton;