import {useEffect} from "react";
import axios from "axios";
import {useAuthProvider} from "./AuthProvider";
import {apiUrls} from "../common/apiUrls";

// Hook dodaje nagłówek interceptor axiosa z nagłówkiem autoryzacji
export const useAxiosAuth = () => {
    
    const { authState: { loggedIn, access_token }, refreshAccessToken } = useAuthProvider();
    
    useEffect(() => {
        
        const authInterceptor = axios.interceptors.request.use(config => {
            
            const headers = config?.headers ?? {};
            
            // Nie dodajemy nagłówka autoryzacji jeżeli jest to odświeżenie tokena lub logowanie
            // oraz kiedy user nie jest zalogowany, a pobieramy obszary (bo musimy mieć ich listę przed zalogowaniem)
            const r = new RegExp(`${apiUrls.areas}`);
            
            const isRefresh = config.data?.grant_type === "refresh_token" ||  config.data?.grant_type === "password";
            
            if ((loggedIn && !isRefresh) || (!loggedIn && config.url.match(r))) {
                
                const newConfig = {
                    ...config,
                    headers: {...headers, "Authorization": `Bearer ${access_token}`}
                };
                
                console.log("Dodaję interceptor", newConfig);
                
                return newConfig;
                
            } else {
                return config;
            }

        });
        
        // Jeżeli access token wygasł, to odświeżam i ponawiam request
        const responseAuthInterceptor = axios.interceptors.response.use( 
        response => response,
        async error => {
            
                    const originalRequest = error.config;
                    
                    // Dane z original request są serializowane do stringa przez axiosa, więc trzeba je odserializwować
                    const data =(() =>  {
                        try {
                            const parsed = JSON.parse(originalRequest.data);
                            return parsed;
                        } catch {
                            return {};
                        }
                    })();
                    
                    const isPassword = data?.grant_type === "password"; 
                        
                    if ( !isPassword ) {

                        if (error.response.status === 401 && !originalRequest._retry) {
                            originalRequest._retry = true;
                            console.log("Wywołuję funkcję refreshAccessToken()");
                            return refreshAccessToken().then(res => {
                                const repeatRequest = {
                                    ...originalRequest,
                                    headers: {
                                        ...originalRequest.headers,
                                        Authorization: `Bearer ${res.data.access_token}`
                                    }
                                };
                                
                                console.log(`Response, po odświeżeniu, ponawiam request ${data.access_token}`);
                                // Nowa instancja axiosa, ponieważ interceptor dodający auth nie widzi jeszcze zmian
                                // co powoduje, że nadpisze nagłówek Authorization starym acces_tokenem
                                const localAxios = axios.create();
                                return localAxios(repeatRequest);
                            });
                            
                        }
                    }
                    
                    return Promise.reject(error);
                    
                });
        
        return () => {
            axios.interceptors.request.eject(authInterceptor);
            axios.interceptors.response.eject(responseAuthInterceptor);
        };
    }, [loggedIn, access_token, refreshAccessToken]);
    
}