import axios from "axios";
import {apiUrls} from "../common/apiUrls";

export const getUser = async (apiUrl, username, access_token) => {

    return axios.get(`${apiUrl}${apiUrls.users}?username=${username}`,{
        headers: {
            "Authorization": `Bearer ${access_token}`
        }
    }).then( ret => {
        
        if ( ret?.data?.data?.length === 1 ) {
            return ret.data.data[0];   
        }
        
        return Promise.reject();
        
    });
    
}