import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n.use(HttpApi).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  defaultNS: "Login",
  // Change this to switch logging
  debug: false,
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  // For available options see at https://github.com/i18next/i18next-xhr-backend
  backend: {
    loadPath: "/locales/{{lng}}/{{ns}}.json"
  },

  // react i18next special options (optional)
  react: {
    wait: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  }
});


export default i18n;
