import {useContext, createContext, useEffect, useState, useCallback, useRef} from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {useToaster} from "../context-providers/ToasterProvider";
import {push} from "connected-react-router";

// TODO: Akcja przekierowania do ekranu logowania powinna być dostarczana z zewnątrz
// Żeby tutaj nie było żadnej logiki powiązanej bezpośrednio z aplikacją

const initialAuthState = {
    loggedIn: false,
    access_token: "",
    expires_in: 0,
    token_type: ""
};

const AuthContext = createContext();
AuthContext.displayName = "AuthContext";

const useAuthProvider = () => useContext(AuthContext);

// expires_in z serwera przychodzi w sekundach, dlatego musimy przeliczyć na ms
const getTimeout = (expiresIn) => (expiresIn/2).toFixed() * 1000;
//const getTimeout = (expiresIn) => 300000;

const saveToStorage = (authState) => localStorage.setItem("auth", JSON.stringify(authState));
const removeFromStorage = () => localStorage.removeItem("auth");

const AuthProvider = ({children, authUrl}) => {
    
    const {t} = useTranslation("Common");
    const { toastInfo } = useToaster();
    
    const [authState, setAuthState] = useState(initialAuthState);
    const [error, setError] = useState("");

    const [isRunning, setRunning] = useState(false);
    const timoutRef = useRef();
    
    const start = useCallback( () => {
        setRunning(true);
    },[setRunning]);

    const login = useCallback(async (username, password) => {
        setError("");
        return axios.post(authUrl, {
            grant_type: "password",
            username,
            password,
            client_id: "app"
        },{
            withCredentials: true
        }).then( res => {
                const newAuthState = {...authState, ...res.data, loggedIn: true};
                setAuthState(newAuthState);
                saveToStorage(newAuthState);
                return res.data;
            }
        ).catch( err => {
            
            console.log(err);
            if ( err.response.status === 401 ) {
                
                const newAuthState = {
                    ...authState,
                    access_token: ""
                };
                
                setAuthState(newAuthState);
                return newAuthState;
                
            } else {
                
                const newAuthState = {
                    ...authState,
                    access_token: undefined
                };
                
                setAuthState(newAuthState);
                return newAuthState;
            }
            
        });
    },[authState, authUrl]);
    
    const logout = useCallback(
        () => {
            setAuthState(initialAuthState);
            removeFromStorage();
        },
        [setAuthState],
    );
    
    
    const stop = useCallback(
        () => {
            if ( typeof timoutRef.current !== "undefined" ) {
                clearTimeout(timoutRef.current);
            }
            
            setRunning(false);
            
        },
        [setRunning, timoutRef],
    );
    
    const dispatch = useDispatch();

    const refreshAccessToken = useCallback( () => {
        
        console.log(`Wysyłam request odświeżenia tokena na adres ${authUrl}`);
        return axios.post(`${authUrl}`, {
                grant_type: "refresh_token",
                client_id:"app"
            }, {
                withCredentials: true
            }
        ).then(res => {

            console.log(`Token odświeżony: ${res.data.access_token}`);
            const newAuthState = {
                loggedIn: true,
                access_token: res.data.access_token,
                expires_in: res.data.expires_in
            };
            
            setAuthState(newAuthState);
            saveToStorage(newAuthState);
            
            if ( isRunning ) {
                timoutRef.current = setTimeout(refreshAccessToken, getTimeout(res.data.expires_in));
            }
            
            return res;
            
        }).catch(err => {
            console.log(err);
            //toastInfo(t("sessionExpired")); // Toast musi stąd wylecieć
            
            setError("sessionExpired");
            setAuthState(initialAuthState);
            removeFromStorage();
            // TODO: No dobra, a co mam zrobić tutaj?
            // Czy to nie powinno być na poziomie aplikacji?
            // Chociaż, może nie...
            // Może lepiej to tutaj ogarnąć od razu w jednym miejscu?             
            dispatch(push("/login"));
            return err;
                
        });
        
    },[authState, setAuthState, toastInfo, isRunning]);

    // Sprawdza, czy w localStorage jest zapisana autoryzacja
    // Jeżeli jest to ustawia wewnętrzeny stan i  cykliczne odświeżanie tokena
    // Jezeli nie ma to przekierowuje do okna logowania
    useEffect(() => {

        const auth = localStorage.getItem("auth");
        if ( auth ) {
            const authObj = JSON.parse(auth);
            console.log("Zalogowany, pobrane ze storage", authObj);
            setAuthState(authObj);
            //start();
        } else {
            //stop();
            setAuthState(initialAuthState);
            dispatch(push("/login"));
        }

    }, [dispatch]);
    
    // Uruchamia cykliczne odświeżanie tokena
    useEffect(() => {
        
        if ( authState.expires_in > 0 ) {
            if ( typeof timoutRef.current !== "undefined" ) {
                clearTimeout(timoutRef.current);
            }
            
            const newInterval = getTimeout(authState.expires_in);
            
            if ( isRunning ) {
                timoutRef.current = setTimeout(() => {
                    console.log("Wywołuję z timeouta");
                    refreshAccessToken();
                }, newInterval);
            }
        }
        
        return () => {
            if ( typeof timoutRef.current !== "undefined" ) {
                clearTimeout(timoutRef.current);
            }
        }
        
    },[isRunning, timoutRef, authState]);
    
    return <AuthContext.Provider value={{
        login,
        logout,
        isRunning,
        start,
        stop,
        authState,
        refreshAccessToken,
        error
    }}>{typeof children === "function" ? children({
        login,
        logout,
        isRunning,
        start,
        stop,
        authState,
        refreshAccessToken,
        error
    }) : children}</AuthContext.Provider>;
    
};

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export { AuthProvider, useAuthProvider };