import {useState} from "react";
import useImport from "./useImport";
import {useToaster} from "../context-providers/ToasterProvider";
import {useTranslation} from "react-i18next";

export const useImportModal = (url) => {
    
    const {t} = useTranslation("ImportModal");
    
    const {toastError, toastSuccess} = useToaster();
    
    const [ready, setReady] = useState(false);
    const [importedModel, setImportedModel] = useState({ totalRecords: 0, importedRecords: 0});
    const [show, setShow] = useState(false);
    
    const onCancelClick = () => {
        setShow(false);
        setReady(false);
    };
    
    const onImportClick = () =>  setShow(true);
    
    const { mutate: importData, isLoading: loading } = useImport({
        onError: err => toastError(t("importError")),
        onSuccess: (importModel) => {
            setReady(true);
            setImportedModel(importModel);
        }
    });
    
    const onUploadClick = () => importData();
    
    return { modal: { show, loading, ready, importedModel, onCancelClick, onUploadClick }, onImportClick  };
    
};