import PropTypes from 'prop-types';
import {CBadge} from "@coreui/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faTimesCircle} from "@fortawesome/free-regular-svg-icons";
import {useTranslation} from "react-i18next";

const defaultBadgeStyle = {padding:"0.6em 1.2em"};
export const StatusCell = ({active, style, ...rest}) => {
    
    const {t} = useTranslation("Common");
    
    const badgeStyle = style ?  {...defaultBadgeStyle, ...style} : defaultBadgeStyle;  
    
    return (
        <td className="text-nowrap text-center">
            <CBadge style={badgeStyle} color={active === 1 ? "success" : "danger"} {...rest}><FontAwesomeIcon icon={active === 1? faCheckCircle : faTimesCircle} className="mr-1"/>
                {t(`Common:yesNo:${active}`)}</CBadge>
                </td>
    );
};

StatusCell.propTypes = {
    active: PropTypes.number
};

StatusCell.defaultProps = {
    active: 0
};

export default StatusCell;