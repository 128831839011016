import {lazy} from "react";

const StartPage = lazy(() =>  import("../pages/StartPage") );
const DataAdministrationPage = lazy(() => import("../pages/DataAdministration"));
const DataCorrectionPage = lazy(() => import("../pages/DataCorrection"));
const PlanningPage = lazy(() => import("../pages/Planning"));
const ProductionReportingPage = lazy(() => import("../pages/ProductionReporting"));
const ReportsPage = lazy(() => import("../pages/Reports"));
const ScrapReportingPage = lazy( () => import("../pages/ScrapReporting"));
const AdministrationPage = lazy(() => import("../pages/Administration"));
const UsersPage = lazy(()=> import("../pages/Users"));
const EmployeesPage = lazy(() => import("../pages/EmployeesPage"));
const SettingsPage = lazy(()=> import("../pages/Settings"));
const AreasPage = lazy(() => import("../pages/Areas"));
const UserProfilePage = lazy(() => import("../pages/UserProfile"));
const UserSettingsPage = lazy(() => import("../pages/UserSettings"));
const UserActivityLogPage = lazy(() => import("../pages/UserActivityLog"));
const DictionariesPage = lazy(() => import("../pages/Dictionaries"));
const UnitsOfMeasurementPage = lazy(() => import("../pages/UnitsOfMeasurement"));
const PermissionsPage = lazy(() => import("../pages/PermissionsPage"));
const MachineryPage = lazy(() => import("../pages/MachineryPage"));
const StopGroupsPage = lazy(()=> import("../pages/StopGroupsPage"));
const ScrapingCodesPage = lazy(() => import("../pages/ScrapingCodesPage"));
const StopsPage = lazy(() => import("../pages/StopsPage"));
const BOMPage = lazy(() => import("../pages/BOMPage"));
const ProductGroupsPage = lazy(() => import("../pages/ProductGroupsPage"));
const ProductsPage = lazy(() => import("../pages/ProductsPage"));
const ProductionOrdersPage = lazy(() => import("../pages/ProductionOrdersPage")); 
    
export const routes = [
    { path: "/", exact: true, name: "Sidebar:menu_start", component: StartPage },
    { path: "/start", name: "Sidebar:menu_start", component: StartPage },
    { path: "/data-administration", name: "Sidebar:menu_dataAdministration", component: DataAdministrationPage, exact: true },
    { path: "/data-correction", name: "Sidebar:menu_dataCorrection", component: DataCorrectionPage, exact: true },
    { path: "/data-correction/production-orders", name: "Sidebar:menu_productionOrders", component: ProductionOrdersPage},
    { path: "/planning", name: "Sidebar:menu_planning", component: PlanningPage, exact: true },
    { path: "/production-reporting", name: "Sidebar:Raportowanie produkcji", component:  ProductionReportingPage, exact: true },
    { path: "/reports", name: "Sidebar:Raporty", component: ReportsPage, exact: true },
    { path: "/reports/report-1", name: "Sidebar:menu_report1", component: ReportsPage },
    { path: "/reports/report-2", name: "Sidebar:menu_report2", component: ReportsPage },
    { path: "/reports/report-3", name: "Sidebar:menu_report3", component: ReportsPage },
    { path: "/reports/report-4", name: "Sidebar:menu_report4", component: ReportsPage },
    { path: "/reports/report-5", name: "Sidebar:menu_report5", component: ReportsPage },
    { path: "/reports/report-6", name: "Sidebar:menu_report6", component: ReportsPage },
    { path: "/reports/report-7", name: "Sidebar:menu_report7", component: ReportsPage },
    { path: "/scrap-reporting", name: "Sidebar:menu_scrapReporting", component: ScrapReportingPage, exact: true },
    { path: "/administration", name: "Sidebar:menu_administration", component: AdministrationPage, exact: true },
    { path: "/administration/users", name: "Sidebar:menu_users", component: UsersPage },
    { path: "/administration/employees", name: "Sidebar:menu_employees", component: EmployeesPage },
    { path: "/administration/permissions", name: "Sidebar:menu_permissions", component: PermissionsPage },
    { path: "/administration/machinery", name: "Sidebar:menu_machinery", component: MachineryPage },
    { path: "/administration/stop-groups", name: "Sidebar:menu_stop-groups", component: StopGroupsPage },
    { path: "/administration/stops", name: "Sidebar:menu_stops", component: StopsPage },
    { path: "/administration/product-groups", name: "Sidebar:menu_product-groups", component: ProductGroupsPage },
    { path: "/administration/products", name: "Sidebar:menu_products", component: ProductsPage },
    { path: "/administration/scraping-codes", name: "Sidebar:menu_scraping-codes", component: ScrapingCodesPage },
    { path: "/administration/dictionaries", name: "Sidebar:menu_dictionaries", component: DictionariesPage, exact: true },
    { path: "/administration/dictionaries/units-of-measurement", name: "Sidebar:menu_units-of-measurement", component: UnitsOfMeasurementPage },
    { path: "/administration/settings", name: "Sidebar:menu_settings", component: SettingsPage },
    { path: "/administration/areas", name: "Sidebar:menu_areas", component: AreasPage },
    { path: "/administration/bom", name: "Sidebar:menu_bom", component: BOMPage },
    { path: "/user/profile", name: "SidebarMenu:profile", component: UserProfilePage },
    { path: "/user/settings", name: "UserMenu:settings", component: UserSettingsPage },
    { path: "/user/activity-log", name: "UserMenu:activityLog", component: UserActivityLogPage  },
];

const Report1 = lazy(() => import("../components/Reports/WorkDeclaration"));
const Report2 = lazy(() => import("../components/Reports/ExecutionOfOrders"));
const Report3 = lazy(() => import("../components/Reports/WorkingHours"));
const Report4 = lazy(() => import("../components/Reports/Stops"));
const Report5 = lazy(() => import("../components/Reports/WasteScrap"));
const Report6 = lazy(() => import("../components/Reports/MachineWorkingTime"));
const Report7 = lazy(() => import("../components/Reports/ComponentTracking"));

export const reportsRoutes = [
    { path: "/reports/report-1", name: "Sidebar:menu_report1", component: Report1 },
    { path: "/reports/report-2", name: "Sidebar:menu_report2", component: Report2 },
    { path: "/reports/report-3", name: "Sidebar:menu_report3", component: Report3 },
    { path: "/reports/report-4", name: "Sidebar:menu_report4", component: Report4 },
    { path: "/reports/report-5", name: "Sidebar:menu_report5", component: Report5 },
    { path: "/reports/report-6", name: "Sidebar:menu_report6", component: Report6 },
    { path: "/reports/report-7", name: "Sidebar:menu_report7", component: Report7 },
];

export default routes;