import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useProductionOrders = (filter, onSuccess) => {
    const apiUrl = useSelector(apiUrlSelector);
    
    const queryKey = (() => {
        if ( typeof filter !== "undefined") {
            return ["production-orders", filter, apiUrl];
        } else {
            return ["production-orders", apiUrl]
        }    
    })();

    const filterString = filter ? Object.keys(filter).map( f => `${f}=${filter[f]}`).join("&") : "";
    
    return useQuery(queryKey, () =>
        axios.get(`${apiUrl}production-orders${filterString.length > 0 ? `?${filterString}` : ""}`).then(res => {
            if ( onSuccess ) {
                return onSuccess(res.data.data);
            } else {
                return res.data.data;
            }
        }),
        {
            refetchOnWindowFocus: false,
            placeholderData: []
        }
    );
};

export default useProductionOrders;