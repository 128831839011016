import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next";
import CIcon from "@coreui/icons-react";
import {cilX} from "@coreui/icons";
import classNames from "classnames";


export const CancelButton = ({className, ...props}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton className={classNames("px-4", className)} {...props} ><CIcon name="cil-x" content={cilX} className="mr-2" />{t("cancel")}</CButton>
    );
};


export default CancelButton;