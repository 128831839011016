import React, {useMemo} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarMinimizer,
    CSidebarNavDropdown,
    CSidebarNavItem,
} from "@coreui/react";
import navigation from './_nav';
import brandLogo from "./brand.png";
import brandSmall from "./bran_small.png";
import { useTranslation} from "react-i18next";
import AreaPicker from "../AreaPicker/AreaPicker";
import {areaSelector} from "../../redux/selectors";
import {SetArea} from "../../redux/actions/app.actions";

const components = {
  CSidebarNavItem,
  CSidebarNavDropdown
};

const Sidebar = () => {
    const dispatch = useDispatch();
    const show = useSelector(state => state.app.sidebarShow);
    const {t} = useTranslation("Sidebar");
    
    const items = useMemo(() => navigation(dispatch), [dispatch]);
    const area = useSelector(areaSelector);
    
    const handleAreaChange = area => dispatch(SetArea(area));
    
    return (
        <CSidebar
            className="imi-sidebar"
            show={show}
            onShowChange={(val) => dispatch({type: 'set', sidebarShow: val })}
        >
            <CSidebarBrand className="d-md-down-none" to="/">
                <img src={brandLogo} className="c-sidebar-brand-full" style={{height:"35px"}} alt="Imi logo" />
                <img src={brandSmall} className="c-sidebar-brand-minimized" alt="Imi logo" />
            </CSidebarBrand>
            <CSidebarNav>
                
                {items.map( (item, idx) => {
                        const Tag = components[item._tag];
                        return <Tag key={item.key} name={t(item.name)} to={item.to} icon={item.icon} onClick={item.onClick}>
                            {item._children && item._children.map(child => {
                                const ChildTag = components[child._tag];
                                return <ChildTag key={child.key} name={t(child.name)} to={child.to} icon={child.icon} onClick={child.onClick} className="imi-sidebar__nav-item"/>
                            })}
                        </Tag>
                    }
                )}
            </CSidebarNav>
            
            <div className="imi-sidebar__area-picker px-2 py-2">
                <AreaPicker area={area} disabled={false} onChange={handleAreaChange} />
            </div>
            <CSidebarMinimizer className="c-d-md-down-none" />
        </CSidebar>
    )
};

export default React.memo(Sidebar);