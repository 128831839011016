import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useAreas = (filter) => {
    
    const filterString = filter ? Object.keys(filter).map( f => `${f}=${filter[f]}`).join("&") : "";
    const apiUrl = useSelector(apiUrlSelector);

    const queryKey = (() => {
        if ( typeof filter !== "undefined") {
            return ["areas", filter, apiUrl];
        } else {
            return ["areas", apiUrl];
        }
    })();
    
    return useQuery(queryKey, () =>
        axios.get(`${apiUrl}areas${filterString.length > 0 ? `?${filterString}` : ""}`).then(res => res.data.data),
        {
            refetchOnWindowFocus: false,
            placeholderData: []
        }
    );
};

export default useAreas;