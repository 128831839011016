export const apiUrls = {
    users: "users",
    areas: "areas",
    unitsOfMeasurement: "units-of-measurement",
    machinery: "machinery",
    stopGroups: "stop-groups",
    scrappingCodes: "scrapping-codes",
    stops: "stops",
    employees: "employees",
    bom: "bill-of-materials",
    productGroups: "product-groups",
    products: "products",
    productionOrders: "production-orders",
    notes: "notes"
};