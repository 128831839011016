import { CButton } from "@coreui/react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const YesDeleteButton = ({className, ...props}) => {
    
    const {t} = useTranslation("Buttons");
    
    return (
        <CButton type="button" color="danger" size="md" className={classNames(className)} {...props}><FontAwesomeIcon icon={faCheck} className="mr-2"/>{t("yesDelete")}</CButton>
    );
};


export default YesDeleteButton;