import { useQuery } from "react-query";
import axios from "axios";
import {useSelector } from "react-redux";
import {apiUrlSelector} from "../redux/selectors";

export const useProductGroups = (onSuccess) => {
    const apiUrl = useSelector(apiUrlSelector);
    return useQuery(["product-groups", apiUrl], () =>
        axios.get(`${apiUrl}product-groups`).then(res => {
            if ( onSuccess ) {
            return onSuccess(res.data.data);
            } else {
                return res.data.data;
            }
            
        } ),
        {
            refetchOnWindowFocus: false,
            placeholderData: [],
        }
    );
};

export default useProductGroups;