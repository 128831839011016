import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {Provider, useDispatch} from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import { store, history } from "./configureStore";
import ConfigService from "./services/ConfigService";
import { ServicesProvider } from "./context-providers/ServicesProvider";
import LoadingFallback from "./components/LoadingFallback";
import {AuthProvider} from "./auth/AuthProvider";
import {ToasterProvider, useToaster} from "./context-providers/ToasterProvider";
import {ConfigProvider} from "./config/ConfigProvider";
import {SetConfig} from "./redux/actions/config.actions";
import {useTranslation} from "react-i18next";

//import reportWebVitals from './reportWebVitals';

const services = store =>
    ({
        config: ConfigService(),
    });
    
ReactDOM.render(
  <React.StrictMode>
      <LoadingFallback>
          <Provider store={store}>
              <ConfigProvider configPath="config.js">
                  { 
                      ({loading, hasConfig, config}) => {
                          const dispatch = useDispatch();
                          if ( loading === true ) {
                              return <></>;
                          } else {
                              if (hasConfig) {
                                  
                                  // Ponieważ komponenty pobierają configa z reduxa
                                  // (bo tak była całość wcześniej zrobiona)
                                  // To dispatchuję tutaj configa
                                  // Ale docelowo powinno to być zrefactorowane
                                  dispatch(SetConfig(config));
                                  
                                  return (
                                      
                                          <ConnectedRouter history={history}>
                                              <ServicesProvider services={services}>
                                                  <ToasterProvider>
                                                      <AuthProvider authUrl={config.authUrl}>
                                                          <App/>;
                                                      </AuthProvider>
                                                  </ToasterProvider>
                                              </ServicesProvider>
                                          </ConnectedRouter>
                                      
                                  );
                              } else {
                                  return <div>Configuration error</div>
                              }
                          }
                      }
                  }
              </ConfigProvider>
          </Provider>
      </LoadingFallback>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
